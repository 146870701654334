export const bloodTestParameters = {
  hgb: {
    label: { en: 'Hemoglobin (HGB)', it: 'Emoglobina (HGB)' },
    units: ['g/dL', 'g/L', 'mmol/L']
  },
  a1c: {
    label: { en: 'Glycated Hemoglobin (A1C)', it: 'Emoglobina Glicosilata (A1C)' },
    units: ['mmol/mol', '%']
  },
  mpv: {
    label: { en: 'Mean Platelet Volume (MPV)', it: 'Volume Piastrinico Medio (MPV)' },
    units: ['fL']
  },
  wbc: {
    label: { en: 'White Blood Cells (WBC)', it: 'Globuli Bianchi (WBC)' },
    units: ['10^3/µL', '10^9/L']
  },
  t3: {
    label: { en: 'Free Triiodothyronine (FT3)', it: 'Free Triiodotironina (FT3)' },
    units: ['pmol/L']
  },
  t4: {
    label: { en: 'Free Thyroxine (FT4)', it: 'Free Tiroxina (FT4)' },
    units: ['ng/dL']
  },
  ftl: {
    label: { en: 'Ferritin (FTL)', it: 'Ferritina (FTL)' },
    units: ['ng/mL']
  },
  rdwCv: {
    label: { en: 'Red Cell Distribution Width CV (RDW-CV)', it: 'Ampiezza di Distribuzione Eritrocitaria CV (RDW-CV)' },
    units: ['%']
  },
  po4: {
    label: { en: 'Phosphorus (PO4)', it: 'Fosforo (PO4)' },
    units: ['mg/dL']
  },
  ldh: {
    label: { en: 'Lactate Dehydrogenase (LDH)', it: 'Lattatodeidrogenasi (LDH)' },
    units: ['U/L']
  },
  cpk: {
    label: { en: 'Creatine Phosphokinase (CPK)', it: 'Creatinfosfochinasi (CPK)' },
    units: ['U/L']
  },
  inr: {
    label: { en: 'International Normalized Ratio (INR)', it: 'INR' },
    units: ['INR']
  },
  aptt: {
    label: { en: 'Activated Partial Thromboplastin Time (aPTT)', it: 'Tempo di Tromboplastina Parziale Attivata (aPTT)' },
    units: ['sec']
  },
  apttRatio: {
    label: { en: 'aPTT Ratio', it: 'Rapporto aPTT' },
    units: ['Ratio']
  },
  tg: {
    label: { en: 'Thyroglobulin (TG)', it: 'Tireoglobulina (TG)' },
    units: ['ng/mL']
  },
  hbsag: {
    label: { en: 'Hepatitis B Surface Antigen (HBsAg)', it: 'Antigene di Superficie dell\'Epatite B (HBsAg)' },
    units: ['COI']
  },
  antiHbsAg: {
    label: { en: 'Anti-HBs Antibody (Anti-HBs)', it: 'Anticorpi Anti-HBs (Anti-HBs)' },
    units: ['mIU/mL']
  },
  antiHcv: {
    label: { en: 'Anti-HCV Antibody (Anti-HCV)', it: 'Anticorpi Anti-HCV (Anti-HCV)' },
    units: ['COI']
  },
  pt: {
    label: { en: 'Prothrombin Time (PT)', it: 'Tempo di Protrombina (PT)' },
    units: ['%', 'sec']
  },
  mch: {
    label: { en: 'Mean Corpuscular Hemoglobin (MCH)', it: 'Emoglobina Corpuscolare Media (MCH)' },
    units: ['pg']
  },
  mchc: {
    label: { en: 'Mean Corpuscular Hemoglobin Concentration (MCHC)', it: 'Concentrazione Emoglobinica Corpuscolare Media (MCHC)' },
    units: ['g/dL', 'g/L']
  },
  plt: {
    label: { en: 'Platelets Count (PLT)', it: 'Conteggio delle Piastrine (PLT)' },
    units: ['10^3/µL', '10^9/L']
  },
  ast: {
    label: { en: 'Aspartate Transaminase (GOT)', it: 'Aspartato Transaminasi (GOT)' },
    units: ['U/L']
  },



  lps: { 
    label: { en: 'Lipase (LPS)', it: 'Lipasi (LPS)' }, 
    units: ['U/L'] 
  },
  alt: { 
    label: { en: 'Alanine Transaminase (ALT/GPT)', it: 'Alanina Aminotransferasi (ALT/GPT)' }, 
    units: ['U/L'] 
  },
  alp: { 
    label: { en: 'Alkaline Phosphatase (ALP)', it: 'Fosfatasi Alcalina (ALP)' }, 
    units: ['U/L'] 
  },
  pth: {
    label: { en: 'Parathormone (PTH)', it: 'Paratormone (PTH)' },
    units: ['pg/mL']
  },
  insulin: {
    label: { en: 'Insulin (INS)', it: 'Insulina (INS)' },
    units: ['mU/L']
  },
  dbil: {
    label: { en: 'Direct Bilirubin (DBIL)', it: 'Bilirubina Diretta (DBIL)' },
    units: ['mg/dL']
  },
  ibil: {
    label: { en: 'Indirect Bilirubin (IBIL)', it: 'Bilirubina Indiretta (IBIL)' },
    units: ['mg/dL']
  },
  tp: {
    label: { en: 'Total Protein (TP)', it: 'Proteine Totali (TP)' },
    units: ['g/dL']
  },
  fe: {
    label: { en: 'Iron (Serum) (FE)', it: 'Sideremia (FE)' },
    units: ['µg/dL']
  },
  ggt: {
    label: { en: 'Gamma GT (GGT)', it: 'Gamma GT (GGT)' },
    units: ['U/L']
  },
  ams: {
    label: { en: 'Amylase (AMS)', it: 'Amilasi (AMS)' },
    units: ['U/L']
  },
  bche: {
    label: { en: 'Cholinesterase (BCHE)', it: 'Colinesterasi (BCHE)' },
    units: ['U/L']
  },
  hct: {
    label: { en: 'Hematocrit (HCT)', it: 'Ematocrito (HCT)' },
    units: ['%']
  },
  rbc: {
    label: { en: 'Red Blood Cells (RBC)', it: 'Globuli Rossi (RBC)' },
    units: ['10^6/µL', '10^12/L']
  },
  mcv: {
    label: { en: 'Mean Corpuscular Volume (MCV)', it: 'Volume Corpuscolare Medio (MCV)' },
    units: ['fL']
  },
  mch: {
    label: { en: 'Mean Corpuscular Hemoglobin (MCH)', it: 'Emoglobina Corpuscolare Media (MCH)' },
    units: ['pg']
  },
  mchc: {
    label: { en: 'Mean Corpuscular Hemoglobin Concentration (MCHC)', it: 'Concentrazione Emoglobinica Corpuscolare Media (MCHC)' },
    units: ['g/dL', 'g/L']
  },
  neut: {
    label: { en: 'Neutrophils (NEUT)', it: 'Neutrofili (NEUT)' },
    units: ['%', '10^3/µL']
  },
  lym: {
    label: { en: 'Lymphocytes (LYM)', it: 'Linfociti (LYM)' },
    units: ['%', '10^3/µL']
  },
  mono: {
    label: { en: 'Monocytes (MONO)', it: 'Monociti (MONO)' },
    units: ['%', '10^3/µL']
  },
  eosi: {
    label: { en: 'Eosinophils (EOSI)', it: 'Eosinofili (EOSI)' },
    units: ['%', '10^3/µL']
  },
  baso: {
    label: { en: 'Basophils (BASO)', it: 'Basofili (BASO)' },
    units: ['%', '10^3/µL']
  },
  glu: {
    label: { en: 'Glucose (GLU)', it: 'Glicemia (GLU)' },
    units: ['mg/dL', 'mmol/L']
  },



  TPOAb: {
    label: { en: "Thyroid Peroxidase Antibodies (TPOAb)", it: "Anticorpi Antitireoperossidasi (TPOAb)" },
    units: ["IU/mL"]
  },
  PRL: {
    label: { en: "Prolactin (PRL)", it: "Prolattina (PRL)" },
    units: ["ng/mL"]
  },
  rdwsd: {
    label: { en: "Red Cell Distribution Width - Standard Deviation (RDW-SD)", it: "Ampiezza di Distribuzione dei Globuli Rossi - Deviazione Standard (RDW-SD)" },
    units: ["fL"]
  },
  PLCR: {
    label: { en: "Platelet Large Cell Ratio (PLCR)", it: "Rapporto delle Grandi Cellule Piastriniche (PLCR)" },
    units: ["%"]
  },
  PDW: {
    label: { en: "Platelet Distribution Width (PDW)", it: "Ampiezza di Distribuzione delle Piastrine (PDW)" },
    units: ["fL"]
  },
  PCT: {
    label: { en: "Plateletcrit (PCT)", it: "Piastrinocrito (PCT)" },
    units: ["%"]
  },
  HBA1: {
    label: { en: "Hemoglobin A1 (HBA1)", it: "Emoglobina A1 (HBA1)" },
    units: ["%"]
  },
  HBF: {
    label: { en: "Hemoglobin F (HBF)", it: "Emoglobina F (HBF)" },
    units: ["%"]
  },
  HBA2: {
    label: { en: "Hemoglobin A2 (HBA2)", it: "Emoglobina A2 (HBA2)" },
    units: ["%"]
  },
  TgAb: {
    label: { en: "Thyroglobulin Antibodies (TgAb)", it: "Anticorpi Anti-Tireoglobulina (TgAb)" },
    units: ["IU/mL"]
  },
  AbnHE: {
    label: { en: "Abnormal Hemoglobin (AbnHE)", it: "Emoglobine Anomale (AbnHE)" },
    units: ["g/dL"]
  },
  HF: {
    label: { en: "Fetal Hemoglobin (HF)", it: "Emoglobina Fetale (HF)" },
    units: ["%"]
  },
  BUN: {
    label: { en: "Blood Urea Nitrogen (BUN)", it: "Azotemia (BUN)" },
    units: ["mg/dL"]
  },
  ToxoIgG: {
    label: { en: "Toxoplasma Antibodies IgG (ToxoIgG)", it: "Anticorpi Toxoplasma IgG (ToxoIgG)" },
    units: ["IU/mL"]
  },
  ToxoIgM: {
    label: { en: "Toxoplasma Antibodies IgM (ToxoIgM)", it: "Anticorpi Toxoplasma IgM (ToxoIgM)" },
    units: ["IU/mL"]
  },
  HSV12IgG: {
    label: { en: "Herpes Virus 1-2 Antibodies IgG (HSV-1/2 IgG)", it: "Anticorpi Herpes Virus 1-2 IgG (HSV-1/2 IgG)" },
    units: ["IU/mL"]
  },
  HSV12IgM: {
    label: { en: "Herpes Virus 1-2 Antibodies IgM (HSV-1/2 IgM)", it: "Anticorpi Herpes Virus 1-2 IgM (HSV-1/2 IgM)" },
    units: ["IU/mL"]
  },

  VDRL: {
    label: { en: "Veneral Disease Research Laboratory Test (VDRL)", it: "Test di Laboratorio per la Ricerca delle Malattie Veneree (VDRL)" },
    units: ["qualitative"]
  },
  RPHA: {
    label: { en: "Reverse Passive Hemagglutination Assay (RPHA)", it: "Test di Emaglutinazione Passiva Inversa (RPHA)" },
    units: ["qualitative"]
  },
  HIV12Ab: {
    label: { en: "HIV 1-2 Antibodies (HIV1-2 Ab)", it: "Anticorpi HIV 1-2 (HIV1-2 Ab)" },
    units: ["qualitative"]
  },
  HIV1Ag: {
    label: { en: "HIV 1 Antigen (HIV1 Ag)", it: "Antigene HIV 1 (HIV1 Ag)" },
    units: ["qualitative"]
  },
  βhCG: {
    label: { en: "Beta-Human Chorionic Gonadotropin (β-hCG)", it: "Beta-Gonadotropina Corionica Umana (β-hCG)" },
    units: ["mIU/mL"]
  },
  TRAb: {
    label: { en: "Thyroid-Stimulating Hormone Receptor Antibodies (TRAb)", it: "Anticorpi del Recettore dell'Ormone Tireostimolante (TRAb)" },
    units: ["IU/L"]
  },
  urea: {
    label: { en: "Blood Urea Nitrogen (BUN)", it: "Azoto Ureico (BUN)" },
    units: ['mg/dL', 'mmol/L']
  },
  crea: {
    label: { en: "Creatinine (CREA)", it: "Creatinina (CREA)" },
    units: ['mg/dL', 'µmol/L']
  },
  na: {
    label: { en: "Sodium (NA)", it: "Sodio (NA)" },
    units: ['mEq/L', 'mmol/L']
  },
  k: {
    label: { en: "Potassium (K)", it: "Potassio (K)" },
    units: ['mEq/L', 'mmol/L']
  },
  cl: {
    label: { en: "Chloride (CL)", it: "Cloruro (CL)" },
    units: ['mEq/L', 'mmol/L']
  },
  ca: {
    label: { en: "Calcium (CA)", it: "Calcio (CA)" },
    units: ['mg/dL', 'mmol/L']
  },
  co2: {
    label: { en: "Carbon Dioxide (CO2)", it: "Anidride Carbonica (CO2)" },
    units: ['mEq/L', 'mmol/L']
  },
  // Lipid Profile
  tc: { 
    label: { en: "Total Cholesterol (TC)", it: "Colesterolo Totale (TC)" }, 
    units: ['mg/dL', 'mmol/L'] 
  },
  hdl: { 
    label: { en: "HDL Cholesterol (HDL)", it: "Colesterolo HDL (HDL)" }, 
    units: ['mg/dL', 'mmol/L'] 
  },
  ldl: { 
    label: { en: "LDL Cholesterol (LDL)", it: "Colesterolo LDL (LDL)" }, 
    units: ['mg/dL', 'mmol/L'] 
  },
  tag: { 
    label: { en: "Triglycerides (TAG)", it: "Trigliceridi (TAG)" }, 
    units: ['mg/dL', 'mmol/L'] 
  },
  tbil: { 
    label: { en: "Total Bilirubin (TBIL)", it: "Bilirubina Totale (TBIL)" }, 
    units: ['mg/dL', 'µmol/L'] 
  },
  alb: { 
    label: { en: "Albumin (ALB)", it: "Albumina (ALB)" }, 
    units: ['g/dL', 'g/L'] 
  },
  tsh: { 
    label: { en: "Thyroid Stimulating Hormone (TSH)", it: "Ormone Tireostimolante (TSH)" }, 
    units: ['mIU/L'] 
  },
 // Iron Studies
 tibc: { 
  label: { en: 'Total Iron Binding Capacity (TIBC)', it: 'Capacità Totale di Legame del Ferro (TIBC)' }, 
  units: ['µg/dL', 'µmol/L'] 
},

// Vitamins
b12: { 
  label: { en: 'Vitamin B12 (B12)', it: 'Vitamina B12 (B12)' }, 
  units: ['pg/mL', 'pmol/L'] 
},
fa: { 
  label: { en: 'Folic Acid (FA)', it: 'Acido Folico (FA)' }, 
  units: ['ng/mL', 'nmol/L'] 
},
d25: { 
  label: { en: 'Vitamin D, 25-Hydroxy (D25)', it: 'Vitamina D, 25-Idrossi (D25)' }, 
  units: ['ng/mL', 'nmol/L'] 
},
d3: {
  label: { en: 'Cholecalciferol (D3)', it: 'Vitamina D, Colecalciferolo (D3)' },
  units: ['IU', 'µg']
},

crp: { 
  label: { en: 'C-Reactive Protein (CRP)', it: 'Proteina C-Reattiva (PCR)' }, 
  units: ['mg/L'] 
},
esr: { 
  label: { en: 'Erythrocyte Sedimentation Rate (ESR)', it: 'Velocità di Eritrosedimentazione (VES)' }, 
  units: ['mm/hr'] 
},

hba1c: { 
  label: { en: 'Hemoglobin A1c (HbA1c)', it: 'Emoglobina A1c (HbA1c)' }, 
  units: ['%', 'mmol/mol'] 
},

egfr: { 
  label: { en: 'Estimated GFR (eGFR)', it: 'GFR Stimato (eGFR)' }, 
  units: ['mL/min/1.73m²'] 
},

psa: { 
  label: { en: 'Prostate-Specific Antigen (PSA)', it: 'Antigene Prostatico Specifico (PSA)' }, 
  units: ['ng/mL'] 
},
afp: { 
  label: { en: 'Alpha-Fetoprotein (AFP)', it: 'Alfa-Fetoproteina (AFP)' }, 
  units: ['ng/mL'] 
},
cea: { 
  label: { en: 'Carcinoembryonic Antigen (CEA)', it: 'Antigene Carcinoembrionario (CEA)' }, 
  units: ['ng/mL'] 
},
ca125: { 
  label: { en: 'Cancer Antigen 125 (CA-125)', it: 'Antigene Tumorale 125 (CA-125)' }, 
  units: ['U/mL'] 
},
ca19_9: { 
  label: { en: 'Cancer Antigen 19-9 (CA 19-9)', it: 'Antigene Tumorale 19-9 (CA 19-9)' }, 
  units: ['U/mL'] 
},
hcy: { 
  label: { en: 'Homocysteine (HCY)', it: 'Omocisteina (HCY)' }, 
  units: ['µmol/L'] 
},

lpa: { 
  label: { en: 'Lipoprotein(a) (LPA)', it: 'Lipoproteina(a) (LPA)' }, 
  units: ['mg/dL', 'nmol/L'] 
},
apob: { 
  label: { en: 'Apolipoprotein B (APOB)', it: 'Apolipoproteina B (APOB)' }, 
  units: ['mg/dL', 'g/L'] 
},
hscrp: { 
  label: { en: 'High-Sensitivity C-Reactive Protein (hs-CRP)', it: 'Proteina C-Reattiva ad Alta Sensibilità (hs-CRP)' }, 
  units: ['mg/L'] 
},
ntprobnp: { 
  label: { en: 'NT-proBNP (NT-proBNP)', it: 'NT-proBNP (NT-proBNP)' }, 
  units: ['pg/mL'] 
},
tn: { 
  label: { en: 'Troponin (TN)', it: 'Troponina (TN)' }, 
  units: ['ng/mL'] 
},
cort: { 
  label: { en: 'Cortisol (CORT)', it: 'Cortisolo (CORT)' }, 
  units: ['µg/dL', 'nmol/L'] 
},
testo: { 
  label: { en: 'Testosterone (TESTO)', it: 'Testosterone (TESTO)' }, 
  units: ['ng/dL', 'nmol/L'] 
},
e2: { 
  label: { en: 'Estradiol (E2)', it: 'Estradiolo (E2)' }, 
  units: ['pg/mL', 'pmol/L'] 
},
prg: { 
  label: { en: 'Progesterone (PRG)', it: 'Progesterone (PRG)' }, 
  units: ['ng/mL', 'nmol/L'] 
},
dhea_s: { 
  label: { en: 'DHEA-S (DHEA-S)', it: 'DHEA-S (DHEA-S)' }, 
  units: ['µg/dL', 'µmol/L'] ,
},
DHEA: { 
  label: { en: 'Dehydroepiandrosterone (DHEA)', it: 'Deidroepiandrosterone (DHEA)' }, 
  units: ['µg/dL', 'nmol/L'] 
},

// added new exams AB https://www.analisiclinichedipiazza.it/esami-in-convenzione/

oh_prg: { 
  label: { en: '17-OH Progesterone (17-OH PRG)', it: '17-OH Progesterone (17-OH PRG)' }, 
  units: ['ng/mL', 'nmol/L'] 
},
deoxycort: { 
  label: { en: '21 Deoxycortisol (21 DEOSSICORTISOLO)', it: '21 Deossicortisolo (21 DEOSSICORTISOLO)' }, 
  units: ['ng/mL', 'nmol/L'] 
},
dht: { 
  label: { en: '5-Alpha Dihydrotestosterone (DHT)', it: '5-Alfa Diidrotestosterone (DHT)' }, 
  units: ['ng/mL', 'nmol/L'] 
},

//AC AM
ace: { 
  label: { en: 'Angiotensin-Converting Enzyme (ACE)', it: 'ACE (Angiotensin Converting Enzyme)' }, 
  units: ['U/L', 'µg/L'] 
},
bile_acids: { 
  label: { en: 'Bile Acids (ACIDI BILIARI)', it: 'Acidi Biliari' }, 
  units: ['µmol/L', 'mg/dL'] 
},
methylmalonic_acid: { 
  label: { en: 'Methylmalonic Acid (ACIDO METILMALONICO)', it: 'Acido Metilmalonico' }, 
  units: ['µmol/L', 'nmol/L'] 
},
valproic_acid: { 
  label: { en: 'Valproic Acid (ACIDO VALPROICO)', it: 'Acido Valproico' }, 
  units: ['µg/mL', 'µmol/L'] 
},
acth: { 
  label: { en: 'Adrenocorticotropic Hormone (ACTH)', it: 'ACTH' }, 
  units: ['pg/mL', 'pmol/L'] 
},

adh: { 
  label: { en: 'Antidiuretic Hormone (ADH)', it: 'ADH Ormone Antidiuretico' }, 
  units: ['pg/mL', 'pmol/L'] 
},
ald: { 
  label: { en: 'Aldolase', it: 'Aldolasi' }, 
  units: ['U/L'] 
},
ald_orto: { 
  label: { en: 'Aldosterone (Orto)', it: 'Aldosterone (Orto)' }, 
  units: ['ng/dL', 'pmol/L'] 
},

alpha1_acid_glycoprotein: { 
  label: { en: 'Alpha-1-Acid Glycoprotein', it: 'Alfa 1 Glicoproteina Acida' }, 
  units: ['mg/dL', 'g/L'] 
},
alpha1_antitrypsin: { 
  label: { en: 'Alpha-1-Antitrypsin', it: 'Alfa-1-Antitripsina' }, 
  units: ['mg/dL', 'g/L'] 
},
alpha_fetoprotein: { 
  label: { en: 'Alpha-Fetoprotein (AFP)', it: 'Alfa-Feto Proteina (AFP)' }, 
  units: ['ng/mL', 'IU/mL'] 
},
amylasemia: { 
  label: { en: 'Amylasemia', it: 'Amilasemia' }, 
  units: ['U/L'] 
},
pancreatic_amylase: { 
  label: { en: 'Pancreatic Amylase', it: 'Amilasi Pancreatica' }, 
  units: ['U/L'] 
},
amiodarone_metabolites: { 
  label: { en: 'Amiodarone and Metabolites', it: 'Amiodarone e Metaboliti' }, 
  units: ['ng/mL'] 
},
ammonemia: { 
  label: { en: 'Ammonemia', it: 'Ammoniemia' }, 
  units: ['µmol/L', 'µg/dL'] 
},

//AN
 ANA: { 
    label: { en: 'Antinuclear Antibodies (ANA)', it: 'Anticorpi Antinucleo (ANA)' }, 
    units: ['qualitative'] 
  },
  TROMBO: { 
    label: { en: 'DNA Mutation Analysis for Thrombophilia (Factor II and V)', it: 'Analisi Mutazione DNA per Trombofilia (Fattore II e V)' }, 
    units: ['qualitative'] 
  },
  ADG: { 
    label: { en: 'Androstenediol Glucuronide (ADG)', it: 'Androstenediolo Glucuronide (ADG)' }, 
    units: ['ng/mL'] 
  },
  ANDRO: { 
    label: { en: 'Androstenedione (ANDRO)', it: 'Androstenedione (ANDRO)' }, 
    units: ['ng/mL'] 
  },
  ANISAKIS_IgG: { 
    label: { en: 'Anisakis IgG (ANISAKIS IgG)', it: 'Anisakis IgG (ANISAKIS IgG)' }, 
    units: ['qualitative'] 
  },
  AAIgA: { 
    label: { en: 'Anti-Actin IgA (AAIgA)', it: 'Anti-Actina IgA (AAIgA)' }, 
    units: ['qualitative'] 
  },
  AAIgG: { 
    label: { en: 'Anti-Actin IgG (AAIgG)', it: 'Anti-Actina IgG (AAIgG)' }, 
    units: ['qualitative'] 
  },
  AAN: { 
    label: { en: 'Anti-Neuronal Antigens (AAN)', it: 'Anti-Antigeni Neuronali (AAN)' }, 
    units: ['qualitative'] 
  },
  ABGPIgA: { 
    label: { en: 'Anti-Beta 2 Glycoprotein I IgA (ABGPIgA)', it: 'Anti-Beta 2 Glicoproteina I IgA (ABGPIgA)' }, 
    units: ['qualitative'] 
  },
  ABGPIgG: { 
    label: { en: 'Anti-Beta 2 Glycoprotein I IgG (ABGPIgG)', it: 'Anti-Beta 2 Glicoproteina I IgG (ABGPIgG)' }, 
    units: ['qualitative'] 
  },
  ABGPIgM: { 
    label: { en: 'Anti-Beta 2 Glycoprotein I IgM (ABGPIgM)', it: 'Anti-Beta 2 Glicoproteina I IgM (ABGPIgM)' }, 
    units: ['qualitative'] 
  },
  ABP180: { 
    label: { en: 'Anti-BP180 (ABP180)', it: 'Anti-BP180 (ABP180)' }, 
    units: ['qualitative'] 
  },
  ABP230: { 
    label: { en: 'Anti-BP230 (ABP230)', it: 'Anti-BP230 (ABP230)' }, 
    units: ['qualitative'] 
  },
  ABRUC: { 
    label: { en: 'Anti-Brucella IgG/IgM (ABRUC)', it: 'Anti-Brucelle IgG/IgM (ABRUC)' }, 
    units: ['qualitative'] 
  },
  AC1Q: { 
    label: { en: 'Anti-C1Q (AC1Q)', it: 'Anti-C1Q (AC1Q)' }, 
    units: ['qualitative'] 
  },
  ACPG: { 
    label: { en: 'Anti-Parietal Cell Antibodies (ACPG)', it: 'Anti-Cellule Parietali Gasriche (ACPG)' }, 
    units: ['qualitative'] 
  },
  ACEN: { 
    label: { en: 'Anti-Centromere (ACEN)', it: 'Anti-Centromero (ACEN)' }, 
    units: ['qualitative'] 
  },
  ACLAP_IgA: { 
    label: { en: 'Anti-Chlamydia Pneumoniae IgA (ACLAP IgA)', it: 'Anti-Clamidia Pneumoniae IgA (ACLAP IgA)' }, 
    units: ['qualitative'] 
  },
  ACLAP_IgG: { 
    label: { en: 'Anti-Chlamydia Pneumoniae IgG (ACLAP IgG)', it: 'Anti-Clamidia Pneumoniae IgG (ACLAP IgG)' }, 
    units: ['qualitative'] 
  },
  ACLAP_IgM: { 
    label: { en: 'Anti-Chlamydia Pneumoniae IgM (ACLAP IgM)', it: 'Anti-Clamidia Pneumoniae IgM (ACLAP IgM)' }, 
    units: ['qualitative'] 
  },
  ACLAT_IgA: { 
    label: { en: 'Anti-Chlamydia Trachomatis IgA (ACLAT IgA)', it: 'Anti-Clamidia Trachomatis IgA (ACLAT IgA)' }, 
    units: ['qualitative'] 
  },
  ACLAT_IgG: { 
    label: { en: 'Anti-Chlamydia Trachomatis IgG (ACLAT IgG)', it: 'Anti-Clamidia Trachomatis IgG (ACLAT IgG)' }, 
    units: ['qualitative'] 
  },
  ACLAT_IgM: { 
    label: { en: 'Anti-Chlamydia Trachomatis IgM (ACLAT IgM)', it: 'Anti-Clamidia Trachomatis IgM (ACLAT IgM)' }, 
    units: ['qualitative'] 
  },
  ADES1: { 
    label: { en: 'Anti-Desmoglein 1 (ADES1)', it: 'Anti-Desmogleina 1 (ADES1)' }, 
    units: ['qualitative'] 
  },
  ADES3: { 
    label: { en: 'Anti-Desmoglein 3 (ADES3)', it: 'Anti-Desmogleina 3 (ADES3)' }, 
    units: ['qualitative'] 
  },
  ADIF: { 
    label: { en: 'Anti-Diphtheria Antibody (ADIF)', it: 'Anti-Difterite Ab (ADIF)' }, 
    units: ['qualitative'] 
  },
  ADNA: { 
    label: { en: 'Anti-DNA (ADNA)', it: 'Anti-DNA (ADNA)' }, 
    units: ['qualitative'] 
  },
  AFI_IgG: { 
    label: { en: 'Anti-Intrinsic Factor IgG (AFI IgG)', it: 'Anti-Fattore Intrinseco IgG (AFI IgG)' }, 
    units: ['qualitative'] 
  },
  AGAD: { 
    label: { en: 'Anti-GAD (AGAD)', it: 'Anti-GAD (AGAD)' }, 
    units: ['qualitative'] 
  },
  AGAN: { 
    label: { en: 'Anti-Gangliosides (AGAN)', it: 'Anti-Gangliosidi (AGAN)' }, 
    units: ['qualitative'] 
  },
  AHHV6_IgG: { 
    label: { en: 'Anti-HHV-6 IgG (AHHV6 IgG)', it: 'Anti-HHV-6 IgG (AHHV6 IgG)' }, 
    units: ['qualitative'] 
  },
  AICA: { 
    label: { en: 'Anti-Islet Cell Antibodies (ICA)', it: 'Anti-Insula Pancreatica (ICA)' }, 
    units: ['qualitative'] 
  },
  AHIS: { 
    label: { en: 'Anti-Histones (AHIS)', it: 'Anti-Istoni (AHIS)' }, 
    units: ['qualitative'] 
  },
  ALIS: { 
    label: { en: 'Anti-Listeria IgG (ALIS)', it: 'Anti-Listeria IgG (ALIS)' }, 
    units: ['qualitative'] 
  },
  AMPO: { 
    label: { en: 'Anti-MPO (AMPO)', it: 'Anti-MPO (AMPO)' }, 
    units: ['qualitative'] 
  },
  AMUSK: { 
    label: { en: 'Anti-MuSK (AMUSK)', it: 'Anti-MuSK (AMUSK)' }, 
    units: ['qualitative'] 
  },
  ANUC: { 
    label: { en: 'Anti-Nucleosomes (ANUC)', it: 'Anti-Nucleosomi (ANUC)' }, 
    units: ['qualitative'] 
  },
  APR3: { 
    label: { en: 'Anti-PR3 (APR3)', it: 'Anti-PR3 (APR3)' }, 
    units: ['qualitative'] 
  },
  AR_TSH: { 
    label: { en: 'Anti-TSH Receptor (AR TSH)', it: 'Anti-Recettori TSH (AR TSH)' }, 
    units: ['qualitative'] 
  },
  ASLA_LP: { 
    label: { en: 'Anti-SLA/LP (ASLA/LP)', it: 'Anti-SLA/LP (ASLA/LP)' }, 
    units: ['qualitative'] 
  },
  ASUR: { 
    label: { en: 'Anti-Adrenal Antibodies (ASUR)', it: 'Anti-Surrene (ASUR)' }, 
    units: ['qualitative'] 
  },
  ATIREO: { 
    label: { en: 'Anti-Thyroglobulin (ATIREO)', it: 'Anti-Tireoglobulina (ATIREO)' }, 
    units: ['qualitative'] 
  },
  ATOX_IgA: { 
    label: { en: 'Anti-Toxoplasma IgA (ATOX IgA)', it: 'Anti-Toxo IgA (ATOX IgA)' }, 
    units: ['qualitative'] 
  },
  ATOX_IGG_AVIDITY: { 
    label: { en: 'Anti-Toxoplasma IgG Avidity (ATOX IgG Avidity)', it: 'Anti-Toxo IgG Avidità (ATOX IgG Avidity)' }, 
    units: ['qualitative'] 
  },
  ATRE: { 
    label: { en: 'Anti-Treponema Pallidum (ATRE)', it: 'Anti-Treponema Pallidum (ATRE)' }, 
    units: ['qualitative'] 
  },
  AAIAA: { 
    label: { en: 'Anti-Insulin Autoantibodies (AAIAA)', it: 'Anti-AIAA (anti insulina)' }, 
    units: ['qualitative'] 
  },
  AMAG: { 
    label: { en: 'Anti-MAG (AMAG)', it: 'Anti-MAG (AMAG)' }, 
    units: ['qualitative'] 
  },
  ATF: { 
    label: { en: 'Anti-Tyrosine Phosphatase (IA-2) (ATF)', it: 'Anti-Tirosina Fosfatasi (IA2) (ATF)' }, 
    units: ['qualitative'] 
  },
  AAQU: { 
    label: { en: 'Anti-Aquaporin Antibodies (AAQU)', it: 'Antiacquaporina Anticorpi (AAQU)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_BACILLUS: { 
    label: { en: 'Antibiogram Bacillus species (ANTIBAC Bacillus)', it: 'Antibiogramma Bacillus Species (ANTIBAC Bacillus)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_ECOLI: { 
    label: { en: 'Antibiogram E. Coli Type 1 (ANTIBAC E. Coli)', it: 'Antibiogramma E. Coli Tipo 1 (ANTIBAC E. Coli)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_ESP_1: { 
    label: { en: 'Antibiogram Sputum Type 1 (ANTIBAC Esp 1)', it: 'Antibiogramma Espettorato Tipo 1 (ANTIBAC Esp 1)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_ESP_2: { 
    label: { en: 'Antibiogram Sputum Type 2 (ANTIBAC Esp 2)', it: 'Antibiogramma Espettorato Tipo 2 (ANTIBAC Esp 2)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_ENT_FAECALIS: { 
    label: { en: 'Antibiogram Enterococcus Faecalis (ANTIBAC Ent. Faecalis)', it: 'Antibiogramma Enterococcus Faecalis (ANTIBAC Ent. Faecalis)' }, 
    units: ['qualitative'] 
  },

  ANTIBAC_GRAM_NEG: { 
    label: { en: 'Antibiogram Gram Negative (ANTIBAC Gram Neg)', it: 'Antibiogramma Gram Negativi (ANTIBAC Gram Neg)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_GRAM_POS: { 
    label: { en: 'Antibiogram Gram Positive (ANTIBAC Gram Pos)', it: 'Antibiogramma Gram Positivi (ANTIBAC Gram Pos)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_GRAM_PED: { 
    label: { en: 'Antibiogram Pediatric Gram Negative (ANTIBAC Gram Ped)', it: 'Antibiogramma Gram- Pediatrico (ANTIBAC Gram Ped)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_HAEMO: { 
    label: { en: 'Antibiogram Haemophilus Influenzae (ANTIBAC Haemo)', it: 'Antibiogramma Haemophilus Influenzae (ANTIBAC Haemo)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_MIC: { 
    label: { en: 'Antibiogram in MIC (ANTIBAC MIC)', it: 'Antibiogramma in MIC (ANTIBAC MIC)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_MYCO_UREA: { 
    label: { en: 'Antibiogram Mycoplasma/Ureaplasma (ANTIBAC Myco/Urea)', it: 'Antibiogramma Mycoplasma/Ureaplasma (ANTIBAC Myco/Urea)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_NEISS: { 
    label: { en: 'Antibiogram Neisseria Gonorrhoeae (ANTIBAC Neiss)', it: 'Antibiogramma Neisseria Gonorrhoeae (ANTIBAC Neiss)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_PSEUD: { 
    label: { en: 'Antibiogram Pseudomonas (ANTIBAC Pseud)', it: 'Antibiogramma Pseudomonas (ANTIBAC Pseud)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_SEC_LING_2: { 
    label: { en: 'Antibiogram Lingual Secretion Type 2 (ANTIBAC Sec Ling 2)', it: 'Antibiogramma Secreto Linguale Tipo 2 (ANTIBAC Sec Ling 2)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_STREP_PNEU: { 
    label: { en: 'Antibiogram Streptococcus Pneumoniae (ANTIBAC Strep Pneu)', it: 'Antibiogramma Streptococcus Pneumoniae (ANTIBAC Strep Pneu)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_STREP_PYO: { 
    label: { en: 'Antibiogram Streptococcus Pyogenes (ANTIBAC Strep Pyo)', it: 'Antibiogramma Streptococcus Pyogenes (ANTIBAC Strep Pyo)' }, 
    units: ['qualitative'] 
  },
  ANTIBAC_TAMP_FAR: { 
    label: { en: 'Antibiogram Pharyngeal Swab (ANTIBAC Tamp Far)', it: 'Antibiogramma Tampone Faringeo (ANTIBAC Tamp Far)' }, 
    units: ['qualitative'] 
  },
  ACLA_IgA: { 
    label: { en: 'Anti-Cardiolipin IgA (ACLA IgA)', it: 'Anticardiolipina IgA (ACLA IgA)' }, 
    units: ['qualitative'] 
  },
  ACLA_IgG: { 
    label: { en: 'Anti-Cardiolipin IgG (ACLA IgG)', it: 'Anticardiolipina IgG (ACLA IgG)' }, 
    units: ['qualitative'] 
  },
  ACIT: { 
    label: { en: 'Anti-Citrulline (ACIT)', it: 'Anticitrullina (ACIT)' }, 
    units: ['qualitative'] 
  },
  ALAC: { 
    label: { en: 'Lupus Anticoagulant (LAC)', it: 'Anticoagulante Lupus Like (ALAC)' }, 
    units: ['qualitative'] 
  },
  AAQU_IgG: { 
    label: { en: 'Anti-Aquaporin IgG (AAQU IgG)', it: 'Anticorpi Anti Acquaporina IgG (AAQU IgG)' }, 
    units: ['qualitative'] 
  },
  ANCA: { 
    label: { en: 'Anti-Cytoplasmic Antibodies (ANCA)', it: 'Anticorpi Anti Citoplasma (ANCA)' }, 
    units: ['qualitative'] 
  },
  AHTLV: { 
    label: { en: 'Anti-HTLV I/II (AHTLV)', it: 'Anticorpi Anti HTLV I/II (AHTLV)' }, 
    units: ['qualitative'] 
  },
  AANA: { 
    label: { en: 'Anti-Nucleus Antibodies (ANA)', it: 'Anticorpi Anti Nucleo (ANA)' }, 
    units: ['qualitative'] 
  },
  
  APAR_IgM: { 
    label: { en: 'Anti-Mumps IgM (APAR IgM)', it: 'Anticorpi Anti Parotite (IgM) (APAR IgM)' }, 
    units: ['qualitative'] 
  },
  APAR_IgG: { 
    label: { en: 'Anti-Mumps IgG (APAR IgG)', it: 'Anticorpi Anti Parotite (IgG) (APAR IgG)' }, 
    units: ['qualitative'] 
  },
  APF4: { 
    label: { en: 'Anti-PF4 Antibodies (APF4)', it: 'Anticorpi Anti PF4 (APF4)' }, 
    units: ['qualitative'] 
  },
  APIAST: { 
    label: { en: 'Anti-Platelet Antibodies (APIAST)', it: 'Anticorpi Anti Piastrine (APIAST)' }, 
    units: ['qualitative'] 
  },
  AADEN_IgA: { 
    label: { en: 'Anti-Adenovirus IgA (AADEN IgA)', it: 'Anticorpi Antiadenovirus IgA (AADEN IgA)' }, 
    units: ['qualitative'] 
  },
  AADEN_IgG: { 
    label: { en: 'Anti-Adenovirus IgG (AADEN IgG)', it: 'Anticorpi Antiadenovirus IgG (AADEN IgG)' }, 
    units: ['qualitative'] 
  },
  AEPA: { 
    label: { en: 'Anti-Liver Antigen Antibodies (AEPA)', it: 'Anticorpi Anti Antigeni Epatici (AEPA)' }, 
    units: ['qualitative'] 
  },
  AERIT: { 
    label: { en: 'Anti-Erythrocyte Antibodies (AERIT)', it: 'Anticorpi Antieritrocitari (AERIT)' }, 
    units: ['qualitative'] 
  },
  AAMUS: { 
    label: { en: 'Anti-Striated Muscle Antibodies (AAMUS)', it: 'Anticorpi Antimuscolo Striato (AAMUS)' }, 
    units: ['qualitative'] 
  },
  AMIC: { 
    label: { en: 'Antimycogram Fungi (AMIC)', it: 'Antimicogramma Miceti (AMIC)' }, 
    units: ['qualitative'] 
  },
  ANEU: { 
    label: { en: 'Anti-Neuronal Nucleus Antibodies (ANEU)', it: 'Antinucleo Neuronale (ANEU)' }, 
    units: ['qualitative'] 
  },
  ARACH: { 
    label: { en: 'Anti-Acetylcholine Receptor Antibodies (ARACH)', it: 'Antirecettore ACh (ARACH)' }, 
    units: ['qualitative'] 
  },
  ATIII: { 
    label: { en: 'Antithrombin III (ATIII)', it: 'Antitrombina III (ATIII)' }, 
    units: ['qualitative'] 
  },
  ABO: { 
    label: { en: 'ABO Blood Group (ABO)', it: 'Gruppo Sanguigno ABO (ABO)' }, 
    units: ['qualitative'] 
  },
  RH: { 
    label: { en: 'Rh Factor (RH)', it: 'Fattore Rh (RH)' }, 
    units: ['qualitative'] 
  },
  FSH: { 
    label: { en: 'Follicle-Stimulating Hormone (FSH)', it: 'Ormone Follicolo-Stimolante (FSH)' }, 
    units: ['mIU/mL'] 
  },
  LH: { 
    label: { en: 'Luteinizing Hormone (LH)', it: 'Ormone Luteinizzante (LH)' }, 
    units: ['mIU/mL'] 
  },
  PROTS: { 
    label: { en: 'Protein S (PROTS)', it: 'Proteina S (PROTS)' }, 
    units: ['%', 'mg/dL'] 
  },
  
  HBV: { 
    label: { en: 'Hepatitis B Markers (HBV)', it: 'Marker Epatite B (HBV)' }, 
    units: ['qualitative/quantitative'] 
  },

  
  HCV: { 
    label: { en: 'Hepatitis C Markers (HCV)', it: 'Marker Epatite C (HCV)' }, 
    units: ['qualitative/quantitative'] 
  },
  
  HAV: { 
    label: { en: 'Hepatitis A Markers (HAV)', it: 'Marker Epatite A (HAV)' }, 
    units: ['qualitative/quantitative'] 
  },
  
  HIV: { 
    label: { en: 'Human Immunodeficiency Virus (HIV)', it: 'Virus dell\'Immunodeficienza Umana (HIV)' }, 
    units: ['qualitative/quantitative'] 
  },
  TT: { 
    label: { en: 'Thrombin Time (TT)', it: 'Tempo di Trombina (TT)' }, 
    units: ['seconds'] 
  },
  
  FIB: { 
    label: { en: 'Fibrinogen (FIB)', it: 'Fibrinogeno (FIB)' }, 
    units: ['mg/dL', 'g/L'] 
  },
  
  D_DIMER: { 
    label: { en: 'D-Dimer (D-DIMER)', it: 'D-Dimero (D-DIMER)' }, 
    units: ['µg/mL', 'ng/mL'] 
  },
  ACT: { 
    label: { en: 'Activated Clotting Time (ACT)', it: 'Tempo di Coagulazione Attivato (ACT)' }, 
    units: ['seconds'] 
  },
  CMV: { 
    label: { en: 'Cytomegalovirus (CMV)', it: 'Citomegalovirus (CMV)' }, 
    units: ['qualitative', 'IU/mL'] 
  },
  
  ROSOLIA: { 
    label: { en: 'Rubella (Rosolia)', it: 'Rosolia (ROSOLIA)' }, 
    units: ['qualitative', 'IU/mL'] 
  },
  
  HERPES: { 
    label: { en: 'Herpes Simplex Virus I and II (HERPES I & II)', it: 'Virus Herpes Simplex I e II (HERPES I e II)' }, 
    units: ['qualitative', 'IU/mL'] 
  },
  
  AUTOIMMUNITY_TUMOR_MARKERS: { 
    label: { en: 'Autoimmunity and Tumor Markers HE 4 + CA125 with R.O.M.A.', it: 'Autoimmunità e Markers Tumorali HE 4 + CA125 con R.O.M.A.' }, 
    units: ['qualitative', 'U/mL'] 
  },

  COOMBS: { 
    label: { en: 'Coombs Test (Direct and Indirect)', it: 'Test di Coombs Diretto e Indiretto (COOMBS)' }, 
    units: ['qualitative'] 
  },
  
  IgE_TOTAL: { 
    label: { en: 'Total IgE (IgE TOTAL)', it: 'IgE Totali (IgE TOTAL)' }, 
    units: ['IU/mL'] 
  },
  
  RAST: { 
    label: { en: 'RAST (Specific Allergens)', it: 'RAST (Allergeni Specifici)' }, 
    units: ['kU/L'] 
  },
  
  G6PDH: { 
    label: { en: 'G6PDH (Glucose-6-Phosphate Dehydrogenase)', it: 'G6PDH (Glucosio-6-Fosfato Deidrogenasi)' }, 
    units: ['U/gHb'] 
  },
  
  dROMs: { 
    label: { en: 'dROMs Test (Reactive Oxygen Metabolites)', it: 'dROMs Test (Metaboliti dell\'Ossigeno Reattivo)' }, 
    units: ['CARR U'] 
  },
  
  BAP: { 
    label: { en: 'BAP Test (Biological Antioxidant Potential)', it: 'BAP Test (Potenziale Antiossidante Biologico)' }, 
    units: ['µmol/L'] 
  },
  
  OMEGA_3_6_RATIO: { 
    label: { en: 'Omega 3/6 Ratio', it: 'Rapporto Omega 3/6' }, 
    units: ['ratio'] 
  },
  
  ANTI_ENDOMYSIAL_AB: { 
    label: { en: 'Anti-Endomysial Antibodies (EMA)', it: 'Anticorpi Anti Endomisio (EMA)' }, 
    units: ['qualitative'] 
  },
  
  ANTI_GLIADIN_AB: { 
    label: { en: 'Anti-Gliadin Antibodies (AGA)', it: 'Anticorpi Anti Gliadina (AGA)' }, 
    units: ['qualitative'] 
  },
  
  ANTI_TRANSGLUTAMINASE_AB: { 
    label: { en: 'Anti-Transglutaminase Antibodies (tTG)', it: 'Anticorpi Anti Transglutaminasi (tTG)' }, 
    units: ['qualitative', 'U/mL'] 
  },

};

