import React from 'react';

const colors = {
  primary: '#1a237e',
  secondary: '#4caf50',
  accent: '#ff9800',
  background: '#f0f4f8',
  text: '#333333',
};

const headingStyle = {
  fontWeight: 'bold',
  marginBottom: '16px',
  color: colors.primary,
};

const sectionStyle = {
  marginBottom: '20px',
};

const TermsOfUse = () => {
  return (
    <div style={{ backgroundColor: colors.background, color: colors.text, padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ ...headingStyle, fontSize: '2rem' }}>Med Web Reports Terms of Use</h1>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>1. Acceptance of the Terms of Use</h2>
        <p>
          These Terms of Use (“Terms”) are a binding agreement between you (“You” or “Your”) and Med Web Reports. By accessing
          or using the Med Web Reports application (“App”), you agree to comply with and be bound by these Terms. If you do not
          agree to these Terms, you must not access or use the App.
        </p>
        <p>
          Med Web Reports reserves the right to modify these Terms at any time. Changes take effect immediately upon posting.
          Your continued use of the App after any changes indicates your acceptance of the revised Terms.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>2. Description of Services</h2>
        <p>
          Med Web Reports provides an application that offers health insights and analytics based on user-provided data,
          including patient history, body measurements, and blood test results. The App utilizes artificial intelligence
          (AI) to generate personalized health information and recommendations.
        </p>
        <p>
          <strong>Features Include:</strong>
        </p>
        <ul>
          <li>Personalized health reports and analytics</li>
          <li>Data visualization tools</li>
          <li>Integration with third-party health services</li>
          <li>Secure storage and management of personal health data</li>
          <li>Customer support and troubleshooting</li>
        </ul>
        <p>
          Med Web Reports may introduce new features or discontinue existing ones at its discretion.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>3. Accessing the App and Account Security</h2>
        <p>
          Med Web Reports may modify or discontinue the App or any part of it without notice. We are not liable if any portion
          of the App is unavailable at any time. We may restrict access to parts or all of the App, including to
          registered users, at our discretion.
        </p>
        <p>
          To access certain features, you may need to register and provide accurate, current information. You agree to
          maintain the confidentiality of your account information, including your password, and to notify us immediately
          of any unauthorized access or use of your account.
        </p>
        <p>
          Med Web Reports reserves the right to disable any user account, user name, password, or other identifier at any time
          if, in our opinion, you have violated any provision of these Terms.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>4. Intellectual Property Rights</h2>
        <p>
          The App and its entire contents, including information, software, text, images, video, audio, and the design
          and arrangement thereof, are owned by Med Web Reports, its licensors, or other providers and are protected by
          intellectual property laws.
        </p>
        <p>
          These Terms permit you to use the App for personal, non-commercial use only. Unauthorized reproduction,
          distribution, modification, or public display of content on the App is prohibited and may result in termination
          of access and legal action.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>5. Data Protection and Privacy</h2>
        <p>
          Med Web Reports is committed to protecting your personal data in accordance with the General Data Protection
          Regulation (GDPR) and other applicable laws. All user data is encrypted and securely stored in Firebase
          Firestore. The App will request your explicit consent before saving any personal data.
        </p>
        <p>
          You have the right to download and delete your data at any time. Med Web Reports will not share your personal data
          with third parties without your explicit consent, except as required by law. For more details, please refer to
          our <a href="/privacy-policy">Privacy Policy</a>.
        </p>
        <p>
          <strong>Data Transfers:</strong> Your data may be transferred and stored outside the European Economic Area (EEA).
          We ensure that such transfers are protected by appropriate safeguards in compliance with GDPR.
        </p>
        <p>
          <strong>Automated Decision-Making:</strong> The App may use AI algorithms to generate insights. Users have the
          right to request information about the logic involved and to contest any decisions based solely on automated
          processing.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>6. AI-Generated Insights Disclaimer</h2>
        <p>
          The insights provided by Med Web Reports are generated by artificial intelligence (AI) and are intended to
          supplement, not replace, professional medical advice. AI-generated data may occasionally be inaccurate or
          misleading. Users should always consult a healthcare provider before making health-related decisions based on
          information provided by the App.
        </p>
        <p>
          Med Web Reports disclaims any liability for decisions made based on AI-generated data. The App is designed to
          provide valuable health information but should not be used as the sole basis for medical decision-making.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>7. Purchases and No Refund Policy</h2>
        <p>
          Med Web Reports operates on a credit-based system for accessing premium features within the App. Upon registration,
          users receive 20 free credits. Additional credits can be purchased through the Stripe API, with options
          including 300 credits for €14.99 and 600 credits for €24.99. Custom credit packages are available by
          contacting <a href="mailto:info@medwebreports.io">info@medwebreports.io</a>.
        </p>
        <p>
          All purchases of credits are final and non-refundable. By purchasing credits, you agree to this no refund
          policy. Med Web Reports reserves the right to modify credit pricing and availability at any time without prior notice.
        </p>
        <p>
          <strong>Payment Terms:</strong> Payments for credits are processed securely through the Stripe API. By making a
          purchase, you authorize Med Web Reports to charge your chosen payment method for the selected credit package.
        </p>
        <p>
          <strong>Billing Cycle:</strong> All purchases are one-time payments. No recurring charges will be applied unless
          explicitly stated.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>8. Prohibited Uses</h2>
        <p>
          You may use the App only for lawful purposes and in accordance with these Terms. You agree not to use the App
          in any way that:
        </p>
        <ul>
          <li>Violates any applicable law or regulation.</li>
          <li>Impersonates Med Web Reports, a Med Web Reports employee, another user, or any other person or entity.</li>
          <li>Disables, overburdens, damages, or impairs the App, or interferes with any other party’s use of the App.</li>
          <li>Introduces viruses, Trojan horses, worms, or other material that is malicious or technologically harmful.</li>
          <li>Attempts to gain unauthorized access to any portion of the App, other accounts, computer systems, or networks connected to the App.</li>
          <li>Uses the App to transmit unsolicited bulk communications or spam.</li>
        </ul>
        <p>
          Med Web Reports reserves the right to terminate your access to the App for violating these prohibited uses or for any
          other reason deemed appropriate by Med Web Reports.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>9. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, Med Web Reports will not be liable for any direct, indirect, incidental,
          consequential, or punitive damages arising out of your use or inability to use the App. This includes, but is
          not limited to, damages for errors, omissions, interruptions, defects, delays, or loss of data.
        </p>
        <p>
          Med Web Reports' total liability for any claim arising from or related to the App is limited to the amount you paid,
          if any, for accessing the App’s premium services.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>10. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless Med Web Reports, its officers, directors, employees, agents,
          and affiliates from and against any claims, liabilities, damages, losses, or expenses, including without
          limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to
          or use of the App, your violation of these Terms, or your violation of any rights of another.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>11. Governing Law and Dispute Resolution</h2>
        <p>
          These Terms and any disputes arising from or related to them shall be governed by and construed in accordance
          with the laws of Italy.
        </p>
        <p>
          The parties agree to first attempt to resolve any dispute arising from these Terms through good faith
          negotiation or mediation. If the parties are unable to resolve the dispute through these methods, the matter
          shall be submitted to binding arbitration, conducted in accordance with the rules of the Arbitration Chamber
          of Milan or another mutually agreed-upon arbitration institution. The arbitration shall take place in Italy,
          and the proceedings shall be conducted in the Italian language. The decision of the arbitrator shall be final
          and binding on both parties.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>12. Termination</h2>
        <p>
          Med Web Reports may terminate or suspend your access to the App immediately, without prior notice or liability,
          for any reason, including without limitation if you breach the Terms.
        </p>
        <p>
          Upon termination, your right to use the App will immediately cease. If you wish to terminate your account, you
          may simply discontinue using the App.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive termination shall survive termination,
          including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>13. Modification of Services</h2>
        <p>
          Med Web Reports reserves the right to modify, suspend, or discontinue, temporarily or permanently, the App or any
          part of it with or without notice. You agree that Med Web Reports will not be liable to you or to any third party for
          any modification, suspension, or discontinuance of the App.
        </p>
        <p>
          In the event of significant changes to the services, Med Web Reports will notify users through the App or via email,
          providing details about the changes and any actions users may need to take.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>14. User-Generated Content</h2>
        <p>
          If the App allows users to submit, post, or otherwise make available content, you retain ownership of any intellectual
          property rights that you hold in that content. By submitting content to the App, you grant Med Web Reports a
          worldwide, non-exclusive, royalty-free license to use, copy, modify, create derivative works based on, distribute,
          publicly display, publicly perform, and otherwise exploit in any manner such content in all formats and distribution
          channels now known or hereafter devised.
        </p>
        <p>
          You represent and warrant that you have all rights, power, and authority necessary to grant the rights granted
          herein to any content that you submit. Med Web Reports reserves the right to remove any user-generated content that
          violates these Terms or is deemed inappropriate at our sole discretion.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>15. No Warranty</h2>
        <p>
          The App is provided on an "AS IS" and "AS AVAILABLE" basis. Med Web Reports disclaims all warranties of any kind,
          whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a
          particular purpose, and non-infringement.
        </p>
        <p>
          Med Web Reports does not warrant that the App will meet your requirements, be uninterrupted, timely, secure, or error-free.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>16. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless Med Web Reports, its officers, directors, employees, agents,
          and affiliates from and against any claims, liabilities, damages, losses, or expenses, including without
          limitation reasonable legal and accounting fees, arising out of or in any way connected with your access to
          or use of the App, your violation of these Terms, or your violation of any rights of another.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>17. Accessibility and Language</h2>
        <p>
          Med Web Reports strives to make the App accessible to all users. If you encounter any accessibility issues, please
          contact us at <a href="mailto:info@medwebreports.io">info@medwebreports.io</a> so we can address them promptly.
        </p>
        <p>
          These Terms are available in [languages your App supports]. In the event of any discrepancies between translations, the English version shall prevail.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>18. Waiver and Severability</h2>
        <p>
          No waiver by Med Web Reports of any term or condition set forth in these Terms shall be deemed a further or continuing
          waiver of such term or condition or a waiver of any other term or condition. If any provision of these Terms is
          held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any
          reason, such provision shall be eliminated or limited to the minimum extent necessary so that the remaining
          provisions of the Terms will continue in full force and effect.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>19. Entire Agreement</h2>
        <p>
          These Terms constitute the sole and entire agreement between you and Med Web Reports regarding the App and supersede
          all prior and contemporaneous understandings, agreements, representations, and warranties, both written and
          oral, regarding the App.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>20. Contact Information</h2>
        <p>
          This App is operated by Med Web Reports. For any comments, concerns, or questions regarding these Terms or the App,
          please contact us at <a href="mailto:info@medwebreports.io">info@medwebreports.io</a>. We are committed to assisting
          you and ensuring your experience with Med Web Reports is positive and secure.
        </p>
      </section>
    </div>
  );
};

export default TermsOfUse;
