import React from 'react';

const colors = {
  primary: '#1a237e',
  secondary: '#4caf50',
  accent: '#ff9800',
  background: '#f0f4f8',
  text: '#333333',
};

const headingStyle = {
  fontWeight: 'bold',
  marginBottom: '16px',
  color: colors.primary,
};

const sectionStyle = {
  marginBottom: '20px',
};

const PrivacyPolicy = () => {
  return (
    <div style={{ backgroundColor: colors.background, color: colors.text, padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1 style={{ ...headingStyle, fontSize: '2rem' }}>Med Web Reports Privacy Policy</h1>

      <p>
        <strong>Effective Date:</strong> 26/08/2024
      </p>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>1. Overview</h2>
        <p>
          Med Web Reports (“we”, “us”, or “our”) is committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use the Med Web Reports application (“App”). Our commitment to privacy is grounded in our compliance with the General Data Protection Regulation (GDPR) and applicable Italian laws.
        </p>
        <p>
          We believe that transparency is key to building trust. This Privacy Policy aims to provide you with a clear understanding of how your data is handled, the rights you have concerning your personal data, and the measures we take to protect your privacy.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>2. Scope</h2>
        <p>
          This Privacy Policy applies to all users of the Med Web Reports App. By using our services, you agree to the collection, use, and disclosure of your personal information as described in this policy. If you do not agree with any part of this policy, please refrain from using our services.
        </p>
        <p>
          Please note that our App may contain links to third-party websites or services that are not controlled by us. We are not responsible for the privacy practices of these external sites, and we encourage you to review their privacy policies before sharing any personal information.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>3. Data Controller</h2>
        <p>
          Med Web Reports acts as the "data controller" for the personal information you provide to us. As the data controller, we determine the purposes and means of processing your personal data. Our responsibility is to ensure that your data is processed lawfully, fairly, and transparently in accordance with GDPR and other applicable laws.
        </p>
        <p>
          Should you have any questions or concerns about how your personal data is handled, or if you wish to exercise your data rights, you can contact us at <a href="mailto:info@medwebreports.io">info@medwebreports.io</a>.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>4. Information We Collect</h2>

        <h3 style={{ fontWeight: 'bold', color: colors.accent }}>4.1 Personal Information</h3>
        <p>
          We collect personal information that you provide directly to us when you use the Med Web Reports App. This may include, but is not limited to:
        </p>
        <ul>
          <li>Your email address and contact information</li>
          <li>Patient history, body measurements, and blood test results</li>
          <li>Any other data you choose to provide within the App</li>
        </ul>

        <h3 style={{ fontWeight: 'bold', color: colors.accent }}>4.2 Automatically Collected Information</h3>
        <p>
          We may automatically collect certain information about your device and usage of our App. This includes:
        </p>
        <ul>
          <li>Your IP address and device type</li>
          <li>Browser type and version</li>
          <li>Usage data, such as interactions with the App and error reports</li>
        </ul>
        <p>
          This data is used to improve the functionality and performance of our App and to ensure a seamless user experience.
        </p>

        <h3 style={{ fontWeight: 'bold', color: colors.accent }}>4.3 Information from Other Sources</h3>
        <p>
          We may receive information about you from third parties, such as public databases or social media platforms. This information may be combined with the data we already have to enhance our services and provide you with more tailored experiences. We ensure that any third-party data is processed in compliance with this Privacy Policy and applicable laws.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>5. Legal Basis for Processing</h2>
        <p>
          We process your personal data based on the following legal grounds:
        </p>
        <ul>
          <li><strong>Consent:</strong> When you provide explicit consent for specific data processing activities.</li>
          <li><strong>Contractual Necessity:</strong> To fulfill our contractual obligations with you.</li>
          <li><strong>Legitimate Interests:</strong> For purposes such as improving our services, ensuring security, and preventing fraud.</li>
          <li><strong>Legal Obligations:</strong> To comply with legal requirements and regulatory obligations.</li>
        </ul>
        <p>
          Each processing activity is based on one or more of these legal grounds, ensuring that your data is handled lawfully.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>6. How We Use Your Information</h2>
        <p>
          The personal information we collect is used for the following purposes to enhance your experience and ensure the effective operation of our services:
        </p>
        <ul>
          <li>
            <strong>Service Delivery:</strong> To provide and personalize the health insights and services you request, based on your patient history, body measurements, and blood test results.
          </li>
          <li>
            <strong>Communication:</strong> To communicate with you regarding your account, respond to your inquiries, and send you updates about our services. This includes essential transactional emails and notifications.
          </li>
          <li>
            <strong>Account Management:</strong> To manage your account, including account setup, verification, maintenance, and the provision of secure access to your data.
          </li>
          <li>
            <strong>Customer Support:</strong> To provide you with customer support, troubleshoot issues, and address any concerns or feedback you may have about our services.
          </li>
          <li>
            <strong>Improvement of Services:</strong> To analyze usage data and feedback to improve the functionality, performance, and user experience of our App.
          </li>
          <li>
            <strong>Legal Compliance:</strong> To fulfill our legal obligations, including tax reporting and compliance with data protection laws.
          </li>
          <li>
            <strong>Fraud Prevention and Security:</strong> To protect your data and our App from unauthorized access, fraud, and other security risks.
          </li>
          <li>
            <strong>Automated Decision-Making:</strong> To utilize AI algorithms for generating insights. We ensure that any automated decisions are fair and transparent.
          </li>
          <li>
            <strong>Aggregated and Anonymized Data:</strong> To create statistical reports and analyses that help us understand trends and improve our services. This data does not identify you personally.
          </li>
        </ul>
        <p>
          We are committed to using your data only for the purposes for which it was collected and ensuring that it is handled in a secure and lawful manner.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>7. Data Sharing</h2>
        <p>
          We value your privacy and will not share your personal information with third parties without your explicit consent, except in the following circumstances:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> We may share your personal information with third-party service providers who assist us in delivering our services, such as cloud storage providers, payment processors, and customer support platforms. These providers are contractually obligated to protect your data and use it only for the services they provide to us.
          </li>
          <li>
            <strong>Legal Authorities:</strong> We may disclose your personal information to legal authorities if required by law or to comply with a valid legal process, such as a court order or subpoena.
          </li>
          <li>
            <strong>Business Transactions:</strong> In the event of a merger, acquisition, or sale of all or part of our business, your personal data may be transferred to the acquiring entity. We will ensure that any such transfers are conducted in accordance with this Privacy Policy and applicable laws.
          </li>
          <li>
            <strong>With Your Consent:</strong> We may share your data with third parties if you have explicitly consented to such sharing. This may include sharing your health data with third-party apps or services that integrate with Med Web Reports, subject to your approval.
          </li>
        </ul>
        <p>
          <strong>Data Transfers Outside the EEA:</strong> If your personal data is transferred outside the European Economic Area (EEA), we ensure that appropriate safeguards are in place, such as Standard Contractual Clauses, to protect your data in accordance with GDPR requirements.
        </p>
        <p>
          We will never sell your personal data to third parties for marketing purposes without your explicit consent. Your trust is important to us, and we are committed to protecting your privacy.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>8. Data Security</h2>
        <p>
          We take the security of your personal information seriously and implement industry-standard security measures to protect your data. This includes encryption, secure storage in Firebase Firestore, and robust authentication protocols to prevent unauthorized access.
        </p>
        <p>
          Despite our best efforts, no online platform can guarantee absolute security. We encourage you to protect your account by using a strong, unique password and being cautious with the sharing of your login credentials. If you suspect any unauthorized access to your account, please contact us immediately at <a href="mailto:info@medwebreports.io">info@medwebreports.io</a>.
        </p>
        <p>
          <strong>Breach Notification Procedures:</strong> In the unlikely event of a data breach, we will take prompt action to mitigate the breach, notify affected users without undue delay, and comply with all applicable data protection laws, including notifying relevant authorities if required.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>9. User Rights</h2>
        <p>
          Under GDPR and other applicable laws, you have the following rights concerning your personal data:
        </p>
        <ul>
          <li>
            <strong>Right to Access:</strong> You can request access to the personal data we hold about you and obtain information on how it is processed.
          </li>
          <li>
            <strong>Right to Rectification:</strong> If you believe your personal data is inaccurate or incomplete, you have the right to request correction or updating of your data.
          </li>
          <li>
            <strong>Right to Erasure (Right to be Forgotten):</strong> You can request the deletion of your personal data under certain conditions, such as if the data is no longer necessary for the purposes it was collected.
          </li>
          <li>
            <strong>Right to Restriction of Processing:</strong> You have the right to request that we limit the processing of your personal data in certain circumstances, such as when you contest the accuracy of the data.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> You can request a copy of your personal data in a structured, commonly used, machine-readable format and have the right to transmit this data to another data controller.
          </li>
          <li>
            <strong>Right to Object:</strong> You can object to the processing of your personal data for direct marketing purposes or on grounds relating to your particular situation.
          </li>
          <li>
            <strong>Right to Withdraw Consent:</strong> If we process your personal data based on your consent, you have the right to withdraw that consent at any time. This will not affect the lawfulness of processing based on consent before its withdrawal.
          </li>
        </ul>
        <p>
          <strong>Children’s Privacy:</strong> Our App is not intended for children under the age of 16. We do not knowingly collect personal data from children under 16. If you are a parent or guardian and believe that your child has provided us with personal data, please contact us at <a href="mailto:info@medwebreports.io">info@medwebreports.io</a>.
        </p>
        <p>
          To exercise any of these rights, please contact us at <a href="mailto:info@medwebreports.io">info@medwebreports.io</a>. We will respond to your request in accordance with applicable laws and within a reasonable timeframe.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>10. Data Retention and Disposal</h2>
        <p>
          We will retain your personal data only as long as necessary to fulfill the purposes for which it was collected, including for the purpose of satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          <strong>Retention Periods:</strong> We define retention periods based on the type of data and the purposes for which it is processed. For example:
        </p>
        <ul>
          <li>Account information: Retained for the duration of your account and for a period thereafter as necessary for legal compliance.</li>
          <li>Transactional data: Retained for 7 years to comply with financial regulations.</li>
          <li>Usage data: Retained for 2 years to analyze trends and improve services.</li>
        </ul>
        <p>
          Once your data is no longer needed, we will securely delete or anonymize it in accordance with our data retention policies and applicable laws. You also have the right to request the deletion of your data at any time, and we will comply with such requests in accordance with legal requirements.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>11. Cookies and Tracking Technology</h2>
        <p>
          We use cookies and similar tracking technologies to enhance your experience on our App. Cookies are small text files that are stored on your device when you visit our App. They help us remember your preferences, improve site functionality, and deliver personalized content.
        </p>
        <p>
          <strong>Types of Cookies We Use:</strong>
        </p>
        <ul>
          <li><strong>Essential Cookies:</strong> Necessary for the operation of the App.</li>
          <li><strong>Analytics Cookies:</strong> Help us understand how users interact with the App by collecting and reporting information anonymously.</li>
          <li><strong>Functionality Cookies:</strong> Allow the App to remember choices you make and provide enhanced, more personal features.</li>
          <li><strong>Advertising Cookies:</strong> Used to deliver advertisements more relevant to you and your interests.</li>
        </ul>
        <p>
          You will be asked to consent to the use of non-essential cookies when you first visit our App. You can manage your cookie preferences through your browser settings at any time. Please note that disabling certain cookies may affect your ability to use certain features of the App.
        </p>
        <p>
          For more detailed information about the cookies we use, please refer to our <a href="/cookie-policy">Cookie Policy</a>.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>12. Automated Decision-Making and Profiling</h2>
        <p>
          Our App may use automated decision-making and profiling to provide personalized health insights. This involves using algorithms to analyze your data and generate recommendations.
        </p>
        <p>
          <strong>Your Rights:</strong> You have the right to request information about the logic involved in any automated decision-making, as well as the significance and potential consequences of such processing for you.
        </p>
        <p>
          We ensure that any automated decisions are fair, transparent, and do not adversely affect your rights. If you have concerns about automated decision-making, please contact us at <a href="mailto:info@medwebreports.io">info@medwebreports.io</a>.
        </p>
      </section>

      <section style={sectionStyle}>
        <h2 style={headingStyle}>13. Updates to this Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices, legal requirements, or operational needs. Any updates will be posted on this page, and we will notify users of significant changes through the App or via email.
        </p>
        <p>
          Your continued use of the Med Web Reports App following any changes to this Privacy Policy constitutes your acceptance of the updated terms. We encourage you to review this policy periodically to stay informed about how we are protecting your data.
        </p>
      </section>

      <section>
        <h2 style={headingStyle}>14. Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding your personal data or this Privacy Policy, please contact us at <a href="mailto:info@medwebreports.io">info@medwebreports.io</a>. We are here to assist you and will respond to your inquiries promptly.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
