import React, { useState } from 'react';
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Dialog,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLanguage } from './LanguageContext';
import { initializeFirebase } from '../firebase';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { logError } from '../utils/qms';
import { decryptData } from '../utils/encryption';
import EditProfile from './EditProfile';

const UserMenu = ({ user, onDeleteAccount }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editProfileOpen, setEditProfileOpen] = useState(false); // State for EditProfile dialog
  const { language, translations } = useLanguage();
  const t = translations[language];

  const { auth, db } = initializeFirebase();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (action) => {
    handleMenuClose();
    await action();
  };

  const handleEditProfile = () => {
    setEditProfileOpen(true);
  };

  const handleDownloadData = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error('User is not logged in.');
      }

      const userId = auth.currentUser.uid;

      // Fetch profile data (assuming it's stored in the user object)
      const userProfile = {
        email: user.email,
        displayName: user.displayName,
        credits: user.credits,
        tier: user.tier
      };

      // Fetch and decrypt blood tests
      const bloodTestsQuery = query(
        collection(db, 'bloodTests'),
        where('userId', '==', userId),
        orderBy('date', 'desc')
      );
      const bloodTestsSnapshot = await getDocs(bloodTestsQuery);
      const bloodTests = bloodTestsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          date: data.date,
          results: decryptData(data.encryptedResults),
          units: decryptData(data.encryptedUnits)
        };
      });

      // Fetch and decrypt patient history
      const patientHistoryQuery = query(
        collection(db, 'patientHistory'),
        where('userId', '==', userId),
        orderBy('date', 'desc')
      );
      const patientHistorySnapshot = await getDocs(patientHistoryQuery);
      const patientHistory = patientHistorySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          date: data.date,
          newAllergies: decryptData(data.encryptedNewAllergies),
          newIntolerances: decryptData(data.encryptedNewIntolerances),
          historyOfPresentIllness: decryptData(data.encryptedHistoryOfPresentIllness),
          visitOutcomeNote: decryptData(data.encryptedVisitOutcomeNote),
          assessmentAndPlan: decryptData(data.encryptedAssessmentAndPlan),
          followUp: decryptData(data.encryptedFollowUp),
          therapy: decryptData(data.encryptedTherapy),
        };
      });

      // Fetch and decrypt body measurements
      const bodyMeasurementsQuery = query(
        collection(db, 'bodyMeasurements'),
        where('userId', '==', userId),
        orderBy('date', 'desc')
      );
      const bodyMeasurementsSnapshot = await getDocs(bodyMeasurementsQuery);
      const bodyMeasurements = bodyMeasurementsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          date: data.date,
          weight: decryptData(data.encryptedWeight),
          height: decryptData(data.encryptedHeight),
          bmi: decryptData(data.encryptedBMI),
          bloodPressure: decryptData(data.encryptedBloodPressure),
          pulse: decryptData(data.encryptedPulse)
        };
      });

      // Compile all data into a single object
      const userData = {
        profile: userProfile,
        bloodTests,
        patientHistory,
        bodyMeasurements
      };

      // Convert the userData object to a JSON string
      const dataStr = JSON.stringify(userData, null, 2);

      // Create a blob from the data string
      const blob = new Blob([dataStr], { type: 'application/json' });

      // Create a download link and trigger the download
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'patient_data.json';
      link.click();

      // Clean up
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading data:', error);
      logError('Error downloading data', error);
    }
  };

  return (
    <>
      <Tooltip title={t.userSettings || 'User Settings'} arrow>
        <IconButton onClick={handleMenuOpen} sx={{ color: 'white' }}>
          <Avatar sx={{ bgcolor: 'secondary.main' }}>
            {user.displayName ? user.displayName[0].toUpperCase() : <AccountCircleIcon />}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick(handleEditProfile)}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t.editProfile || 'Edit Profile'} />
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(handleDownloadData)}>
          <ListItemIcon>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t.downloadData || 'Download Data'} />
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick(onDeleteAccount)}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t.deleteAccount || 'Delete Account'} />
        </MenuItem>
      </Menu>

      {/* Edit Profile Dialog */}
      <Dialog open={editProfileOpen} onClose={() => setEditProfileOpen(false)}>
        <EditProfile user={user} onClose={() => setEditProfileOpen(false)} />
      </Dialog>
    </>
  );
};

export default UserMenu;
