import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Typography, Box, CircularProgress, Button, Paper, 
  Container
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import { useLanguage } from './LanguageContext';
import { useCredits } from './CreditContext';
import axios from 'axios';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: 16,
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const SuccessIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  fontSize: 80,
  color: theme.palette.success.main,
  marginBottom: theme.spacing(2),
}));

const PaymentSuccess = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { language, translations } = useLanguage();
  const { setCredits, setUserTier } = useCredits();
  const t = translations[language];
  const [updatedCredits, setUpdatedCredits] = useState(null);
  const [updatedTier, setUpdatedTier] = useState(null);
  
  const hasFetchedRef = useRef(false);

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      if (hasFetchedRef.current) return;

      const sessionId = new URLSearchParams(location.search).get('session_id');
      
      if (!sessionId) {
        setError(t.paymentVerificationFailed);
        setLoading(false);
        return;
      }
      
      try {
        const response = await axios.get(`https://www.medwebreports.io/api/payment-success?session_id=${sessionId}`);
        
        if (response.data.success) {
          console.log("Server response:", response.data);
          setUpdatedCredits(response.data.updatedCredits);
          setUpdatedTier(response.data.updatedTier);
          setCredits(response.data.updatedCredits);
          setUserTier(response.data.updatedTier);
        } else {
          console.error("Payment processing error:", response.data.error);
          setError(response.data.error || t.paymentProcessingError);
        }
      } catch (error) {
        console.error('Error confirming payment:', error);
        setError(error.response?.data?.error || error.message || t.paymentProcessingError);
      } finally {
        setLoading(false);
        hasFetchedRef.current = true;
      }
    };
    
    fetchPaymentStatus();
  }, [location.search, setCredits, setUserTier, t]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <StyledPaper elevation={3}>
          <Typography color="error" variant="h5" gutterBottom>{t.paymentProcessingError}</Typography>
          <Typography color="error" variant="body1" paragraph>{error}</Typography>
          <Button variant="contained" color="primary" onClick={() => navigate('/dashboard')} sx={{ mt: 2 }}>
            {t.backToDashboard}
          </Button>
        </StyledPaper>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <StyledPaper elevation={3}>
        <SuccessIcon />
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>{t.paymentSuccessful}</Typography>
        <Typography variant="subtitle1" paragraph>{t.thankYouForPurchase}</Typography>
        {updatedCredits !== null && (
          <Typography variant="h6" sx={{ mt: 2, fontWeight: 'medium' }}>
            {t.yourNewCreditBalance}: <span style={{ color: '#4caf50' }}>{updatedCredits}</span>
          </Typography>
        )}
        {updatedTier !== null && (
          <Typography variant="h6" sx={{ mt: 1, mb: 3, fontWeight: 'medium' }}>
            {t.yourNewTier}: <span style={{ color: '#2196f3' }}>{updatedTier}</span>
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/dashboard')}
          sx={{ mt: 2, px: 4, py: 1, borderRadius: 2 }}
        >
          {t.backToDashboard}
        </Button>
      </StyledPaper>
    </Container>
  );
};

export default PaymentSuccess;