import React, { useState, useContext } from 'react';
import {
  Button,
  TextField,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import { updateProfile, updateEmail, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { initializeFirebase } from '../firebase';
import { UserContext } from './UserContext'; // Assuming you have a UserContext to manage user state
import { useLanguage } from './LanguageContext';

const EditProfile = ({ user, onClose }) => {
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [email, setEmail] = useState(user.email || '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(null);
  const { language, translations } = useLanguage();
  const t = translations[language];
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [success, setSuccess] = useState(null);

  const { auth, db } = initializeFirebase();
  const { setUser } = useContext(UserContext); // Update user state

  const handleSave = async () => {
    setError(null);
    try {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        throw new Error(t.userNotLoggedIn || 'User is not logged in.');
      }

      // Re-authenticate the user if needed
      if (newPassword && newPassword !== confirmNewPassword) {
        setError(t.passwordsDoNotMatch || 'New passwords do not match.');
        return;
      }
  
      // Re-authenticate the user if needed
      if (currentPassword) {
        const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
        await reauthenticateWithCredential(currentUser, credential);
      } else if (email !== currentUser.email || newPassword) {
        // If changing email or password, current password is required
        setError(t.reauthenticatePrompt || 'Please enter your current password to update your profile.');
        return;
      }

      // Update display name
      if (displayName !== currentUser.displayName) {
        await updateProfile(currentUser, { displayName });
      }

      // Update email
      if (email !== currentUser.email) {
        await updateEmail(currentUser, email);
      }

      // Update password
      if (newPassword) {
        await updatePassword(currentUser, newPassword);
      }

      // Update user document in Firestore (if you store additional user data)
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        displayName,
        email,
      });

      // Update local user state
      setUser({
        ...user,
        displayName,
        email,
      });

      // Close the dialog
      onClose();
      setSuccess(t.profileUpdatedSuccessfully || 'Profile updated successfully.');
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError(t.incorrectPassword || 'The current password is incorrect.');
      } else if (error.code === 'auth/requires-recent-login') {
        // Prompt re-authentication if current password wasn't provided
        if (!currentPassword) {
          setError(t.reauthenticatePrompt || 'Please enter your current password to update your profile.');
        } else {
          setError(error.message || t.errorUpdatingProfile || 'Error updating profile.');
        }
      } else {
        console.error('Error updating profile:', error);
        setError(error.message || t.errorUpdatingProfile || 'Error updating profile.');
      }
    }
  };

  return (
    <>
      <DialogContent>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}
        <TextField
          label={t.fullName || 'Full Name'}
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t.email || 'Email'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t.currentPassword || 'Current Password'}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          type="password"
          fullWidth
          margin="normal"
          helperText={t.enterCurrentPassword || 'Enter your current password to change email or password.'}
        />
        <TextField
          label={t.newPassword || 'New Password'}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          type="password"
          fullWidth
          margin="normal"
          helperText={t.leaveBlankToKeepSame || 'Leave blank to keep the same password.'}
        />
        <TextField
        label={t.confirmNewPassword || 'Confirm New Password'}
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
        type="password"
        fullWidth
        margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t.cancel || 'Cancel'}</Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {t.saveChanges || 'Save Changes'}
        </Button>
      </DialogActions>
    </>
  );
};

export default EditProfile;