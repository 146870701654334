import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { bloodTestParameters } from '../utils/data/bloodTestParameters';
import { resultInterpretations } from '../utils/data/resultInterpretations';
import { useLanguage } from '../components/LanguageContext';

const ResultsTable = ({ testResults, units, standardizedResults, filledTests }) => {
  const { language, translations } = useLanguage();

  const getInterpretation = (test, value) => {
    const interpretations = resultInterpretations[test];
    if (!interpretations) return null;

    for (let interpretation of interpretations) {
      if (value >= interpretation.range[0] && value < interpretation.range[1]) {
        return interpretation.interpretation[language];
      }
    }
    return null;
  };

  const getAdvice = (test, value) => {
    const interpretations = resultInterpretations[test];
    if (!interpretations) return null;

    for (let interpretation of interpretations) {
      if (value >= interpretation.range[0] && value < interpretation.range[1]) {
        return interpretation.advice ? interpretation.advice[language] : null;
      }
    }
    return null;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translations[language].test}</TableCell>
            <TableCell>{translations[language].yourResult}</TableCell>
            <TableCell>{translations[language].standardUnit}</TableCell>
            <TableCell>{translations[language].interpretation}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filledTests.map((test) => {
            const testParam = bloodTestParameters[test];
            const label = testParam ? (typeof testParam.label === 'object' ? testParam.label[language] : testParam.label) : test;
            const value = parseFloat(testResults[test]);
            const interpretation = getInterpretation(test, value);
            const advice = getAdvice(test, value);

            return (
              <TableRow key={test}>
                <TableCell>{label}</TableCell>
                <TableCell>{`${testResults[test]} ${units[test] || ''}`}</TableCell>
                <TableCell>
                  {standardizedResults[test] !== undefined 
                    ? `${standardizedResults[test]?.toFixed(2)} ${testParam ? testParam.units[0] : ''}`
                    : 'N/A'}
                </TableCell>
                <TableCell>
                  <Typography>{interpretation || 'No interpretation available'}</Typography>
                  {advice && (
                    <Typography variant="body2" color="textSecondary">
                      {advice}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ResultsTable;