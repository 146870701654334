import { useLanguage } from '../components/LanguageContext';
import { useCredits } from '../components/CreditContext';

export const useCreditOperations = () => {
  const { checkCredits, updateCredits } = useCredits();
  const { language, translations } = useLanguage();

  const getTranslation = (key, fallback) => {
    return translations[language]?.[key] || fallback;
  };

  const handleCreditOperation = async (cost, operation) => {
    if (!checkCredits(cost)) {
      return { 
        success: false, 
        error: getTranslation('notEnoughCredits', 'Not enough credits for this operation.') 
      };
    }

    try {
      await updateCredits(-cost);
      const result = await operation();
      return { success: true, result };
    } catch (error) {
      console.error('Error in credit operation:', error);
      await updateCredits(cost); // Refund the credits if the operation failed
      return { success: false, error: error.message };
    }
  };

  return { handleCreditOperation };
};