import React, { useEffect, useState, useMemo } from 'react';
import { Container, Typography, Button, Alert } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ResultsTable from './ResultsTable';
import ResultsSummary from './ResultsSummary';
import HealthcareDisclaimer from '../components/HealthcareDisclaimer';
import { interpretResult, generateSummary } from '../utils/data/resultUtils';
import { convertToStandardUnits } from '../utils/data/unitConversion';
import { decryptData } from '../utils/encryption';
import { useLanguage } from '../components/LanguageContext';
import { bloodTestParameters } from '../utils/data/bloodTestParameters';

const Results = () => {
  const { language, translations } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { encryptedResults, encryptedUnits } = location.state || {};

  const testResults = useMemo(() => {
    return encryptedResults ? JSON.parse(decryptData(encryptedResults)) : {};
  }, [encryptedResults]);

  const units = useMemo(() => {
    return encryptedUnits ? JSON.parse(decryptData(encryptedUnits)) : {};
  }, [encryptedUnits]);

  useEffect(() => {
    if (!testResults || !units || Object.keys(testResults).length === 0) {
      setError('Unable to retrieve test results. Please try entering your results again.');
    }
  }, [testResults, units]);

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
        <Button onClick={() => navigate('/input')} style={{ marginTop: '1rem' }}>
          {translations[language].goToInputPage}
        </Button>
      </Container>
    );
  }

  const filledTests = Object.keys(testResults).filter(test => 
    testResults[test] !== undefined && testResults[test] !== '' && testResults[test] !== null
  );

  const standardizedResults = filledTests.reduce((acc, test) => {
    if (bloodTestParameters[test]) {
      acc[test] = convertToStandardUnits(parseFloat(testResults[test]), units[test], test);
    }
    return acc;
  }, {});

  const interpretations = filledTests.reduce((acc, test) => {
    if (bloodTestParameters[test]) {
      const interpretation = interpretResult(test, standardizedResults[test]);
      if (interpretation) {
        acc[test] = interpretation;
      }
    }
    return acc;
  }, {});

  const summary = generateSummary(interpretations, language);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {translations[language].bloodTestResultsInterpretation}
      </Typography>
      <Button onClick={() => navigate('/input')}>
        {translations[language].enterNewResults}
      </Button>
      <Button onClick={() => window.print()}>
        {translations[language].printResults}
      </Button>
      <ResultsTable 
        testResults={testResults} 
        units={units} 
        standardizedResults={standardizedResults} 
        interpretations={interpretations}
        filledTests={filledTests}
      />
      <ResultsSummary summary={summary} />
      <HealthcareDisclaimer />
    </Container>
  );
};

export default Results;