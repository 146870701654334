import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField, Button, Typography, Container, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, IconButton, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress,
  Alert, Checkbox, FormControlLabel, Grid, Select, MenuItem,
  Box, ButtonGroup, Snackbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Edit as EditIcon, 
  Delete as DeleteIcon, 
  GetApp as DownloadIcon, 
} from '@mui/icons-material';
import { collection, setDoc, doc, query, where, orderBy, getDocs, deleteDoc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { initializeFirebase } from '../firebase';
import { bloodTestParameters } from '../utils/data/bloodTestParameters';
import { encryptData, decryptData } from '../utils/encryption';
import { logError, logAccess } from '../utils/qms';
import { useLanguage } from './LanguageContext';
import { generateWordReport, generateReportPreview } from '../utils/reportGenerator';
import EditReportDetails from '../components/report/EditReportDetails';
import { useCredits } from './CreditContext';

const colors = {
  primary: '#1a237e',
  secondary: '#4caf50',
  accent: '#ff9800',
  background: '#f0f4f8',
  text: '#FFFFFF',
  textRows: '#000000'
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: '15px',
  overflow: 'auto',
  maxHeight: '600px',
  backgroundColor: colors.background,
}));

const StyledTableHead = styled(TableHead)(() => ({
  backgroundColor: `${colors.primary} !important`,
  '& th': {
    color: colors.text,
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    fontFamily: 'Arial, sans-serif',
  },
}));


const StyledTableCell = styled(TableCell)(({ component }) => ({
  fontFamily: 'Arial, sans-serif',
  ...(component === 'th' ? {
    color: colors.text, // White text for header cells
    backgroundColor: colors.primary, // Blue background for header cells
  } : {
    color: colors.textRows, // Dark text for body cells
  }),
  '&.MuiTableCell-head': {
    backgroundColor: colors.primary,
    color: colors.text,
  },
}));

const BloodTestHistory = () => {
  const [bloodTests, setBloodTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [testToDelete, setTestToDelete] = useState(null);
  const [editTest, setEditTest] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editValues, setEditValues] = useState({ results: {}, units: {} });
  const [editSearchQuery, setEditSearchQuery] = useState('');
  const [userConsent, setUserConsent] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);
  const [editReportOpen, setEditReportOpen] = useState(false);
  const [reportPreview, setReportPreview] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();
  const { auth, db } = initializeFirebase();
  const { language, translations } = useLanguage();
  const { credits, checkCredits, setCredits } = useCredits();

  const updateCredits = async (amount) => {
    try {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const currentCredits = userDoc.data().credits || 0;
        const newCredits = currentCredits + amount;
  
        // Ensure credits don't go below zero
        if (newCredits < 0) {
          console.error('Not enough credits to complete this operation.');
          throw new Error('Not enough credits');
        }
  
        await updateDoc(userDocRef, { credits: newCredits });
        setCredits(newCredits);
      } else {
        console.error('User document does not exist.');
        throw new Error('User document does not exist');
      }
    } catch (error) {
      console.error('Failed to update credits:', error);
      throw error;
    }
  };
  

  useEffect(() => {
    
    if (!bloodTestParameters || Object.keys(bloodTestParameters).length === 0) {
      console.error('Blood test parameters are undefined or empty');
      logError('BloodTestHistory', 'Blood test parameters are undefined or empty');
      setError('Blood test parameters are not defined. Please check your configuration.');
    } else {
      setIsDataReady(true);
    }
  }, []);

  const sanitizeInput = (input) => {
    if (typeof input === 'string') {
      return input.trim().replace(/[<>&'"]/g, function (c) {
        return {
          '<': '&lt;',
          '>': '&gt;',
          '&': '&amp;',
          "'": '&#39;',
          '"': '&quot;'
        }[c];
      });
    }
    return input;
  };

  const handleEditReport = () => {
    setEditReportOpen(true);
  };

  const handleSaveReportDetails = (details) => {
    setReportDetails(details);
    setEditReportOpen(false);
    updateReportPreview(details);
  };

  const updateReportPreview = useCallback((details) => {
    const previewData = {
      patientDetails: details,
      bloodTests,
      language
    };
    const preview = generateReportPreview(previewData);
    setReportPreview(preview);
  }, [bloodTests, language]);

  const handleDownloadReport = async () => {
    const REPORT_COST = 5;
  
    if (!reportDetails) {
      setError(translations[language].pleaseEditReportFirst);
      return;
    }
    if (!userConsent) {
      setError(translations[language].pleaseProvideConsent);
      return;
    }
    if (!checkCredits(REPORT_COST)) {
      setError(translations[language].notEnoughCredits);
      return;
    }
  
    try {
  
      const reportData = {
        patientDetails: reportDetails,
        bloodTests: bloodTests.map(test => ({
          ...test,
          results: typeof test.results === 'string' ? JSON.parse(test.results) : test.results,
          units: typeof test.units === 'string' ? JSON.parse(test.units) : test.units
        })),
        language
      };
  
      const blob = await generateWordReport(reportData);
      
      if (!blob) {
        throw new Error('Failed to create the report file.');
      }
  
  
      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `blood_test_report_${new Date().toISOString().split('T')[0]}.docx`);
      
      document.body.appendChild(link);
      link.click();  // Trigger the download
      document.body.removeChild(link);  // Clean up
      window.URL.revokeObjectURL(url);  // Revoke the object URL
  
      // Deduct credits after successful download
      try {
        await updateCredits(-REPORT_COST);
      } catch (creditsError) {
        console.error('Error updating credits after report download:', creditsError);
        setError(translations[language].errorUpdatingCredits);
      }
  
      // Show success message
      logAccess('BloodTestHistory', 'Blood Test Report downloaded');
      setSuccess(translations[language].reportDownloadedSuccess);
    } catch (error) {
      console.error('Error downloading report:', error);
      setError(translations[language].errorDownloadingReport);
    }
  };
  
  

  const isReportAvailable = bloodTests.length > 0;

  const formatBloodTestsForAI = () => {
    let formattedTests = "Blood Test Results:\n";
    bloodTests.forEach(test => {
      formattedTests += `Date: ${test.date}\n`;
      Object.entries(test.results).forEach(([key, value]) => {
        const unit = test.units[key] || '';
        formattedTests += `${key}: ${value} ${unit}\n`;
      });
      formattedTests += "\n";
    });
    return formattedTests;
  };
  

  const fetchBloodTests = useCallback(async () => {
    if (!auth.currentUser) {
      setLoading(false);
      setError('Please log in to access blood test history.');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const q = query(
        collection(db, 'bloodTests'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('date', 'desc')
      );
  
      const querySnapshot = await getDocs(q);
      const bloodTestsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        let decryptedResults = {};
        let decryptedUnits = {};
  
        try {
          decryptedResults = JSON.parse(decryptData(data.encryptedResults) || '{}');
          decryptedUnits = JSON.parse(decryptData(data.encryptedUnits) || '{}');
        } catch (error) {
          logError('Error decrypting blood test data', error);
        }
  
        return {
          id: doc.id,
          date: data.date,
          results: decryptedResults,
          units: decryptedUnits
        };
      });
  
      setBloodTests(bloodTestsData);
      logAccess('BloodTestHistory', 'Fetched blood tests');
    } catch (error) {
      logError('Error fetching blood tests', error);
      setError('Failed to fetch blood tests. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [auth, db]);

  useEffect(() => {
    fetchBloodTests();
  }, [fetchBloodTests]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchBloodTests();
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [auth, fetchBloodTests, navigate]);

  const handleEdit = (test) => {
    if (!auth.currentUser) {
      setError(translations[language].pleaseLogIn);
      return;
    }
  
    setEditTest(test);
    setEditValues({
      ...test,
      results: { ...test.results },
      units: { ...test.units }
    });
  
    setEditDialogOpen(true);
    setUserConsent(false);
    logAccess('BloodTestHistory', `Editing test from ${test.date}`);
  };

  const handleDelete = async () => {
    if (!userConsent || !testToDelete) {
      setError(translations[language].pleaseProvideConsent);
      return;
    }
  
    if (!checkCredits(4)) {
      setError(translations[language].notEnoughCredits);
      return;
    }
  
    setLoading(true);
    setError(null);
    try {
      await deleteDoc(doc(db, 'bloodTests', testToDelete.id));
      await updateCredits(-4);
      await fetchBloodTests();
      setDeleteConfirmOpen(false);
      setTestToDelete(null);
      logAccess('BloodTestHistory', `Deleted test from ${testToDelete.date}`);
    } catch (error) {
      console.error('Error deleting blood test:', error);
      setError(`${translations[language].failedToDeleteBloodTest}: ${error.message}`);
      logError('Error deleting blood test', error);
    } finally {
      setLoading(false);
    }
  }

  const handleAddEntry = (param) => {
    setEditValues(prevValues => ({
      ...prevValues,
      results: {
        ...prevValues.results,
        [param]: [
          ...(Array.isArray(prevValues.results[param]) ? prevValues.results[param] : []),
          { value: '', unit: bloodTestParameters[param].units[0] }
        ]
      }
    }));
  };

  const handleRemoveEntry = (param, index) => {
    setEditValues(prevValues => ({
      ...prevValues,
      results: {
        ...prevValues.results,
        [param]: Array.isArray(prevValues.results[param]) 
          ? prevValues.results[param].filter((_, i) => i !== index)
          : []
      }
    }));
  };

  const handleEditSave = async () => {
    if (!auth.currentUser || !userConsent || !checkCredits(4)) {
      setError(translations[language].pleaseProvideConsent);
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      // Combine existing results with any custom results
      const formattedResults = {
        ...editValues.results,
        ...editValues.customResults
      };
      const formattedUnits = { ...editValues.units };
  
      // Sanitize inputs and remove entries with empty or undefined values
      for (const key in formattedResults) {
        const sanitizedKey = sanitizeInput(key);
        const value = formattedResults[key];
  
        // If the value is empty or undefined, remove it
        if (value === '' || value === undefined || value === null) {
          delete formattedResults[sanitizedKey];
          delete formattedUnits[sanitizedKey];
        } else {
          // Sanitize the value
          formattedResults[sanitizedKey] = sanitizeInput(value);
        }
      }
  
      // Ensure units correspond to existing results
      for (const key in formattedUnits) {
        const sanitizedKey = sanitizeInput(key);
  
        if (!formattedResults.hasOwnProperty(sanitizedKey)) {
          delete formattedUnits[sanitizedKey];
        } else {
          formattedUnits[sanitizedKey] = sanitizeInput(formattedUnits[key]);
        }
      }
  
      // Encrypt the sanitized and cleaned results and units
      const encryptedResults = encryptData(JSON.stringify(formattedResults));
      const encryptedUnits = encryptData(JSON.stringify(formattedUnits));
  
      const updatedTest = {
        ...editValues,
        results: formattedResults,
        units: formattedUnits,
        encryptedResults,
        encryptedUnits,
        updatedAt: new Date(),
        userId: auth.currentUser.uid
      };
  
      // Validate the updated blood test data
      if (!validateBloodTest(updatedTest)) {
        throw new Error(translations[language].invalidBloodTestData || "Invalid blood test data.");
      }
  
      // Save the updated test to the database
      await setDoc(doc(db, 'bloodTests', editTest.id), updatedTest, { merge: true });
  
      // Deduct credits for the operation
      await updateCredits(-4);
  
      // Refresh the blood tests list
      await fetchBloodTests();
  
      // Close the edit dialog and reset the state
      setEditDialogOpen(false);
      setEditTest(null);
      logAccess('BloodTestHistory', `Updated test from ${editValues.date}`);
    } catch (error) {
      const errorMessage = error.message || translations[language].unknownError || "An unknown error occurred.";
      console.error('Error occurred while saving edited blood test:', error);
      setError(`${translations[language].failedToSaveBloodTest}: ${errorMessage}`);
      logError('Error saving blood test', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  

  const validateBloodTest = (test) => {
    // Check if required fields are present
    if (!test.date || !test.results || !test.units) {
      return false;
    }
  
    // Check if results and units are objects
    if (typeof test.results !== 'object' || typeof test.units !== 'object') {
      return false;
    }
  
    // Check if all values in results are numbers or valid strings
    for (const key in test.results) {
      const value = test.results[key];
      if (typeof value !== 'number' && (typeof value !== 'string' || isNaN(parseFloat(value)))) {
        return false;
      }
    }
  
    // Check if all units are strings
    for (const key in test.units) {
      if (typeof test.units[key] !== 'string') {
        return false;
      }
    }
  
    return true;
  };

  const getFilledTests = useCallback((test) => {
    return Object.keys(test.results).filter(key => 
      test.results[key] !== undefined && 
      test.results[key] !== null && 
      test.results[key] !== ''
    );
  }, []);

  const getAllFilledTestKeys = useCallback(() => {
    const allKeys = new Set();
    bloodTests.forEach(test => {
      getFilledTests(test).forEach(key => allKeys.add(key));
    });
    return Array.from(allKeys);
  }, [bloodTests, getFilledTests]);

  const formatValue = (value) => {
    if (value === undefined || value === null || value === '') return '-';
    const numValue = parseFloat(value);
    return isNaN(numValue) ? value : numValue.toFixed(2);
  };

  const renderTableCell = (test, param) => {
    if (!bloodTestParameters || !bloodTestParameters[param]) {
        return <StyledTableCell key={param}>-</StyledTableCell>;
    }

    const result = test.results[param];
    let value, unit;

    if (typeof result === 'object' && result !== null) {
        // If result is an object, extract value and unit
        value = result.value;
        unit = result.unit;
    } else {
        // If result is a simple value, use it directly
        value = result;
        unit = test.units[param];
    }

    return (
        <StyledTableCell key={param}>
            {value !== undefined && value !== '' ? `${parseFloat(value).toFixed(2)} ${unit || ''}` : '-'}
        </StyledTableCell>
    );
};
  
  if (!isDataReady || !bloodTestParameters) {
    return <Typography>No data available</Typography>;
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom sx={{ color: colors.primary, fontWeight: 'bold', mt: 4 }}>
        {translations[language].bloodTestHistory}
      </Typography>
  
      <Box sx={{ mb: 2 }}>
        <ButtonGroup variant="contained" disabled={bloodTests.length === 0}>
        <Button
          onClick={() => navigate('/test-input')}
          sx={{
            bgcolor: colors.primary,
            color: colors.text,
            '&:hover': { bgcolor: colors.accent },
          }}
        >
          {translations[language].addBloodTest}
        </Button>
          <Button
            onClick={() => setEditReportOpen(true)}
            startIcon={<EditIcon />}
          >
            {translations[language].editReport}
          </Button>
          <Button
            onClick={handleDownloadReport}
            startIcon={<DownloadIcon />}
            disabled={!reportDetails || !userConsent || !checkCredits(5)}
          >
            {translations[language].downloadReport}
          </Button>
        </ButtonGroup>
      </Box>
  
      {reportPreview && (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            {translations[language].reportPreview}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: reportPreview }} />
        </Paper>
      )}
  
      <FormControlLabel
        control={
          <Checkbox
            checked={userConsent}
            onChange={(e) => setUserConsent(e.target.checked)}
            name="userConsent"
          />
        }
        label={translations[language].consentToDownload}
      />
  
      {bloodTests.length === 0 ? (
        <Typography variant="h6">
          {translations[language].noBloodTestsFound}
        </Typography>
      ) : (
        <StyledTableContainer component={Paper}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>Blood Test Types</StyledTableCell>
                <StyledTableCell>Values</StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {bloodTests.map((test) => (
                <React.Fragment key={test.id}>
                  <TableRow>
                    <StyledTableCell colSpan={2} style={{ fontWeight: 'bold', backgroundColor: colors.secondary }}>
                      {test.date}
                    </StyledTableCell>
                  </TableRow>
                  {Object.entries(test.results).map(([key, value]) => {
                    if (value !== undefined && value !== '') {
                      return (
                        <TableRow key={key}>
                          <StyledTableCell>{`${bloodTestParameters[key]?.label[language] || key} (${test.units[key] || ''})`}</StyledTableCell>
                          <StyledTableCell>
                            {typeof value === 'object' && value !== null
                              ? `${formatValue(value.value)} ${value.unit || ''}`
                              : `${formatValue(value)} ${test.units[key] || ''}`}
                          </StyledTableCell>
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                  <TableRow>
                    <StyledTableCell colSpan={2} align="right">
                      <IconButton onClick={() => handleEdit(test)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => {
                        setTestToDelete(test);
                        setDeleteConfirmOpen(true);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
  
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>{translations[language].confirmDeletion}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translations[language].deleteBloodTestConfirmation}
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={userConsent}
                onChange={(e) => setUserConsent(e.target.checked)}
                name="deleteConsent"
              />
            }
            label={translations[language].deleteBloodTestConsent}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>{translations[language].cancel}</Button>
          <Button onClick={handleDelete} color="secondary" autoFocus disabled={!userConsent}>
            {translations[language].delete}
          </Button>
        </DialogActions>
      </Dialog>
  
      {/* Edit Dialog */}
     
      {/* Edit Dialog */}
<Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="md" fullWidth>
  <DialogTitle>{translations[language].editBloodTest}</DialogTitle>
  <DialogContent>
    <DialogContentText>
      {translations[language].editBloodTestInstructions}
    </DialogContentText>
    <TextField
      label={translations[language].searchTests}
      value={editSearchQuery}
      onChange={(e) => setEditSearchQuery(e.target.value)}
      fullWidth
      margin="normal"
    />
    {[...Object.keys(bloodTestParameters), ...Object.keys(editValues.results || {})]
      .filter((key, index, self) => self.indexOf(key) === index) // Remove duplicates
      .filter(key => {
        const label = (bloodTestParameters[key]?.label[language] || key).toLowerCase();
        return label.includes(editSearchQuery.toLowerCase());
      })
      .map(key => {
        const isCustomTest = !bloodTestParameters[key];
        const value = editValues.results[key] || '';
        return (
          <Grid container spacing={2} key={key} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Typography>{isCustomTest ? key : bloodTestParameters[key].label[language]}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                value={value}
                onChange={(e) => setEditValues({
                  ...editValues,
                  results: { ...editValues.results, [key]: e.target.value }
                })}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {isCustomTest ? (
                <TextField
                  value={editValues.units[key] || ''}
                  onChange={(e) => setEditValues({
                    ...editValues,
                    units: { ...editValues.units, [key]: e.target.value }
                  })}
                  fullWidth
                  placeholder={translations[language].unit}
                />
              ) : (
                <Select
                  value={editValues.units[key] || ''}
                  onChange={(e) => setEditValues({
                    ...editValues,
                    units: { ...editValues.units, [key]: e.target.value }
                  })}
                  fullWidth
                >
                  {(bloodTestParameters[key]?.units || []).map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
          </Grid>
        );
      })}
    <FormControlLabel
      control={
        <Checkbox
          checked={userConsent}
          onChange={(e) => setUserConsent(e.target.checked)}
          name="editConsent"
        />
      }
      label={translations[language].updateBloodTestConsent}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setEditDialogOpen(false)}>{translations[language].cancel}</Button>
    <Button onClick={handleEditSave} color="primary" disabled={!userConsent}>
      {translations[language].save}
    </Button>
  </DialogActions>
</Dialog>
  
      {/* Edit Report Dialog */}
      <Dialog open={editReportOpen} onClose={() => setEditReportOpen(false)}>
        <DialogTitle>{translations[language].editReportDetails}</DialogTitle>
        <DialogContent>
          <EditReportDetails onSave={(details) => {
            setReportDetails(details);
            setEditReportOpen(false);
            // Update report preview here
          }} initialData={reportDetails} />
        </DialogContent>
      </Dialog>
  
      <Snackbar open={!!error || !!success} autoHideDuration={6000} onClose={() => { setError(null); setSuccess(null); }}>
        <Alert onClose={() => { setError(null); setSuccess(null); }} severity={error ? "error" : "success"}>
          {error || success}
        </Alert>
      </Snackbar>
    </Container>
  );
  
  
};

export default BloodTestHistory;