import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
  Tooltip,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
  Avatar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Link, useNavigate } from 'react-router-dom';
import { signOut, updateProfile, updateEmail, deleteUser, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { doc, updateDoc, deleteDoc, collection, getDocs, query, where, getDoc, getFirestore as db, writeBatch  } from 'firebase/firestore';
import { initializeFirebase } from '../firebase';
import { useLanguage } from './LanguageContext';
import { useCredits } from './CreditContext';
import logo from '../assets/logo.jpeg';
import { fetchUserData, deleteUserAccount } from '../utils/userUtils';
import UserMenu from './UserMenu';
import { decryptData } from '../utils/encryption';


const Header = ({ user, onUserUpdate }) => {
  const navigate = useNavigate();
  const { auth, db } = initializeFirebase();
  const { language, toggleLanguage, translations } = useLanguage();
  const { credits, isAdmin, userTier, loading } = useCredits();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [fullName, setFullName] = useState(user?.displayName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const t = translations[language];
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

  useEffect(() => {
    if (user) {
      setFullName(user.displayName || '');
      setEmail(user.email || '');
    }
  }, [user]);

  const renderProfileInfo = () => {
    if (!user) return null;
    if (user.googleSignIn) {
        return (
            <Typography variant="body2" sx={{ color: 'yellow', fontWeight: 'bold' }}>
                {user.displayName} (Google Account)
            </Typography>
        );
    }
    return (
        <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold' }}>
            {user.displayName}
        </Typography>
    );
};


  const handleUserMenuOpen = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleEditProfile = () => {
    try {
      // Set the initial values for the form fields
      setFullName(user?.displayName || '');
      setEmail(user?.email || '');
      setPassword(''); // Reset password field
  
      // Open the settings dialog
      setSettingsDialogOpen(true);
  
      // Close the user menu if it's open
      if (userMenuAnchorEl) {
        setUserMenuAnchorEl(null);
      }
  
      // Log the action (optional)
      console.log('Edit profile dialog opened');
    } catch (error) {
      console.error('Error opening edit profile dialog:', error);
      // Show an error message to the user
      setSnackbar({
        open: true,
        message: t.errorOpeningEditProfile,
        severity: 'error'
      });
    }
  };


  const handleLanguageToggle = () => {
    toggleLanguage();
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      onUserUpdate(null);  // Use onUserUpdate directly
      navigate('/login');
    } catch (error) {
      console.error('Error signing out: ', error);
      setSnackbar({ open: true, message: t.logoutError, severity: 'error' });
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSettingsClick = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsAnchorEl(null);
  };

  const handleOpenSettingsDialog = () => {
    setSettingsDialogOpen(true);
    handleSettingsClose();
  };

  const handleCloseSettingsDialog = () => {
    setSettingsDialogOpen(false);
    setPassword('');
  };

  const handleSaveSettings = async () => {
    try {
      if (!auth.currentUser) {
        throw new Error(t.userNotAuthenticated);
      }

      // Re-authenticate user
      const credential = EmailAuthProvider.credential(auth.currentUser.email, password);
      await reauthenticateWithCredential(auth.currentUser, credential);

      // Update display name if changed
      if (fullName !== auth.currentUser.displayName) {
        await updateProfile(auth.currentUser, { displayName: fullName });
      }

      // Update email if changed
      if (email !== auth.currentUser.email) {
        await updateEmail(auth.currentUser, email);
      }

      // Update Firestore document
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDoc, {
        displayName: fullName,
        email: email,
      });

      onUserUpdate({ ...auth.currentUser, displayName: fullName, email: email });  // Use onUserUpdate directly
      handleCloseSettingsDialog();
      setSnackbar({ open: true, message: t.settingsUpdated, severity: 'success' });
    } catch (error) {
      console.error('Error updating settings:', error);
      setSnackbar({ open: true, message: t.settingsUpdateError, severity: 'error' });
    }
  };

  const handleDownloadData = async () => {
    try {
      if (!user) throw new Error('User not authenticated');
  
      // Fetch user profile data
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
  
      // Fetch blood tests
      const bloodTestsQuery = query(collection(db, 'bloodTests'), where('userId', '==', user.uid));
      const bloodTestsSnapshot = await getDocs(bloodTestsQuery);
      const bloodTests = bloodTestsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          date: data.date,
          results: JSON.parse(decryptData(data.encryptedResults)),
          units: JSON.parse(decryptData(data.encryptedUnits))
        };
      });
  
      // Fetch patient history
      const patientHistoryQuery = query(collection(db, 'patientHistory'), where('userId', '==', user.uid));
      const patientHistorySnapshot = await getDocs(patientHistoryQuery);
      const patientHistory = patientHistorySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          date: data.date,
          newAllergies: decryptData(data.encryptedNewAllergies),
          newIntolerances: decryptData(data.encryptedNewIntolerances),
          historyOfPresentIllness: decryptData(data.encryptedHistoryOfPresentIllness),
          visitOutcomeNote: decryptData(data.encryptedVisitOutcomeNote),
          assessmentAndPlan: decryptData(data.encryptedAssessmentAndPlan),
          followUp: decryptData(data.encryptedFollowUp)
        };
      });
  
      // Combine all data
      const allData = {
        profile: {
          email: user.email,
          displayName: user.displayName,
          ...userData
        },
        bloodTests: bloodTests,
        patientHistory: patientHistory,
      };
  
      // Create and download the JSON file
      const dataStr = JSON.stringify(allData, null, 2);
      const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
      const exportFileDefaultName = `user_data_${user.uid}_${new Date().toISOString()}.json`;
  
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();
  
      setSnackbar({ 
        open: true, 
        message: t.dataDownloadSuccess || 'Data downloaded successfully', 
        severity: 'success' 
      });
    } catch (error) {
      console.error('Error downloading data:', error);
      setSnackbar({ 
        open: true, 
        message: t.dataDownloadError || 'Error downloading data', 
        severity: 'error' 
      });
    }
  };

  const handleDeleteAccount = async () => {
    const confirmMessage = t.deleteAccountConfirmation || 'Are you sure you want to delete your account? This action cannot be undone.';
  
    if (window.confirm(confirmMessage)) {
      const emailConfirmation = window.prompt(t.typeEmailToConfirm || 'Please type your email address to confirm account deletion:');
  
      if (emailConfirmation === user.email) {
        try {
          if (!user) throw new Error('User not authenticated');
  
          // For Google accounts, skip the re-authentication step
          if (!user.googleSignIn) {
            // Re-authenticate the user before deletion (for non-Google accounts)
            const credential = EmailAuthProvider.credential(user.email, prompt('Please enter your password to confirm:'));
            await reauthenticateWithCredential(user, credential);
          }
  
          // Create a batch instance from Firestore
          const batch = writeBatch(db);
  
          // Delete user profile from Firestore
          const userRef = doc(db, 'users', user.uid);
          batch.delete(userRef);
  
          // Delete blood tests
          const bloodTestsQuery = query(collection(db, 'bloodTests'), where('userId', '==', user.uid));
          const bloodTestsSnapshot = await getDocs(bloodTestsQuery);
          bloodTestsSnapshot.docs.forEach(doc => {
            batch.delete(doc.ref);
          });
  
          // Delete patient history
          const patientHistoryQuery = query(collection(db, 'patientHistory'), where('userId', '==', user.uid));
          const patientHistorySnapshot = await getDocs(patientHistoryQuery);
          patientHistorySnapshot.docs.forEach(doc => {
            batch.delete(doc.ref);
          });
  
          // Commit the batch to delete data
          await batch.commit();
  
          // Delete the Firebase Authentication user account
          await deleteUser(user);
  
          // Sign out the user
          await signOut(auth);
          onUserUpdate(null);
  
          // Notify the user of successful deletion
          setSnackbar({
            open: true,
            message: t.accountDeletedSuccess || 'Account deleted successfully',
            severity: 'success'
          });
  
          // Redirect to registration page
          navigate('/register', { state: { message: t.accountDeletedRedirect || 'Your account has been deleted. You can register a new account if you wish.' } });
  
        } catch (error) {
          console.error('Error deleting account:', error);
  
          let errorMessage = t.accountDeleteError || 'Error deleting account';
  
          if (error.code === 'auth/requires-recent-login') {
            errorMessage = t.recentLoginRequired || 'Please log in again before deleting your account';
            navigate('/login', { state: { message: errorMessage } });
          } else {
            setSnackbar({
              open: true,
              message: errorMessage,
              severity: 'error'
            });
          }
        }
      } else {
        setSnackbar({
          open: true,
          message: t.emailConfirmationFailed || 'Email confirmation failed. Account not deleted.',
          severity: 'error'
        });
      }
    }
  };
  

  
  const colors = {
    primary: '#1a237e',
    secondary: '#4caf50',
    accent: '#ff9800',
    text: '#FFFFFF',
  };

  const buttonStyle = {
    color: colors.text,
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: '14px',
    textTransform: 'none',
    padding: '6px 8px',
    minWidth: 0,
    flexShrink: 0,
    '&:hover': {
      backgroundColor: colors.accent,
      color: colors.primary,
    },
  };

  const renderCreditsInfo = () => {
    if (!user) return null; // Don't show if the user is not logged in
    if (loading) return 'Loading...';
    if (isAdmin) return `Admin (${t.unlimitedCredits})`;
    return `${t.tier}: ${userTier} | ${t.credits}: ${typeof credits === 'number' ? credits.toFixed(2) : credits}`;
  };

  const menuItems = [
    { label: t.home, path: '/' },
    ...(user
      ? [
          { label: t.userDashboard, path: '/patient-data-overview' },
          { label: t.bloodTestInput, path: '/test-input' },
          { label: t.patientHistory, path: '/patient-history' },
          ...(!isAdmin ? [{ label: t.buyCredits, path: '/purchase-credits' }] : []),
          { label: t.logout, action: handleLogout },
        ]
      : [
          { label: t.login, path: '/login' },
          { label: t.register, path: '/register' },
        ]
    ),
  ];

  return (
    <AppBar position="static" sx={{ bgcolor: colors.primary }}>
      <Toolbar sx={{ height: 100, justifyContent: 'space-between', px: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: 80, marginRight: 16 }} />
          {user && (
            <Typography variant="body2" sx={{ 
              color: colors.text, 
              fontWeight: 'bold', 
              display: { xs: 'none', sm: 'block' },
              maxWidth: 300,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
              {renderCreditsInfo()}
            </Typography>
          )}
        </Box>
  
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
              sx={{ ml: 'auto' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              TransitionComponent={Fade}
            >
              {menuItems.map((item, index) => (
                <MenuItem 
                  key={index} 
                  onClick={() => {
                    handleMenuClose();
                    if (item.action) item.action();
                    else navigate(item.path);
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
              <MenuItem onClick={() => { toggleLanguage(); handleMenuClose(); }}>
                {language === 'en' ? 'IT' : 'EN'}
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1, 
            height: '100%',
            maxWidth: 'calc(100% - 300px)',
            overflow: 'hidden'
          }}>
            {menuItems.map((item, index) => (
              <Tooltip key={index} title={item.label} arrow>
                <Button
                  component={item.path ? Link : 'button'}
                  to={item.path}
                  onClick={item.action}
                  sx={buttonStyle}
                >
                  {item.label}
                </Button>
              </Tooltip>
            ))}
            <Tooltip title={language === 'en' ? 'Switch to Italian' : 'Switch to English'} arrow>
              <Button onClick={handleLanguageToggle} sx={buttonStyle}>
                {language === 'en' ? 'IT' : 'EN'}
              </Button>
            </Tooltip>
          </Box>
        )}
  
      {user && (
            <UserMenu
              user={user}
              onEditProfile={handleEditProfile}
              onDownloadData={handleDownloadData}
              onDeleteAccount={handleDeleteAccount}
            />
        )}
      </Toolbar>
  
      <Dialog open={settingsDialogOpen} onClose={handleCloseSettingsDialog}>
        <DialogTitle>{t.editProfile}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t.fullName}
            type="text"
            fullWidth
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <TextField
            margin="dense"
            label={t.email}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label={t.password}
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText={t.passwordRequiredForChanges}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettingsDialog}>{t.cancel}</Button>
          <Button onClick={handleSaveSettings}>{t.save}</Button>
        </DialogActions>
      </Dialog>
  
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </AppBar>
  );
};

export default Header;
