import React, { useState, useEffect } from 'react';
import {
  Typography, Box, Grid, Button, CircularProgress, Card, CardContent, CardActions,
  Container, Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLanguage } from './LanguageContext';
import { useCredits } from './CreditContext';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import InfoIcon from '@mui/icons-material/Info';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmailIcon from '@mui/icons-material/Email';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const colors = {
  primary: '#1a237e',
  secondary: '#4caf50',
  accent: '#ff9800',
  text: '#FFFFFF',
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[8],
  },
  backgroundColor: colors.primary,
  color: colors.text,
}));

const StyledButton = styled(Button)({
  color: colors.text,
  backgroundColor: colors.accent,
  fontWeight: 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: colors.secondary,
  },
});

const PriceTag = styled(Typography)(({ theme }) => ({
  backgroundColor: colors.secondary,
  color: colors.text,
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  fontWeight: 'bold',
}));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ creditPackages }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processingPackage, setProcessingPackage] = useState(null);
  const [error, setError] = useState(null);
  const { language, translations } = useLanguage();
  const { updateCredits } = useCredits();
  const t = translations[language];
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const createStripeCustomer = async (userId) => {
    const userRef = doc(db, 'customers', userId);
    const userDoc = await getDoc(userRef);

    let stripeCustomerId;
    if (userDoc.exists() && userDoc.data().stripeCustomerId) {
      stripeCustomerId = userDoc.data().stripeCustomerId;
    } else {
      try {
        const response = await fetch(`${API_URL}/api/purchase-credits`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            credits,
            price,
            tier,
          }),
        });
      
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.error) {
          throw new Error(data.error);
        }
        stripeCustomerId = data.stripeCustomerId;
        await setDoc(userRef, { stripeCustomerId }, { merge: true });
      } catch (error) {
        console.error('Detailed error:', error);
        setError(`${t.paymentProcessingError}: ${error.message}`);
        setProcessingPackage(null);
      }
    }
    return stripeCustomerId;
  };

  const handleSubmit = async (event, credits, price, tier) => {
    event.preventDefault();
    setProcessingPackage(credits);
    setError(null);

    if (tier === 'Tier 3') {
      window.location.href = 'mailto:info@medwebreport.com';
      return;
    }

    if (!stripe || !elements) {
      setError(t.stripeNotLoaded);
      setProcessingPackage(null);
      return;
    }

    if (!auth.currentUser) {
      console.error('User is not authenticated');
      setError(t.userNotAuthenticated);
      setProcessingPackage(null);
      return;
    }

    try {
      const userId = auth.currentUser.uid;
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchase-credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          credits,
          price,
          tier,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.error) {
        throw new Error(data.error);
      }

      const { sessionId } = data;
      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Detailed error:', error);
      setError(`${t.paymentProcessingError}: ${error.message}`);
      setProcessingPackage(null);
    }
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={3}>
        {creditPackages.map((pack, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="div">
                  {pack.credits} {t.credits}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  {t.tier}: {pack.tier}
                </Typography>
                <Typography variant="h4" component="div">
                  {pack.price === 'Contact Us' ? t.contactUs : `€${pack.price}`}
                </Typography>
              </CardContent>
              <CardActions>
                <StyledButton
                  fullWidth
                  onClick={(e) => handleSubmit(e, pack.credits, pack.price, pack.tier)}
                  disabled={!stripe || processingPackage === pack.credits}
                >
                  {processingPackage === pack.credits ? (
                    <CircularProgress size={24} sx={{ color: colors.text }} />
                  ) : pack.tier === 'Tier 3' ? (
                    t.contactUs
                  ) : (
                    t.buy
                  )}
                </StyledButton>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
    </Box>
  );
};

const PurchaseCredits = () => {
  const { language, translations } = useLanguage();
  const t = translations[language];
  const creditPackages = [
    { credits: 300, price: 14.99, tier: 'Tier 1' },
    { credits: 600, price: 24.99, tier: 'Tier 2' },
    { credits: 'Custom', price: 'Contact Us', tier: 'Tier 3' },
  ];

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ color: colors.primary }}>
        {t.purchaseCredits}
      </Typography>
      <Elements stripe={stripePromise}>
        <CheckoutForm creditPackages={creditPackages} />
      </Elements>
    </Container>
  );
};

export default PurchaseCredits;
