import React from 'react';
import { Typography, Box } from '@mui/material';
import { useLanguage } from './LanguageContext';

const PaymentCancelled = () => {
  const { language, translations } = useLanguage();
  const t = translations[language];

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">{t.paymentCancelled}</Typography>
      <Typography>{t.tryAgainLater}</Typography>
    </Box>
  );
};

export default PaymentCancelled;