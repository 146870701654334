import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Container, Button, Grid, Alert, Paper, FormControl, InputLabel,
  Select, MenuItem, TextField, Box, Checkbox, FormControlLabel, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog,
  DialogTitle, DialogContent, DialogActions, IconButton, CircularProgress,
  Snackbar, Button as StyledButton
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { collection, addDoc, setDoc, doc, query, where, orderBy, getDocs, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { initializeFirebase } from '../firebase';
import { encryptData, decryptData } from '../utils/encryption';
import { logError, reportIssue } from '../utils/qms';
import { useLanguage } from '../components/LanguageContext';
import { performOCR, analyzeTestInputOCR } from '../utils/ocrUtils';
import ImageUpload from '../components/ImageUpload';
import { useCredits } from '../components/CreditContext';

const PatientHistory = () => {
  const [date, setDate] = useState('');
  const [information, setInformation] = useState('');
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);
  const { language, translations } = useLanguage();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingEntry, setEditingEntry] = useState(null);
  const [editValues, setEditValues] = useState({
    date: '',                 
    weight: '',                
    height: '',               
    bmi: '',                   
    bloodPressure: '',         
    pulse: '',                 
    newAllergies: '',          
    newIntolerances: '',      
    chiefComplaint: '',       
    historyOfPresentIllness: '', 
    assessmentAndPlan: '',     
    followUp: '',              
    examNotes: ''              
  });
  
  const [userConsent, setUserConsent] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const { auth, db, storage } = initializeFirebase();

  const [image, setImage] = useState(null);
  const [ocrProgress, setOcrProgress] = useState('');
  const { userTier } = useCredits();

  const handleOCR = async () => {
    if (!image) return;
    setOcrProgress('Processing...');
    try {
      const text = await performOCR(image);
      const analyzedData = await analyzeTestInputOCR(text);
      setEditValues(prevValues => ({
        ...prevValues,
        ...analyzedData
      }));
      setOcrProgress('');
    } catch (error) {
      console.error('OCR Error:', error);
      setError('Failed to process image. Please try again.');
      setOcrProgress('');
    }
  };

  const fetchHistory = useCallback(async () => {
    if (!auth.currentUser) {
      setLoading(false);
      setError('Please log in to access patient history.');
      return;
    }
  
    setLoading(true);
    setError(null);
    try {
      const q = query(
        collection(db, 'patientHistory'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('date', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const historyData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        let decryptedInformation;
        try {
          decryptedInformation = JSON.parse(decryptData(data.encryptedInformation));
        } catch (error) {
          console.error('Error decrypting/parsing patient history:', error);
          decryptedInformation = { blocks: [{ text: 'Error decrypting data' }] };
        }
        return {
          id: doc.id,
          date: data.date,
          information: decryptedInformation
        };
      });
      setHistory(historyData);
    } catch (error) {
      console.error('Error fetching history:', error);
      logError('Error fetching patient history', error);
      if (error.code === 'failed-precondition' && error.message.includes('requires an index')) {
        setError('Database index is being created. Please try again in a few minutes.');
      } else {
        setError(`Failed to fetch history: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  }, [auth, db]);
  

  const handleEditSave = async () => {
    if (!userConsent) {
      setError(translations[language].pleaseConsent);
      return;
    }
  
    try {
      const encryptedInformation = encryptData(JSON.stringify(editValues.information));
      await setDoc(doc(db, 'patientHistory', editingEntry.id), {
        date: editValues.date,
        encryptedInformation,
        updatedAt: new Date()
      }, { merge: true });
  
      setEditDialogOpen(false);
      setEditingEntry(null);
      setUserConsent(false);
      await fetchHistory();
      setSuccess(translations[language].updateSuccess);
    } catch (error) {
      console.error('Error updating patient history:', error);
      setError(translations[language].updateError);
    }
  };
  
  

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchHistory();
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [auth, fetchHistory, navigate]);

  const formatPatientHistoryForAI = () => {
    let formattedHistory = "Patient History:\n";
    history.forEach(entry => {
      formattedHistory += `Date: ${entry.date}\n`;
      entry.information.blocks?.forEach(block => {
        formattedHistory += `Information: ${block.text}\n`;
      });
      formattedHistory += "\n";
    });
    return formattedHistory;
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!date || !information) {
      setError('Please fill in both date and information fields.');
      return;
    }
    if (!userConsent) {
      setError('Please provide consent to store and process your data.');
      return;
    }
    if (!auth.currentUser) {
      setError('User not authenticated. Please log in.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const encryptedInformation = encryptData(information);
      if (editingEntry) {
        await setDoc(doc(db, 'patientHistory', editingEntry.id), {
          date,
          encryptedInformation,
          updatedAt: new Date()
        }, { merge: true });
        setEditingEntry(null);
      } else {
        await addDoc(collection(db, 'patientHistory'), {
          userId: auth.currentUser.uid,
          date,
          encryptedInformation,
          createdAt: new Date()
        });
      }
      setDate('');
      setInformation('');
      await fetchHistory();
    } catch (error) {
      console.error('Error adding/updating history:', error);
      logError('Error adding/updating patient history', error);
      if (error.code === 'permission-denied') {
        setError('Permission denied. Please ensure you are logged in and have the necessary permissions.');
      } else {
        setError(`Failed to add/update history: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setEditValues({
      date: entry.date,
      information: entry.information
    });
    setEditDialogOpen(true);
    setUserConsent(false);
  };
  

  const handleDelete = async () => {
    if (!entryToDelete) return;
    setLoading(true);
    setError(null);
    try {
      await deleteDoc(doc(db, 'patientHistory', entryToDelete.id));
      await fetchHistory();
      setDeleteConfirmOpen(false);
      setEntryToDelete(null);
    } catch (error) {
      console.error('Error deleting history entry:', error);
      logError('Error deleting patient history entry', error);
      setError(`Failed to delete history entry: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (!auth.currentUser) {
    return (
      <Container>
        <Typography variant="h6">Please log in to access patient history.</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {editingEntry ? translations[language].editPatientHistory : translations[language].addPatientHistory}
      </Typography>
  
      {/* OCR Section */}
      <Box sx={{ mb: 4 }}>
        {(userTier === 'Tier 1' || userTier === 'Tier 2') && (
          <ImageUpload setImage={setImage} setError={setError} />
        )}
        
        {image && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOCR}
            disabled={ocrProgress !== ''}
            sx={{ mt: 2, mb: 2 }}
          >
            {ocrProgress ? <CircularProgress size={24} /> : translations[language].performOcr}
          </Button>
        )}
  
        {ocrProgress && <Typography sx={{ mt: 2 }}>{ocrProgress}</Typography>}
      </Box>
  
      <form onSubmit={handleSubmit}>
        <TextField
          label={translations[language].testDate}
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          required
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: '1rem' }}
        />
        <TextField
          label={translations[language].information}
          multiline
          rows={4}
          value={information}
          onChange={(e) => setInformation(e.target.value)}
          fullWidth
          required
          sx={{ marginBottom: '1rem' }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={userConsent}
              onChange={(e) => setUserConsent(e.target.checked)}
              name="userConsent"
            />
          }
          label={translations[language].gdprConsent}
        />
        <Button type="submit" variant="contained" color="primary" disabled={loading || !userConsent}>
          {loading ? translations[language].saving : (editingEntry ? translations[language].update : translations[language].addToHistory)}
        </Button>
        {editingEntry && (
          <Button
            onClick={() => {
              setEditingEntry(null);
              setDate('');
              setInformation('');
            }} variant="outlined" sx={{ marginLeft: '1rem' }}>
            {translations[language].cancelEdit}
          </Button>
        )}
      </form>
  
      <Typography variant="h5" sx={{ marginTop: '2rem', marginBottom: '1rem' }}>
        {translations[language].historyEntries}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : history.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{translations[language].testDate}</TableCell>
                <TableCell>{translations[language].information}</TableCell>
                <TableCell>{translations[language].actions}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell>{entry.date}</TableCell>
                  <TableCell>
                    {entry.information.blocks?.map((block, index) => (
                      <p key={index}>{block.text}</p>
                    ))}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(entry)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                      setEntryToDelete(entry);
                      setDeleteConfirmOpen(true);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>{translations[language].noHistoryFound}</Typography>
      )}
  
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
  
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>{translations[language].editPatientHistory}</DialogTitle>
        <DialogContent>
            <TextField
                label={translations[language].date}
                type="date"
                value={editValues.date || ''}
                onChange={(e) => setEditValues({...editValues, date: e.target.value})}
                fullWidth
                margin="normal"
            />
            <TextField
                label={translations[language].information}
                multiline
                rows={4}
                value={editValues.information?.blocks?.[0]?.text || ''}
                onChange={(e) => setEditValues({
                    ...editValues,
                    information: {
                        blocks: [{ text: e.target.value }]
                    }
                })}
                fullWidth
                margin="normal"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={userConsent}
                        onChange={(e) => setUserConsent(e.target.checked)}
                        name="userConsent"
                    />
                }
                label={translations[language].updateConsentLabel}
            />
            <ImageUpload setImage={setImage} setError={setError} />
            {image && (
                <StyledButton
                    onClick={handleOCR}
                    variant="contained"
                    sx={{
                        bgcolor: '#4caf50',
                        color: 'white',
                        '&:hover': { bgcolor: '#45a049' },
                        mt: 2
                    }}
                    disabled={ocrProgress !== ''}
                >
                    {ocrProgress ? <CircularProgress size={24} /> : translations[language].performOcr}
                </StyledButton>
            )}
            {ocrProgress && <Typography sx={{ mt: 2 }}>{ocrProgress}</Typography>}
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>{translations[language].cancel}</Button>
            <Button onClick={handleEditSave} color="primary" disabled={!userConsent}>
                {translations[language].save}
            </Button>
        </DialogActions>
    </Dialog>

    </Container>
  );
};

export default PatientHistory;