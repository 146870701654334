import React, { useEffect } from 'react';

const YBugFeedback = () => {
  useEffect(() => {
    window.ybug_settings = {"id":"73qfnqwq91tjf4vnhyj8"};
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://widget.ybug.io/button/' + window.ybug_settings.id + '.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleFeedbackClick = () => {
    if (window.Ybug && typeof window.Ybug.open === 'function') {
      window.Ybug.open();
    } else {
      console.error('Ybug is not available');
    }
  };

  return (
    null
  );
};

export default YBugFeedback;