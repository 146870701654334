import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Typography, Button, CircularProgress, Paper, Box, Snackbar, Alert, TextField,
  Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, FormControlLabel, ButtonGroup, Grid
} from '@mui/material';

import {
  Edit as EditIcon,
  GetApp as DownloadIcon
} from '@mui/icons-material';
import axios from 'axios';
import { useLanguage } from '../components/LanguageContext';
import PatientHistoryTable from '../pages/PatientHistoryTable';
import BloodTestHistory from '../components/BloodTestHistory';
import { initializeFirebase } from '../firebase';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { decryptData } from '../utils/encryption';
import { logAccess, logError } from '../utils/qms';
import { generateHealthInsightsReport } from '../utils/reportGenerator';
import { useCredits } from '../components/CreditContext';
import { useCreditOperations } from '../hooks/useCreditOperations';
import DisclaimerAlert from '../components/DisclaimerAlert';
import ReactMarkdown from 'react-markdown';

const API_URL = process.env.REACT_APP_API_URL;

const HealthInsights = () => {
  // State variables
  const [analyzing, setAnalyzing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [patientHistory, setPatientHistory] = useState([]);
  const [bloodTests, setBloodTests] = useState([]);
  const [bodyMeasurements, setBodyMeasurements] = useState([]);
  const [dataForAnalysis, setDataForAnalysis] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(true);
  const [consentGiven, setConsentGiven] = useState(false);
  const [patientDetails, setPatientDetails] = useState({
    fullName: '',
    dateOfBirth: '',
    gender: '',
    hospitalName: '',
    department: '',
    examType: '',
    examDate: new Date().toISOString().split('T')[0],
  });
  const [editReportOpen, setEditReportOpen] = useState(false);
  const [reportDetails, setReportDetails] = useState({
    fullName: '',
    dateOfBirth: '',
    gender: '',
    hospitalName: '',
    department: '',
    examType: '',
    examDate: new Date().toISOString().split('T')[0],
  });
  

  // Contexts and other variables
  const { language, translations } = useLanguage();
  const { auth, db } = initializeFirebase();
  const { checkCredits, updateCredits } = useCredits();
  const { handleCreditOperation } = useCreditOperations();
  const t = translations[language];
  const [questions, setQuestions] = useState([]);

  // Colors and styles
  const colors = {
    primary: '#1a237e',
    secondary: '#4caf50',
    accent: '#ff9800',
    background: '#f0f4f8',
    text: '#333333',
  };

  const commonStyles = {
    fontFamily: 'Arial, sans-serif',
  };

  const buttonStyle = {
    ...commonStyles,
    fontWeight: 'bold',
    textTransform: 'none',
  };

  // Handlers and functions

  // Handle editing report
  const handleEditReport = () => {
    setEditReportOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditReportOpen(false);
  };

  const handleSaveReportDetails = () => {
    setPatientDetails(reportDetails);
    setReportDetails(reportDetails);
    setEditReportOpen(false);
    
  };

  const handleReportDetailsChange = (event) => {
    const { name, value } = event.target;
    setReportDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  

  // Fetch patient data
  const fetchPatientData = useCallback(async () => {
    if (!auth.currentUser) {
      setLoading(false);
      setError('Please log in to access patient data.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const historyQuery = query(
        collection(db, 'patientHistory'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('date', 'desc')
      );
      const measurementsQuery = query(
        collection(db, 'bodyMeasurements'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('date', 'desc')
      );
      const bloodTestsQuery = query(
        collection(db, 'bloodTests'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('date', 'desc')
      );
      const [historySnapshot, measurementsSnapshot, bloodTestsSnapshot] = await Promise.all([
        getDocs(historyQuery),
        getDocs(measurementsQuery),
        getDocs(bloodTestsQuery)
      ]);

      // Process history data
      const historyData = historySnapshot.docs.map(doc => ({
        id: doc.id,
        date: doc.data().date,
        newAllergies: decryptData(doc.data().encryptedNewAllergies),
        newIntolerances: decryptData(doc.data().encryptedNewIntolerances),
        historyOfPresentIllness: decryptData(doc.data().encryptedHistoryOfPresentIllness),
        visitOutcomeNote: decryptData(doc.data().encryptedVisitOutcomeNote),
        assessmentAndPlan: decryptData(doc.data().encryptedAssessmentAndPlan),
        followUp: decryptData(doc.data().encryptedFollowUp),
        therapy: decryptData(doc.data().encryptedTherapy)
      }));

      // Process measurements data
      const measurementsData = measurementsSnapshot.docs.map(doc => ({
        id: doc.id,
        date: doc.data().date,
        weight: decryptData(doc.data().encryptedWeight),
        height: decryptData(doc.data().encryptedHeight),
        bmi: decryptData(doc.data().encryptedBMI),
        bloodPressure: decryptData(doc.data().encryptedBloodPressure),
        pulse: decryptData(doc.data().encryptedPulse)
      }));

      // Process blood tests data
      const bloodTestsData = bloodTestsSnapshot.docs.map(doc => ({
        id: doc.id,
        date: doc.data().date,
        results: JSON.parse(decryptData(doc.data().encryptedResults)),
        units: JSON.parse(decryptData(doc.data().encryptedUnits))
      }));

      setPatientHistory(historyData);
      setBodyMeasurements(measurementsData);
      setBloodTests(bloodTestsData);
      logAccess('HealthInsights', 'Fetched patient data');
    } catch (error) {
      console.error('Error fetching patient data:', error);
      logError('Error fetching patient data', error);
      setError(translations[language].errorFetchingData);
    } finally {
      setLoading(false);
    }
  }, [auth, db, language, translations]);

  useEffect(() => {
    fetchPatientData();
  }, [fetchPatientData]);

  // Function to format patient data for display
  const formatPatientDataForDisplay = useCallback(() => {
    let formattedData = "PATIENT HEALTH DATA:\n\n";

    formattedData += "1. PATIENT HISTORY:\n";
    patientHistory.forEach((entry, index) => {
      formattedData += `   ${index + 1}. Date: ${entry.date}\n`;
      formattedData += `      New Allergies: ${entry.newAllergies || 'None'}\n`;
      formattedData += `      New Intolerances: ${entry.newIntolerances || 'None'}\n`;
      formattedData += `      History of Present Illness: ${entry.historyOfPresentIllness || 'None'}\n`;
      formattedData += `      Assessment and Plan: ${entry.assessmentAndPlan || 'None'}\n`;
      formattedData += `      Follow Up: ${entry.followUp || 'None'}\n\n`;
    });

    formattedData += "2. BODY MEASUREMENTS:\n";
    bodyMeasurements.forEach((entry, index) => {
      formattedData += `   ${index + 1}. Date: ${entry.date}\n`;
      formattedData += `      Weight: ${entry.weight || 'N/A'}\n`;
      formattedData += `      Height: ${entry.height || 'N/A'}\n`;
      formattedData += `      BMI: ${entry.bmi || 'N/A'}\n`;
      formattedData += `      Blood Pressure: ${entry.bloodPressure || 'N/A'}\n`;
      formattedData += `      Pulse: ${entry.pulse || 'N/A'}\n\n`;
    });

    formattedData += "3. BLOOD TEST RESULTS:\n";
    bloodTests.forEach((test, index) => {
      formattedData += `   ${index + 1}. Date: ${test.date}\n`;
      Object.entries(test.results).forEach(([key, value]) => {
        if (value) {
          formattedData += `      ${key}: ${value} ${test.units[key] || ''}\n`;
        }
      });
      formattedData += '\n';
    });

    return formattedData;
  }, [patientHistory, bodyMeasurements, bloodTests]);

  // Function to format data for AI analysis
  const formatPatientHistoryForAI = useCallback(() => {
    const formattedData = formatPatientDataForDisplay();

    return formattedData;
  }, [formatPatientDataForDisplay]);

  // Handle analysis
  const handleAnalyze = useCallback(async () => {
    setAnalyzing(true); // Show the loading state
  
    try {
      // Fetch and format patient data for analysis
      await fetchPatientData();
      const formattedData = formatPatientDataForDisplay();
  
      // Call your API and handle the credit operation
      const operationResult = await handleCreditOperation(5, async () => {
        const response = await axios.post(`${API_URL}/api/analyze-patient-history`, {
          patientData: formattedData,
          language: language
        });
  
        // Check for analysis in the response
        if (!response.data || typeof response.data.analysis !== 'string') {
          throw new Error('Invalid or missing analysis data in server response.');
        }
  
        return {
          analysis: response.data.analysis,
        };
      });
  
      if (operationResult.success) {
        setAnalysis(operationResult.result.analysis); // Store the analysis result
        setDataForAnalysis(formattedData); // Keep the formatted data for further use
      } else {
        throw new Error(operationResult.error || 'Analysis failed');
      }
    } catch (error) {
      console.error('Error in handleAnalyze:', error);
      setError(error.message || 'An unexpected error occurred during analysis.');
    } finally {
      setAnalyzing(false); // Hide the loading state
    }
  }, [fetchPatientData, formatPatientDataForDisplay, handleCreditOperation, language]);
  

  // Format text for display
  const formatText = (text) => {
    return (
      <ReactMarkdown
        children={text}
        components={{
          h3: ({ node, ...props }) => (
            <Typography
              variant="h5"
              {...props}
              sx={{ fontWeight: 'bold', mt: 4, mb: 2, color: colors.primary }}
            />
          ),
          p: ({ node, ...props }) => <Typography {...props} paragraph sx={{ mb: 2 }} />,
          li: ({ node, ...props }) => (
            <li>
              <Typography {...props} />
            </li>
          ),
        }}
      />
    );
  };
  

  // Sanitize user input
  const sanitizeInput = (input) => {
    if (typeof input === 'string') {
      return input.trim().replace(/[<>&'"]/g, function (c) {
        return {
          '<': '&lt;',
          '>': '&gt;',
          '&': '&amp;',
          "'": '&#39;',
          '"': '&quot;'
        }[c];
      });
    }
    return input;
  };

  

  // Handle chat with AI
  const handleChat = useCallback(async () => {
    if (!userInput.trim()) return;

    const CHAT_CREDIT_COST = 1.5;
    if (!checkCredits(CHAT_CREDIT_COST)) {
      setError(t.notEnoughCreditsChat || 'Not enough credits for chat. Each interaction costs 1.5 credits.');
      return;
    }

    const sanitizedInput = sanitizeInput(userInput);

    const newUserMessage = { role: 'user', content: sanitizedInput };
    setChatHistory(prev => [...prev, newUserMessage]);
    setUserInput('');
    setAnalyzing(true);

    try {
      const response = await axios.post(`${API_URL}/api/chat`, {
        chatHistory,
        userInput: sanitizedInput,
        patientData: dataForAnalysis,
        language: language
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Language': language
        }
      });

      const aiResponse = { role: 'assistant', content: response.data.response };
      setChatHistory(prev => [...prev, aiResponse]);
      await updateCredits(-CHAT_CREDIT_COST);
    } catch (err) {
      console.error('Chat error:', err);
      setError(t.chatError || 'Failed to get a response. Please try again later.');
    } finally {
      setAnalyzing(false);
    }
  }, [userInput, chatHistory, dataForAnalysis, checkCredits, updateCredits, language, t]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleChat();
    }
  };

  // Handle downloading report
  const handleDownloadReport = useCallback(async () => {
    try {
      if (!consentGiven) {
        setError(t.consentRequired || 'Consent is required to download the report.');
        return;
      }
  
      const reportData = {
        patientDetails: reportDetails, // Use reportDetails instead of patientDetails
        analysisResult: analysis,
        chatHistory,
        language,
        patientHistory,
        bloodTests,
        bodyMeasurements,
        questions,
      };
  
      console.log('Report Data:', reportData); // Add this line for debugging
  
      const result = await handleCreditOperation(5, async () => {
        await generateHealthInsightsReport(reportData);
      });
  
      if (!result.success) {
        throw new Error(result.error || 'Download failed');
      }
    } catch (error) {
      console.error('Error in handleDownloadReport:', error);
      setError(error.message || 'Unexpected error during download.');
    }
  }, [
    analysis,
    chatHistory,
    language,
    reportDetails, // Change this from patientDetails to reportDetails
    consentGiven,
    handleCreditOperation,
    t,
    patientHistory,
    bloodTests,
    bodyMeasurements,
    questions,
  ]);
  
  // Handle patient details change
  const handlePatientDetailsChange = (event) => {
    const { name, value } = event.target;
    setPatientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Render component
  return (
    <Box sx={{ bgcolor: colors.background, minHeight: 'calc(100vh - 100px)', pt: 4, pb: 8 }}>
      <Container maxWidth="lg">
        <Typography variant="h2" gutterBottom sx={{ ...commonStyles, color: colors.primary, fontWeight: 'bold' }}>
          {t.healthInsights}
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error" sx={{ mt: 2, mb: 2 }}>{error}</Alert>
        ) : (
          <>
            <Box sx={{ mb: 4 }}>
              <PatientHistoryTable patientHistory={patientHistory} />
            </Box>

            <Box sx={{ mb: 4 }}>
              <BloodTestHistory bloodTests={bloodTests} />
            </Box>

            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
              <ButtonGroup variant="contained">
                <Button
                  onClick={handleAnalyze}
                  disabled={analyzing}
                  sx={{
                    ...buttonStyle,
                    bgcolor: colors.primary,
                    color: colors.background,
                    '&:hover': { bgcolor: colors.accent },
                    '&:disabled': {
                      bgcolor: colors.primary,
                      opacity: 0.7,
                      color: colors.background,
                    },
                  }}
                >
                  {analyzing ? t.analyzing : t.analyzeHealth}
                </Button>
                <Button
                  onClick={handleEditReport}
                  startIcon={<EditIcon />}
                  sx={{
                    ...buttonStyle,
                    bgcolor: colors.secondary,
                    color: colors.background,
                    '&:hover': { bgcolor: colors.accent },
                  }}
                >
                  {t.editReport}
                </Button>
                <Button
                  onClick={handleDownloadReport}
                  startIcon={<DownloadIcon />}
                  disabled={!reportDetails || !consentGiven || !checkCredits(5)}
                  sx={{
                    ...buttonStyle,
                    bgcolor: colors.accent,
                    color: colors.background,
                    '&:hover': { bgcolor: colors.secondary },
                    '&:disabled': {
                      bgcolor: colors.accent,
                      opacity: 0.7,
                      color: colors.background,
                    },
                  }}
                >
                  {t.downloadReport}
                </Button>
              </ButtonGroup>
              {analyzing && <CircularProgress size={24} sx={{ ml: 2 }} />}
            </Box>

            {analysis && (
              <Paper elevation={3} sx={{ mt: 4, p: 3, bgcolor: 'white' }}>
                <Typography variant="h4" gutterBottom sx={{ ...commonStyles, color: colors.primary }}>
                  {t.healthAnalysis}
                </Typography>

                <Box sx={{ ...commonStyles }}>
                  {formatText(analysis)}
                </Box>

                <Box sx={{ mt: 6 }}>
                  <Typography variant="h5" sx={{ ...commonStyles, color: colors.secondary, mb: 3 }}>
                    {t.chatWithAIBilingual}
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    {chatHistory.map((message, index) => (
                      <Paper key={index} elevation={1} sx={{
                        p: 2,
                        my: 1,
                        bgcolor: message.role === 'user' ? '#e3f2fd' : '#f1f8e9',
                        borderRadius: 2
                      }}>
                        <Typography sx={commonStyles}>
                          <strong>{message.role === 'user' ? 'You' : 'AI'}:</strong>
                        </Typography>
                        {formatText(message.content)}
                      </Paper>
                    ))}
                  </Box>
                  <TextField
                    fullWidth
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder={t.askAboutHealth || 'Ask about your health...'}
                    margin="normal"
                    sx={{ ...commonStyles, mb: 2 }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button
                      onClick={handleChat}
                      variant="contained"
                      disabled={analyzing || !checkCredits(1.5)}
                      sx={{
                        ...buttonStyle,
                        bgcolor: colors.secondary,
                        color: colors.background,
                        '&:hover': { bgcolor: colors.accent },
                      }}
                    >
                      {t.send || 'Send'}
                    </Button>
                    {analyzing && <CircularProgress size={24} sx={{ ml: 2 }} />}
                    <Typography variant="caption">
                      {t.chatCreditCost || 'Each chat interaction costs 1.5 credits'}
                    </Typography>
                  </Box>
                </Box>

              </Paper>
            )}
          </>
        )}
      </Container>

      <Dialog
  open={editReportOpen}
  onClose={() => setEditReportOpen(false)}
  maxWidth="md"
  fullWidth
>
  <DialogTitle>{t.editReportDetails}</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {/* Full Name */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          margin="normal"
          name="fullName"
          label={t.fullName}
          value={reportDetails.fullName}
          onChange={handleReportDetailsChange}
        />
      </Grid>

      {/* Date of Birth */}
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          name="dateOfBirth"
          label={t.dateOfBirth}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={reportDetails.dateOfBirth}
          onChange={handleReportDetailsChange}
        />
      </Grid>

      {/* Gender */}
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          name="gender"
          label={t.gender}
          value={reportDetails.gender}
          onChange={handleReportDetailsChange}
        />
      </Grid>

      {/* Hospital Name */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          margin="normal"
          name="hospitalName"
          label={t.hospitalName}
          value={reportDetails.hospitalName}
          onChange={handleReportDetailsChange}
        />
      </Grid>

      {/* Department */}
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          name="department"
          label={t.department}
          value={reportDetails.department}
          onChange={handleReportDetailsChange}
        />
      </Grid>

      {/* Exam Type */}
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          name="examType"
          label={t.examType}
          value={reportDetails.examType}
          onChange={handleReportDetailsChange}
        />
      </Grid>

      {/* Exam Date */}
      <Grid item xs={6}>
        <TextField
          fullWidth
          margin="normal"
          name="examDate"
          label={t.examDate}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={reportDetails.examDate}
          onChange={handleReportDetailsChange}
        />
      </Grid>
    </Grid>

    {/* Consent Checkbox */}
    <FormControlLabel
      control={
        <Checkbox
          checked={consentGiven}
          onChange={(e) => setConsentGiven(e.target.checked)}
          name="editConsent"
        />
      }
      label={t.consentText}
    />
  </DialogContent>
  <DialogActions>
        <Button onClick={handleCloseEditDialog}>{t.cancel}</Button>
        <Button onClick={handleSaveReportDetails} color="primary">
          {t.save}
        </Button>
      </DialogActions>
  
</Dialog>


      <Snackbar
        open={!!error || !!success}
        autoHideDuration={6000}
        onClose={() => { setError(null); setSuccess(null); }}
      >
        <Alert onClose={() => { setError(null); setSuccess(null); }} severity={error ? "error" : "success"}>
          {error || success}
        </Alert>
      </Snackbar>
      <DisclaimerAlert />
    </Box>
  );
};

export default HealthInsights;
