// components/ImageUpload.js
import React, { useState } from 'react';
import { Button, Typography, Box, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLanguage } from '../components/LanguageContext';
import { useCredits } from '../components/CreditContext';

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '20px',
  padding: '10px 20px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
  transition: 'all 0.3s',
  backgroundColor: 'rgb(26, 35, 126)',
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgb(21, 30, 121)',
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 7px 2px rgba(0, 0, 0, .2)',
  },
}));

const ImageUpload = ({ setImage, setError }) => {
  const { language, translations } = useLanguage();
  const { userTier } = useCredits();
  const [fileName, setFileName] = useState('');

  const validateFile = (file) => {
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];
    const maxSize = 2 * 1024 * 1024; // 2MB

    if (!allowedTypes.includes(file.type)) {
      setError(translations[language].invalidFileType);
      return false;
    }

    if (file.size > maxSize) {
      setError(translations[language].fileTooLarge);
      return false;
    }

    return true;
  };

  const sanitizeFileName = (name) => {
    return name.replace(/[^a-zA-Z0-9.-]/g, '_').substring(0, 255);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && validateFile(file)) {
      const sanitizedName = sanitizeFileName(file.name);
      setFileName(sanitizedName);
      setImage(file);
      setError('');
    }
  };

  if (userTier !== 'Tier 1' && userTier !== 'Tier 2') {
    return null; // Don't render the component for unauthorized users
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {translations[language].uploadTestResultImage}
      </Typography>
      <input
        accept=".png,.jpeg,.jpg,.webp"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={handleImageUpload}
      />
      <label htmlFor="raised-button-file">
        <StyledButton
          variant="contained"
          component="span"
        >
          {translations[language].uploadImage}
        </StyledButton>
      </label>
      {fileName && (
        <Typography variant="body2" sx={{ mt: 1 }}>
          {fileName}
        </Typography>
      )}
    </Box>
  );
};

export default ImageUpload;