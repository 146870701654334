import React, { useState, useContext, useEffect } from 'react';
import { TextField, Button, Typography, Container, Paper, Alert, Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate,useLocation  } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { initializeFirebase } from '../firebase';
import { encryptData } from '../utils/encryption';
import { logAccess, logError } from '../utils/qms';
import { useLanguage } from './LanguageContext';
import { CreditContext } from './CreditContext';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [gdprConsent, setGdprConsent] = useState(false);
  const navigate = useNavigate();
  const { auth, db } = initializeFirebase();
  const { language, translations } = useLanguage();
  const { updateUserData } = useContext(CreditContext);
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [lockoutUntil, setLockoutUntil] = useState(0);

  const MAX_ATTEMPTS = 5;
  const LOCKOUT_TIME = 15 * 60 * 1000; // 15 minutes
  


  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
    }
  }, [location]);

  const sanitizeInput = (input) => {
    // Remove potentially dangerous characters
    return input.replace(/[&<>"']/g, function (m) {
      return {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
      }[m]
    });
  };

  const isPasswordStrong = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasNonalphas = /\W/.test(password);
    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas;
  };

  const handleGoogleSignIn = async () => {
    try {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const user = result.user;

        // Get user data from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        let userData = userDoc.data();

        if (!userData) {
            // If user document doesn't exist, create it with default values
            userData = {
                email: user.email,
                displayName: user.displayName || '',
                isAdmin: false,
                tier: 'free',
                credits: 20,
                googleSignIn: true
            };
            await setDoc(doc(db, 'users', user.uid), userData);
        }

        const encryptedUserData = encryptData(JSON.stringify({
            ...userData,
            uid: user.uid,
            lastLogin: new Date().toISOString(),
        }));
        localStorage.setItem('encryptedUserData', encryptedUserData);
        updateUserData(userData);

        logAccess('Login', 'User logged in with Google successfully');
        navigate('/');
    } catch (error) {
        console.error('Detailed Google Sign-In error:', error);
        logError('Login', `Google Sign-In failed: ${error.code} - ${error.message}`, error);
        setError(`${translations[language].loginFailed}: ${error.message}`);
    }
};


  const handleLogin = async (e) => {
    e.preventDefault();

    if (Date.now() < lockoutUntil) {
      setError(translations[language].tooManyAttempts);
      return;
    }
    
    if (!gdprConsent) {
      setError(translations[language].pleaseConsent);
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Get user data from Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      let userData = userDoc.data();

      

      if (!isPasswordStrong(password)) {
        setError(translations[language].weakPassword);
        return;
      }
      
      if (!userData) {
        userData = {
          email: user.email,
          isAdmin: email.toLowerCase() === 'roberto.coscia.it@gmail.com',
          tier: email.toLowerCase() === 'roberto.coscia.it@gmail.com' ? 'admin' : 'free',
          credits: email.toLowerCase() === 'roberto.coscia.it@gmail.com' ? 'Unlimited' : 50,
        };
        await setDoc(doc(db, 'users', user.uid), userData);
      }

      const encryptedUserData = encryptData(JSON.stringify({
        ...userData,
        uid: user.uid,
        lastLogin: new Date().toISOString(),
      }));
      localStorage.setItem('encryptedUserData', encryptedUserData);
      updateUserData(userData);

      setLoginAttempts(0);
      setLockoutUntil(0);

      logAccess('Login', 'User logged in successfully');
      navigate('/');
    } catch (error) {
      const newAttempts = loginAttempts + 1;
      setLoginAttempts(newAttempts);
      
      if (newAttempts >= MAX_ATTEMPTS) {
        const newLockoutTime = Date.now() + LOCKOUT_TIME;
        setLockoutUntil(newLockoutTime);
        setError(translations[language].accountLocked);
      } else {
        setError(translations[language].loginFailed);
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        {message && <Alert severity="info" onClose={() => setMessage('')}>{message}</Alert>}
        <Typography component="h1" variant="h5">
          {translations[language].login}
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={translations[language].email}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(sanitizeInput(e.target.value))}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={translations[language].password}
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(sanitizeInput(e.target.value))}
          />
          <FormControlLabel
            control={<Checkbox value="gdprConsent" color="primary" checked={gdprConsent} onChange={(e) => setGdprConsent(e.target.checked)} />}
            label={translations[language].gdprConsent}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: '1rem' }}
            disabled={!gdprConsent}
          >
            {translations[language].login}
          </Button>
        </form>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          style={{ marginTop: '1rem' }}
          onClick={handleGoogleSignIn}
        >
          {translations[language].loginWithGoogle}
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </Paper>
    </Container>
  );
};

export default Login;