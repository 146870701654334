
import { Document, Paragraph, Table, TableRow, TableCell, HeadingLevel, Packer, AlignmentType, WidthType, TextRun,
   VerticalAlign,TableBorders,  } from 'docx';
import { saveAs } from 'file-saver';
import { translations } from '../components/translations';
import { resultInterpretations } from '../utils/data/resultInterpretations';

const COLORS = {
  PRIMARY: '#1a237e',
  SECONDARY: '#4caf50',
  ACCENT: '#ff9800',
  WHITE: '#ffffff',
  LIGHT_GRAY: '#f0f4f8',
};

const FONTS = {
  TITLE: { name: 'Arial', size: 34, bold: true },
  SUBTITLE: { name: 'Arial', size: 30, bold: true },
  HEADING: { name: 'Arial', size: 26, bold: true },
  NORMAL: { name: 'Arial', size: 20 },
  SMALL: { name: 'Arial', size: 18 },
};

const createParagraph = (textContent, options = {}) => {
  const runs = [];
  const parts = textContent.split(/(\*\*.*?\*\*)/g);
  
  parts.forEach(part => {
    if (part.startsWith('**') && part.endsWith('**')) {
      runs.push(new TextRun({
        text: part.slice(2, -2),
        bold: true,
        ...FONTS.NORMAL,
        ...options,
      }));
    } else {
      runs.push(new TextRun({
        text: part,
        ...FONTS.NORMAL,
        ...options,
      }));
    }
  });

  return new Paragraph({
    children: runs,
    spacing: { before: 200, after: 200 },
    ...options,
  });
};

const createTableCell = (content, options = {}) => {
  return new TableCell({
    children: [createParagraph(content, { spacing: { before: 50, after: 50 } })],
    verticalAlign: VerticalAlign.CENTER,
    ...options,
  });
};

const createPatientDetailsTable = (patientDetails, t) => {
  return new Table({
    width: { size: 100, type: WidthType.PERCENTAGE },
    borders: TableBorders.NONE,
    rows: [
      new TableRow({
        children: [
          createTableCell(`${t.fullName}: ${patientDetails.fullName || ''}`, { width: { size: 50, type: WidthType.PERCENTAGE } }),
          createTableCell(`${t.dateOfBirth}: ${patientDetails.dateOfBirth || ''}`, { width: { size: 50, type: WidthType.PERCENTAGE } }),
        ],
      }),
      new TableRow({
        children: [
          createTableCell(`${t.gender}: ${patientDetails.gender || ''}`, { width: { size: 50, type: WidthType.PERCENTAGE } }),
          createTableCell(`${t.hospitalName}: ${patientDetails.hospitalName || ''}`, { width: { size: 50, type: WidthType.PERCENTAGE } }),
        ],
      }),
      new TableRow({
        children: [
          createTableCell(`${t.examType}: ${patientDetails.examType || ''}`, { width: { size: 50, type: WidthType.PERCENTAGE } }),
          createTableCell(`${t.examDate}: ${patientDetails.examDate || ''}`, { width: { size: 50, type: WidthType.PERCENTAGE } }),
        ],
      }),
    ],
  });
};

export const generateHealthInsightsReport = async (data) => {
  const { patientDetails, analysisResult, chatHistory, language, questions } = data;
  const t = translations[language];

  try {
    const children = [
      new Paragraph({
        text: t.healthInsightsReport,
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.CENTER,
        ...FONTS.TITLE,
        color: COLORS.PRIMARY,
        spacing: { before: 0, after: 400 },
      }),
      new Paragraph({
        text: patientDetails.department || t.departmentOfLaboratoryMedicine,
        alignment: AlignmentType.CENTER,
        ...FONTS.SUBTITLE,
        color: COLORS.SECONDARY,
        spacing: { before: 0, after: 400 },
      }),
      createPatientDetailsTable(patientDetails, t),
      new Paragraph({
        children: [new TextRun({ text: '', size: 14 })],
        spacing: { before: 400, after: 400 },
      }),
      new Paragraph({
        text: t.healthAnalysis,
        heading: HeadingLevel.HEADING_2,
        ...FONTS.HEADING,
        color: COLORS.PRIMARY,
        spacing: { before: 400, after: 200 },
      }),
      ...formatTextForWordDoc(analysisResult),
      new Paragraph({
        text: t.chatHistory,
        heading: HeadingLevel.HEADING_2,
        ...FONTS.HEADING,
        color: COLORS.PRIMARY,
        spacing: { before: 400, after: 200 },
      }),
      ...formatChatHistory(chatHistory, t),
    ];

    const doc = new Document({
      sections: [{
        properties: {
          page: {
            margin: {
              top: 1000,
              right: 1000,
              bottom: 1000,
              left: 1000,
            },
          },
        },
        children: children,
      }],
    });

    const blob = await Packer.toBlob(doc);
      if (!blob) {
        throw new Error('Blob generation returned undefined.');
      }

    saveAs(blob, `health_insights_report_${new Date().toISOString().split('T')[0]}.docx`);
    return blob; // Return the blob if needed elsewhere
  } catch (error) {
    console.error('Error in generateHealthInsightsReport:', error);
    throw new Error('Failed to generate Health Insights report.');
  }
};

const formatChatHistory = (chatHistory, t) => {
  return chatHistory.map((message) => 
    new Paragraph({
      children: [
        new TextRun({
          text: `${message.role === 'user' ? t.user : t.ai}: `,
          ...FONTS.NORMAL,
          bold: true,
          color: message.role === 'user' ? COLORS.PRIMARY : COLORS.SECONDARY,
        }),
        ...formatMessageContent(message.content),
      ],
      spacing: { before: 100, after: 100 },
    })
  );
};

const formatMessageContent = (content) => {
  const parts = content.split(/(\*\*.*?\*\*)/g);
  return parts.map(part => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return new TextRun({
        text: part.slice(2, -2),
        ...FONTS.NORMAL,
        bold: true,
      });
    } else {
      return new TextRun({
        text: part,
        ...FONTS.NORMAL,
      });
    }
  });
};

const formatTextForWordDoc = (text) => {
  if (!text) return [];
  return text.split('\n').map((paragraph) => {
    if (paragraph.trim().startsWith('#')) {
      const level = paragraph.trim().match(/^#+/)[0].length;
      return new Paragraph({
        text: paragraph.replace(/^#+\s*/, ''),
        heading: HeadingLevel[`HEADING_${level}`],
        ...FONTS[level === 1 ? 'TITLE' : level === 2 ? 'SUBTITLE' : 'HEADING'],
        color: COLORS.PRIMARY,
        spacing: { before: 400, after: 200 },
      });
    } else {
      return createParagraph(paragraph, { spacing: { before: 100, after: 100 } });
    }
  });
};

const formatQuestions = (questions) => {
  return questions.map((question, index) => 
    new Paragraph({
      children: [
        new TextRun({
          text: `${index + 1}. `,
          ...FONTS.NORMAL,
          bold: true,
        }),
        new TextRun({
          text: question.replace(/^\d+\.\s*/, '').replace(/\*\*/g, ''),
          ...FONTS.NORMAL,
          bold: true,
        }),
      ],
      spacing: { before: 100, after: 100 },
    })
  );
};

const getReferenceRange = (test, language) => {
  const interpretation = resultInterpretations[test];
  if (!interpretation) return 'N/A';

  const normalRange = interpretation.find(range => range.interpretation[language] === 'normal');
  if (!normalRange) return 'N/A';

  return `${normalRange.range[0]} - ${normalRange.range[1]}`;
};

export const generateBloodTestReportPreview = (data) => {
  const { patientDetails, bloodTests = [], language } = data;
  const t = translations[language] || translations['en'];

  let preview = `
    <div style="font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; border: 1px solid #ccc;">
      <div style="text-align: center; margin-bottom: 20px;">
        <h1 style="color: #1a237e;">${t.bloodTestReport}</h1>
        <h2 style="color: #4caf50;">${patientDetails?.department || t.departmentOfLaboratoryMedicine}</h2>
      </div>
      <div style="margin-bottom: 20px;">
        <p><strong>${t.examType}:</strong> ${patientDetails?.examType || t.notAvailable}</p>
        <p><strong>${t.examDate}:</strong> ${patientDetails?.examDate || t.notAvailable}</p>
        <p><strong>${t.patientName}:</strong> ${patientDetails?.fullName || t.notAvailable}</p>
        <p><strong>${t.dateOfBirth}:</strong> ${patientDetails?.dateOfBirth || t.notAvailable}</p>
        <p><strong>${t.gender}:</strong> ${patientDetails?.gender || t.notAvailable}</p>
        <p><strong>${t.hospital}:</strong> ${patientDetails?.hospitalName || t.notAvailable}</p>
      </div>
  `;

  if (bloodTests.length > 0) {
    preview += `
      <h2 style="color: #1a237e;">${t.bloodTestResults}:</h2>
      <table style="width: 100%; border-collapse: collapse;">
        <tr style="background-color: #1a237e; color: white;">
          <th style="padding: 10px; text-align: left;">${t.test}</th>
          <th style="padding: 10px; text-align: left;">${t.result}</th>
          <th style="padding: 10px; text-align: left;">${t.unit}</th>
          <th style="padding: 10px; text-align: left;">${t.referenceRange}</th>
        </tr>
    `;

    bloodTests.forEach(test => {
      Object.entries(test.results).forEach(([key, value]) => {
        preview += `
          <tr style="border-bottom: 1px solid #ccc;">
            <td style="padding: 10px;">${key}</td>
            <td style="padding: 10px;">${value}</td>
            <td style="padding: 10px;">${test.units[key] || ''}</td>
            <td style="padding: 10px;">${getReferenceRange(key, language)}</td>
          </tr>
        `;
      });
    });

    preview += `</table>`;
  }

  preview += `</div>`;

  return preview;
};

export const generatePatientHistoryReportPreview = (data) => {
  const { patientDetails, bodyMeasurements = [], patientHistory = [], language } = data;
  const t = translations[language] || translations['en'];

  console.log('Generating preview with data:', { patientDetails, bodyMeasurements, patientHistory });

  let preview = `
    <div style="font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; border: 1px solid #ccc;">
      <div style="text-align: center; margin-bottom: 20px;">
        <h1 style="color: #1a237e;">${t.patientHistoryReport}</h1>
        <h2 style="color: #4caf50;">${patientDetails?.department || t.departmentOfLaboratoryMedicine}</h2>
      </div>
      <div style="margin-bottom: 20px;">
        <p><strong>${t.patientName}:</strong> ${patientDetails?.fullName || t.notAvailable}</p>
        <p><strong>${t.dateOfBirth}:</strong> ${patientDetails?.dateOfBirth || t.notAvailable}</p>
        <p><strong>${t.gender}:</strong> ${patientDetails?.gender || t.notAvailable}</p>
      </div>
  `;

  if (bodyMeasurements.length > 0) {
    preview += `
      <h2 style="color: #1a237e;">${t.bodyMeasurements}:</h2>
      <table style="width: 100%; border-collapse: collapse;">
        <tr style="background-color: #1a237e; color: white;">
          <th style="padding: 10px; text-align: left;">${t.date}</th>
          <th style="padding: 10px; text-align: left;">${t.weight}</th>
          <th style="padding: 10px; text-align: left;">${t.height}</th>
          <th style="padding: 10px; text-align: left;">${t.bmi}</th>
          <th style="padding: 10px; text-align: left;">${t.bloodPressure}</th>
          <th style="padding: 10px; text-align: left;">${t.pulse}</th>
        </tr>
    `;

    bodyMeasurements.forEach(measurement => {
      preview += `
        <tr style="border-bottom: 1px solid #ccc;">
          <td style="padding: 10px;">${measurement.date || ''}</td>
          <td style="padding: 10px;">${measurement.weight || ''}</td>
          <td style="padding: 10px;">${measurement.height || ''}</td>
          <td style="padding: 10px;">${measurement.bmi || ''}</td>
          <td style="padding: 10px;">${measurement.bloodPressure || ''}</td>
          <td style="padding: 10px;">${measurement.pulse || ''}</td>
        </tr>
      `;
    });

    preview += `</table>`;
  } else {
    console.log('No body measurements data available');
  }

  if (patientHistory.length > 0) {
    preview += `
      <h2 style="color: #1a237e;">${t.patientHistory}:</h2>
      <table style="width: 100%; border-collapse: collapse;">
        <tr style="background-color: #1a237e; color: white;">
          <th style="padding: 10px; text-align: left;">${t.date}</th>
          <th style="padding: 10px; text-align: left;">${t.newAllergies}</th>
          <th style="padding: 10px; text-align: left;">${t.newIntolerances}</th>
          <th style="padding: 10px; text-align: left;">${t.historyOfPresentIllness}</th>
          <th style="padding: 10px; text-align: left;">${t.assessmentAndPlan}</th>
          <th style="padding: 10px; text-align: left;">${t.followUp}</th>
          <th style="padding: 10px; text-align: left;">${t.examNotes}</th>
        </tr>
    `;

    patientHistory.forEach(entry => {
      preview += `
        <tr style="border-bottom: 1px solid #ccc;">
          <td style="padding: 10px;">${entry.date || ''}</td>
          <td style="padding: 10px;">${entry.newAllergies || ''}</td>
          <td style="padding: 10px;">${entry.newIntolerances || ''}</td>
          <td style="padding: 10px;">${entry.historyOfPresentIllness || ''}</td>
          <td style="padding: 10px;">${entry.assessmentAndPlan || ''}</td>
          <td style="padding: 10px;">${entry.followUp || ''}</td>
          <td style="padding: 10px;">${entry.examNotes || ''}</td>
        </tr>
      `;
    });

    preview += `</table>`;
  } else {
    console.log('No patient history data available');
  }

  preview += `</div>`;

  console.log('Generated preview:', preview);

  return preview;
};

export const generateReportPreview = (data) => {
  const { patientDetails, bloodTests = [], language } = data;
  const t = translations[language] || translations['en'];

  let preview = `
    <div style="font-family: Arial, sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; border: 1px solid #ccc;">
      <div style="text-align: center; margin-bottom: 20px;">
        <h1 style="color: ${COLORS.PRIMARY};">${t.bloodTestReport}</h1>
        <h2 style="color: ${COLORS.SECONDARY};">${patientDetails?.department || t.departmentOfLaboratoryMedicine}</h2>
      </div>
      <div style="margin-bottom: 20px;">
        <p><strong>${t.examType}:</strong> ${patientDetails?.examType || t.notAvailable}</p>
        <p><strong>${t.examDate}:</strong> ${patientDetails?.examDate || t.notAvailable}</p>
        <p><strong>${t.patientName}:</strong> ${patientDetails?.fullName || t.notAvailable}</p>
        <p><strong>${t.dateOfBirth}:</strong> ${patientDetails?.dateOfBirth || t.notAvailable}</p>
        <p><strong>${t.gender}:</strong> ${patientDetails?.gender || t.notAvailable}</p>
        <p><strong>${t.hospital}:</strong> ${patientDetails?.hospitalName || t.notAvailable}</p>
      </div>
  `;

  if (bloodTests.length > 0) {
    preview += `
      <h2 style="color: ${COLORS.PRIMARY};">${t.bloodTestResults}:</h2>
      <table style="width: 100%; border-collapse: collapse;">
        <tr style="background-color: ${COLORS.PRIMARY}; color: ${COLORS.WHITE};">
          <th style="padding: 10px; text-align: left;">${t.test}</th>
          <th style="padding: 10px; text-align: left;">${t.result}</th>
          <th style="padding: 10px; text-align: left;">${t.unit}</th>
          <th style="padding: 10px; text-align: left;">${t.referenceRange}</th>
        </tr>
    `;

    bloodTests.forEach(test => {
      Object.entries(test.results).forEach(([key, value]) => {
        preview += `
          <tr style="border-bottom: 1px solid #ccc;">
            <td style="padding: 10px;">${key}</td>
            <td style="padding: 10px;">${value}</td>
            <td style="padding: 10px;">${test.units[key] || ''}</td>
            <td style="padding: 10px;">${getReferenceRange(key, language)}</td>
          </tr>
        `;
      });
    });

    preview += `</table>`;
  }

  preview += `</div>`;

  return preview;
};

export const generateWordReport = async (data) => {
  const { patientDetails, bloodTests = [], language } = data;
  const t = translations[language] || translations['en'];

  let children = [
    createParagraph(t.bloodTestReport, {
      heading: HeadingLevel.HEADING_1,
      alignment: AlignmentType.CENTER,
      color: COLORS.PRIMARY,
      ...FONTS.TITLE,
    }),
    createParagraph(patientDetails.department || t.departmentOfLaboratoryMedicine, {
      alignment: AlignmentType.CENTER,
      color: COLORS.SECONDARY,
      ...FONTS.SUBTITLE,
    }),
    createParagraph(''),
    createParagraph(`${t.examType}: ${patientDetails.examType || t.notAvailable}`, { ...FONTS.NORMAL }),
    createParagraph(`${t.examDate}: ${patientDetails.examDate || t.notAvailable}`, { ...FONTS.NORMAL }),
    createParagraph(`${t.patientName}: ${patientDetails.fullName || t.notAvailable}`, { ...FONTS.NORMAL }),
    createParagraph(`${t.dateOfBirth}: ${patientDetails.dateOfBirth || t.notAvailable}`, { ...FONTS.NORMAL }),
    createParagraph(`${t.gender}: ${patientDetails.gender || t.notAvailable}`, { ...FONTS.NORMAL }),
    createParagraph(`${t.hospital}: ${patientDetails.hospitalName || t.notAvailable}`, { ...FONTS.NORMAL }),
    createParagraph(''),
  ];

  if (bloodTests.length > 0) {
    children.push(createParagraph(t.bloodTestResults, { ...FONTS.HEADING, color: COLORS.PRIMARY }));

    const tableRows = [
      new TableRow({
        children: [
          createTableCell(t.test, { shading: { fill: COLORS.PRIMARY, color: COLORS.WHITE }, ...FONTS.NORMAL }),
          createTableCell(t.result, { shading: { fill: COLORS.PRIMARY, color: COLORS.WHITE }, ...FONTS.NORMAL }),
          createTableCell(t.unit, { shading: { fill: COLORS.PRIMARY, color: COLORS.WHITE }, ...FONTS.NORMAL }),
          createTableCell(t.referenceRange, { shading: { fill: COLORS.PRIMARY, color: COLORS.WHITE }, ...FONTS.NORMAL }),
        ],
      }),
    ];
    
    bloodTests.forEach((test) => {
      Object.entries(test.results).forEach(([key, value]) => {
        tableRows.push(
          new TableRow({
            children: [
              createTableCell(key, { ...FONTS.NORMAL }),
              createTableCell(value.toString(), { ...FONTS.NORMAL }),
              createTableCell(test.units[key] || '', { ...FONTS.NORMAL }),
              createTableCell(getReferenceRange(key, language), { ...FONTS.NORMAL }),
            ],
          })
        );
      });
    });

    children.push(new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      rows: tableRows,
    }));
  } else {
    children.push(createParagraph(t.noBloodTestResults, { ...FONTS.NORMAL }));
  }

  const doc = new Document({
    sections: [{
      properties: {
        page: {
          margin: {
            top: 1000,
            right: 1000,
            bottom: 1000,
            left: 1000,
          }
        }
      },
      children: children,
    }],
  });

  try {
    console.log('Generating blob...');
    const blob = await Packer.toBlob(doc);
    if (!blob) {
      throw new Error('Blob generation failed.');
    }
    console.log('Blob generated successfully');
    return blob;  // Return the blob to be used for download
  } catch (error) {
    console.error('Error generating Word document:', error);
    throw new Error('Failed to generate Word document');
  }
};



export const generatePatientHistoryReport = (data) => {
  const { patientDetails, bodyMeasurements, patientHistory, language } = data;
  const t = translations[language] || translations['en'];

  const renderValue = (value) => value || 'N/A';

  const tableStyles = {
    tableHeader: {
      fill: {
        color: '#1A2F7A', // Darker navy blue color for header background
      },
      color: '#FFFFFF', // White text for header
      bold: true,
    },
    tableRow: {
      cantSplit: true,
    },
    tableCell: {
      margins: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
    },
  };

  const createStyledTableRow = (cells, isHeader = false) => {
    return new TableRow({
      children: cells.map(cellText => 
        new TableCell({
          children: [new Paragraph({ text: cellText, ...FONTS.NORMAL })],
          ...(isHeader ? tableStyles.tableHeader : {}),
          ...tableStyles.tableCell,
        })
      ),
      ...tableStyles.tableRow,
    });
  };

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: 'Heading1',
          name: 'Heading 1',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 28,
            bold: true,
            color: COLORS.PRIMARY,
          },
          paragraph: {
            spacing: { before: 240, after: 120 },
          },
        },
        {
          id: 'Heading2',
          name: 'Heading 2',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            size: 26,
            bold: true,
            color: COLORS.PRIMARY,
          },
          paragraph: {
            spacing: { before: 240, after: 120 },
          },
        },
      ],
    },
    sections: [{
      properties: {},
      children: [
        new Paragraph({
          text: t.patientHistoryReport,
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph({
          text: renderValue(patientDetails?.department),
          alignment: AlignmentType.CENTER,
          ...FONTS.SUBTITLE,
          color: COLORS.SECONDARY,
        }),
        new Paragraph({ text: `${t.patientName}: ${renderValue(patientDetails?.fullName)}`, ...FONTS.NORMAL }),
        new Paragraph({ text: `${t.dateOfBirth}: ${renderValue(patientDetails?.dateOfBirth)}`, ...FONTS.NORMAL }),
        new Paragraph({ text: `${t.gender}: ${renderValue(patientDetails?.gender)}`, ...FONTS.NORMAL }),
        new Paragraph({
          text: t.bodyMeasurements,
          heading: HeadingLevel.HEADING_2,
        }),
        new Table({
          width: { size: 100, type: WidthType.PERCENTAGE },
          rows: [
            createStyledTableRow([t.date, t.weight, t.height, t.bmi, t.bloodPressure, t.pulse], true),
            ...(bodyMeasurements || []).map(m => 
              createStyledTableRow([
                renderValue(m.date),
                renderValue(m.weight),
                renderValue(m.height),
                renderValue(m.bmi),
                renderValue(m.bloodPressure),
                renderValue(m.pulse)
              ])
            ),
          ],
        }),
        new Paragraph({
          text: t.patientHistory,
          heading: HeadingLevel.HEADING_2,
        }),
        new Table({
          width: { size: 100, type: WidthType.PERCENTAGE },
          rows: [
            createStyledTableRow([
              t.date,
              t.newAllergies,
              t.newIntolerances,
              t.historyOfPresentIllness,
              t.assessmentAndPlan,
              t.followUp,
              t.examNotes
            ], true),
            ...(patientHistory || []).map(h => 
              createStyledTableRow([
                renderValue(h.date),
                renderValue(h.newAllergies),
                renderValue(h.newIntolerances),
                renderValue(h.historyOfPresentIllness),
                renderValue(h.assessmentAndPlan),
                renderValue(h.followUp),
                renderValue(h.examNotes)
              ])
            ),
          ],
        }),
      ],
    }],
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `patient_history_report_${new Date().toISOString().split('T')[0]}.docx`);
  });
};