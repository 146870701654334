import React from 'react';
import { Container, Typography, Grid, Button, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { logAccess } from '../utils/qms';
import { useLanguage } from '../components/LanguageContext';
import DisclaimerAlert from '../components/DisclaimerAlert'


const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '20px',
  padding: '10px 20px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 7px 2px rgba(0, 0, 0, .2)',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '15px',
  boxShadow: '0 5px 15px rgba(0, 0, 0, .1)',
  backgroundColor: '#f0f4f8',
}));

const PatientDataOverview = () => {
  const navigate = useNavigate();
  const { language, translations } = useLanguage();

  const handleNavigation = (path) => {
    logAccess('PatientDataOverview', `Navigated to ${path}`);
    navigate(path);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h2" gutterBottom sx={{ color: '#1a237e', fontWeight: 'bold', mt: 4, mb: 4 }}>
        {translations[language].patientDataOverview}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h5" gutterBottom sx={{ color: '#303f9f' }}>
              {translations[language].bloodTestHistory}
            </Typography>
            <StyledButton
              variant="contained"
              sx={{
                backgroundColor: '#1a237e',
                color: 'white',
                '&:hover': { backgroundColor: '#303f9f' }
              }}
              onClick={() => handleNavigation('/blood-test-history')}
            >
              {translations[language].viewBloodTestHistory}
            </StyledButton>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h5" gutterBottom sx={{ color: '#303f9f' }}>
              {translations[language].patientHistory}
            </Typography>
            <StyledButton
              variant="contained"
              sx={{
                backgroundColor: '#1a237e',
                color: 'white',
                '&:hover': { backgroundColor: '#303f9f' }
              }}
              onClick={() => handleNavigation('/patient-history')}
            >
              {translations[language].viewFullPatientHistory}
            </StyledButton>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledPaper>
            <Typography variant="h5" gutterBottom sx={{ color: '#303f9f' }}>
              {translations[language].healthInsights}
            </Typography>
            <StyledButton
              variant="contained"
              sx={{
                backgroundColor: '#1a237e',
                color: 'white',
                '&:hover': { backgroundColor: '#303f9f' }
              }}
              onClick={() => handleNavigation('/health-insights')}
            >
              {translations[language].analyzeHealth}
            </StyledButton>
          </StyledPaper>
        </Grid>
      </Grid>
      <DisclaimerAlert/>
    </Container>
  );
};

export default PatientDataOverview;