import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, Box } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import { initializeFirebase } from './firebase';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import TestInput from './pages/TestInput';
import Results from './pages/Results';
import Login from './components/Login';
import Register from './components/Register';
import PatientHistory from './pages/PatientHistory';
import BloodTestHistory from './components/BloodTestHistory';
import PatientDataOverview from './pages/PatientDataOverview';
import PatientHistoryTable from './pages/PatientHistoryTable';
import HealthInsights from './pages/HealthInsight';
import { LanguageProvider } from './components/LanguageContext';
import { CreditProvider } from './components/CreditContext';
import PurchaseCredits from './components/PurchaseCredits';
import HomeNotRegistered from './pages/HomeNotRegistered';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import Contacts from './pages/Contacts';
import './index.css';
import { initializeSessionManagement, cleanupSessionManagement } from './utils/sessionManager';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancelled from './components/PaymentCancelled';
import { UserProvider } from './components/UserContext';


const { auth } = initializeFirebase();

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      if (currentUser) {
        initializeSessionManagement(); // Start session management for authenticated users
      }
    });
  
    return () => {
      unsubscribe();
      cleanupSessionManagement(); // Cleanup when component unmounts or user logs out
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <UserProvider>  
      <LanguageProvider>
        <CreditProvider>
          <Router>
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
              <Header user={user} onUserUpdate={setUser} />
              <Box component="main" sx={{ flexGrow: 1, pt: 4, pb: 8 }}>
                
                <Routes>
                  {/* Route for non-registered users */}
                  <Route path="/" element={user ? <Home /> : <HomeNotRegistered />} />
                  
                  <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
                  <Route path="/register" element={user ? <Navigate to="/" /> : <Register />} />
                  <Route path="/test-input" element={user ? <TestInput /> : <Navigate to="/login" />} />
                  <Route path="/results" element={user ? <Results /> : <Navigate to="/login" />} />
                  <Route path="/history" element={user ? <PatientHistory /> : <Navigate to="/login" />} />
                  <Route path="/blood-test-history" element={user ? <BloodTestHistory /> : <Navigate to="/login" />} />
                  <Route path="/patient-data-overview" element={user ? <PatientDataOverview /> : <Navigate to="/login" />} />
                  <Route path="/patient-history" element={user ? <PatientHistoryTable /> : <Navigate to="/login" />} />
                  <Route path="/health-insights" element={user ? <HealthInsights /> : <Navigate to="/login" />} />
                  <Route path="/purchase-credits" element={user ? <PurchaseCredits /> : <Navigate to="/login" />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                  <Route path="*" element={<Navigate to="/" />} />
                  <Route path="/contact" element={<Contacts />} />
                  <Route path="/payment-success" element={<PaymentSuccess />} />
                  <Route path="/payment-cancelled" element={<PaymentCancelled />} />
                </Routes>
              </Box>
              <Footer />
            </Box>
          </Router>
        </CreditProvider>
      </LanguageProvider>
      </UserProvider>
  );
}

export default App;