import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography, Container, Button, Grid, Alert, Paper, FormControl, InputLabel,
  Select, MenuItem, TextField, Box, Checkbox, FormControlLabel, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog,
  DialogTitle, DialogContent, DialogActions, IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { addDoc, setDoc, doc, collection } from 'firebase/firestore';
import ImageUpload from '../components/ImageUpload';
import { validateTestResults } from '../utils/validationUtils';
import { bloodTestParameters } from '../utils/data/bloodTestParameters';
import { encryptData } from '../utils/encryption';
import { logError, reportIssue, logAccess } from '../utils/qms';
import { initializeFirebase } from '../firebase';
import { useLanguage } from '../components/LanguageContext';
import { useCredits } from '../components/CreditContext';
import DisclaimerAlert from '../components/DisclaimerAlert';
import { performOCR } from '../utils/ocrUtils';
import { processWithAI, analyzeResults } from '../utils/ocrUtils';
import CustomInputField from '../components/CustomInputField';
import { useCreditOperations } from '../hooks/useCreditOperations'; 


const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '20px',
  padding: '10px 20px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 7px 2px rgba(0, 0, 0, .2)',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0),
  borderRadius: '15px',
  boxShadow: '0 5px 15px rgba(0, 0, 0, .1)',
  backgroundColor: '#f0f4f8',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: '15px',
  overflow: 'hidden',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: '#1a237e',
  '& th': {
    color: 'white',
    fontWeight: 'bold',
  },
}));

const TestInput = () => {
  const { language, translations } = useLanguage();
  const [testResults, setTestResults] = useState({});
  const [units, setUnits] = useState({});
  const [image, setImage] = useState(null);
  const [ocrProgress, setOcrProgress] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTests, setFilteredTests] = useState([]);
  const [displayedTests, setDisplayedTests] = useState([]);
  const [displayCount, setDisplayCount] = useState(10);
  const [date, setDate] = useState('');
  const [editingTestId, setEditingTestId] = useState(null);
  const [userConsent, setUserConsent] = useState(false);
  const { checkCredits, updateCredits } = useCredits();
  const [customTests, setCustomTests] = useState({});
  const [customUnits, setCustomUnits] = useState({});
  const [openCustomDialog, setOpenCustomDialog] = useState(false);
  const [newCustomTest, setNewCustomTest] = useState({ name: '', value: '', unit: '' });
  const [editingCustomTest, setEditingCustomTest] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [testToDelete, setTestToDelete] = useState(null);
  const [customInterpretations, setCustomInterpretations] = useState({});
  const { handleCreditOperation } = useCreditOperations();
  const { userTier } = useCredits();
  const [allTests, setAllTests] = useState({});

  const { auth, db, storage } = initializeFirebase();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login');
      logAccess('TestInput', 'Component mounted');
    }
  }, [auth, navigate]);

  useEffect(() => {
    if (location.state?.editingTest) {
      const { id, date, results, units, customResults, customUnits } = location.state.editingTest;
      setEditingTestId(id);
      setDate(date);
      
      // Separate standard tests and custom tests
      const standardTests = {};
      const customTestsObj = {};
      Object.keys(results).forEach(test => {
        if (bloodTestParameters[test]) {
          standardTests[test] = results[test];
        } else {
          customTestsObj[test] = results[test];
        }
      });
      
      setTestResults(standardTests);
      setCustomTests(customTestsObj);
      setUnits({...units, ...customUnits});
      setAllTests({...standardTests, ...customTestsObj});
      logAccess('TestInput', 'Editing existing test');
    }
  }, [location.state]);

  useEffect(() => {
    const filtered = Object.keys(bloodTestParameters).filter(test => {
      const label = bloodTestParameters[test].label[language];
      const matchesSearch = label.toLowerCase().includes(searchTerm.toLowerCase()) ||
        test.toLowerCase().includes(searchTerm.toLowerCase());
      const hasInput = testResults[test] !== undefined && testResults[test] !== '';
      return matchesSearch || hasInput;
    });
    setFilteredTests(filtered);
    setDisplayCount(10);
  }, [searchTerm, testResults, language]);

  useEffect(() => {
    setDisplayedTests(filteredTests.slice(0, displayCount));
  }, [filteredTests, displayCount]);

  useEffect(() => {
    console.log('Current test results:', testResults);
    console.log('Current units:', units);
  }, [testResults, units]);

  const handleAddCustomTest = () => {
    if (newCustomTest.name && newCustomTest.value) {
      setCustomTests(prev => ({
        ...prev,
        [newCustomTest.name]: newCustomTest.value
      }));
      setCustomUnits(prev => ({
        ...prev,
        [newCustomTest.name]: newCustomTest.unit
      }));
      setCustomInterpretations(prev => ({
        ...prev,
        [newCustomTest.name]: [
          {
            range: [0, Infinity],
            interpretation: { 
              en: "Custom test interpretation not available",
              it: "Interpretazione del test personalizzato non disponibile"
            },
            advice: {
              en: "Please consult a healthcare professional for interpretation of this custom test.",
              it: "Si prega di consultare un professionista sanitario per l'interpretazione di questo test personalizzato."
            }
          }
        ]
      }));
      setNewCustomTest({ name: '', value: '', unit: '' });
      setOpenCustomDialog(false);
    }
  };
  

  const handleEditCustomTest = (testName) => {
    setEditingCustomTest({
      name: testName,
      value: customTests[testName],
      unit: customUnits[testName]
    });
    setOpenCustomDialog(true);
  };

  const handleUpdateCustomTest = () => {
    if (editingCustomTest.name && editingCustomTest.value) {
      setCustomTests(prev => {
        const newTests = { ...prev };
        delete newTests[editingCustomTest.name];
        return { ...newTests, [editingCustomTest.name]: editingCustomTest.value };
      });
      setCustomUnits(prev => {
        const newUnits = { ...prev };
        delete newUnits[editingCustomTest.name];
        return { ...newUnits, [editingCustomTest.name]: editingCustomTest.unit };
      });
      setEditingCustomTest(null);
      setOpenCustomDialog(false);
      setSuccess(translations[language].customTestUpdated);
    }
  };

  const handleDeleteCustomTest = (testName) => {
    setTestToDelete(testName);
    setDeleteConfirmOpen(true);
  };

  const confirmDeleteCustomTest = () => {
    setCustomTests(prev => {
      const newTests = { ...prev };
      delete newTests[testToDelete];
      return newTests;
    });
    setCustomUnits(prev => {
      const newUnits = { ...prev };
      delete newUnits[testToDelete];
      return newUnits;
    });
    setDeleteConfirmOpen(false);
    setTestToDelete(null);
    setSuccess(translations[language].customTestDeleted);
  };

  const handleChange = (event) => {
    if (!event || !event.target) {
      console.error('Invalid event object:', event);
      return;
    }
    const { name, value } = event.target;
    setTestResults((prev) => ({
      ...prev,
      [name]: value,
    }));
};

const handleCustomChange = (testName, value) => {
  setTestResults((prev) => ({
    ...prev,
    [testName]: value,
  }));
};



  const handleUnitChange = (event) => {
    const { name, value } = event.target;
    setUnits(prev => ({
      ...prev,
      [name]: value,
    }));
  };



  const handleOCR = async () => {
    if (!image) {
      setError('Please upload an image before performing OCR.');
      return;
    }

    try {
      const cost = 5; // Set the credit cost for performing OCR
      const { success, error: creditError, result } = await handleCreditOperation(cost, async () => {
        setOcrProgress('Processing image...');
        const result = await performOCR(image, setOcrProgress);

        if (!result || !result.analysis) {
          throw new Error('OCR failed to extract or analyze text from the image.');
        }

        setOcrProgress('Analyzing results...');
        const { updatedTestResults, updatedUnits } = analyzeResults(result.analysis);

        // Update state with the OCR results
        setTestResults(prevResults => ({
          ...prevResults,
          ...updatedTestResults
        }));

        setUnits(prevUnits => ({
          ...prevUnits,
          ...updatedUnits
        }));

        setOcrProgress('');
        return result;
      });

      if (!success) {
        throw new Error(creditError || 'OCR failed due to insufficient credits.');
      }

      setSuccess('OCR completed. Please verify the extracted values.');
    } catch (err) {
      console.error('Detailed OCR Error:', err);
      setError('Error processing image: ' + err.message);
    }
  };
  
  const renderCustomTestInputs = () => {
    return Object.entries(customTests).map(([test, value]) => (
      <TableRow key={test}>
        <TableCell>{test}</TableCell>
        <TableCell>
          <CustomInputField
            label={test}
            value={value || ''}
            onChange={(value) => handleCustomChange(test, value)}
          />
        </TableCell>
        <TableCell>
          <TextField
            value={customUnits[test] || ''}
            onChange={(e) => setCustomUnits(prev => ({ ...prev, [test]: e.target.value }))}
            fullWidth
          />
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleEditCustomTest(test)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteCustomTest(test)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  const renderAllTestInputs = () => {
    const standardTests = Object.keys(bloodTestParameters).filter(test => 
      displayedTests.includes(test) || testResults[test] !== undefined
    );
  
    return (
      <>
        {standardTests.map((test) => (
          <TableRow key={test}>
            <TableCell>{bloodTestParameters[test].label[language]}</TableCell>
            <TableCell>
              {/* Standard TextField for direct input */}
              <TextField
                name={test}
                value={testResults[test] || ''}
                onChange={handleChange}
                type="text"
                fullWidth
                placeholder={`Enter ${bloodTestParameters[test].label[language]} value`}
              />
            </TableCell>
            <TableCell>
              {/* CustomInputField for advanced input options */}
              <CustomInputField
                label={bloodTestParameters[test].label[language]}
                value={testResults[test] || ''}
                onChange={(value) => handleCustomChange(test, value)}
              />
            </TableCell>
            <TableCell>
              <Select
                name={test}
                value={units[test] || ''}
                onChange={handleUnitChange}
                fullWidth
              >
                {bloodTestParameters[test].units.map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {unit}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
          </TableRow>
        ))}
        {Object.entries(customTests).map(([test, value]) => (
          <TableRow key={test}>
            <TableCell>{test}</TableCell>
            <TableCell>
              {/* Standard TextField for direct input */}
              <TextField
                name={test}
                value={value || ''}
                onChange={(e) => setCustomTests(prev => ({ ...prev, [test]: e.target.value }))}
                type="text"
                fullWidth
                placeholder={`Enter ${test} value`}
              />
            </TableCell>
            <TableCell>
              {/* CustomInputField for advanced input options */}
              <CustomInputField
                label={test}
                value={value || ''}
                onChange={(value) => handleCustomChange(test, value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                value={customUnits[test] || ''}
                onChange={(e) => setCustomUnits(prev => ({ ...prev, [test]: e.target.value }))}
                fullWidth
                placeholder="Enter unit"
              />
            </TableCell>
            <TableCell>
              <IconButton onClick={() => handleEditCustomTest(test)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteCustomTest(test)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

const renderTestInputs = () => {
  return displayedTests.map((test) => (
    <TableRow key={test}>
      <TableCell>{bloodTestParameters[test].label[language]}</TableCell>

      {/* Standard TextField */}
      <TableCell>
        <TextField
          name={test}
          value={testResults[test] || ''}
          onChange={handleChange}
          type="text"
          fullWidth
        />
      </TableCell>

      {/* CustomInputField */}
      <TableCell>
        <CustomInputField
          label={bloodTestParameters[test].label[language]}
          value={testResults[test] || ''}
          onChange={(value) => handleCustomChange(test, value)}
        />
      </TableCell>

      <TableCell>
        <Select
          name={test}
          value={units[test] || ''}
          onChange={handleUnitChange}
          fullWidth
        >
          {bloodTestParameters[test].units.map((unit) => (
            <MenuItem key={unit} value={unit}>
              {unit}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    </TableRow>
  ));
};








  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("Current test results:", testResults);
    console.log("Current units:", units);
    console.log("Current custom tests:", customTests);
    console.log("Current custom units:", customUnits);

    const allTestResults = { ...testResults, ...customTests };
    const allUnits = { ...units, ...customUnits };

    const validTestResults = {};
    Object.keys(allTestResults).forEach(test => {
      if (allTestResults[test] !== '') {
        validTestResults[test] = allTestResults[test];
      }
    });

  if (Object.keys(validTestResults).length === 0) {
    setError(translations[language].enterAtLeastOne);
    return;
  }

  if (!auth.currentUser) {
    setError(translations[language].mustBeLoggedIn);
    return;
  }

  if (!userConsent) {
    setError(translations[language].pleaseConsent);
    return;
  }

  if (!date) {
    setError(translations[language].enterValidDate);
    return;
  }

  const validationError = validateTestResults(validTestResults);
  if (validationError) {
    setError(validationError);
    logError('TestInput', validationError);
    return;
  }

  if (!checkCredits(4)) {
    setError(translations[language].notEnoughCredits);
    return;
  }


  try {
    setError('');
    const allTestResults = { ...testResults, ...customTests };
    const allUnits = { ...units, ...customUnits };

    const encryptedResults = encryptData(JSON.stringify(allTestResults));
    const encryptedUnits = encryptData(JSON.stringify(allUnits));

    const bloodTestData = {
      userId: auth.currentUser.uid,
      date,
      encryptedResults,
      encryptedUnits,
      updatedAt: new Date()
    };

    if (editingTestId) {
      await setDoc(doc(db, 'bloodTests', editingTestId), bloodTestData, { merge: true });
    } else {
      await addDoc(collection(db, 'bloodTests'), {
        ...bloodTestData,
        createdAt: new Date()
      });
    }

    await updateCredits(-4);
    setSuccess(translations[language].dataSaved);
    logAccess('TestInput', 'Blood test data saved');
    setTimeout(() => {
      navigate('/blood-test-history');
    }, 2000);
  } catch (error) {
    console.error('Error saving blood test:', error);
    setError(`${translations[language].errorSaving}: ${error.message}`);
    logError('TestInput', 'Error saving blood test', error);
  }
};

  const handleLoadMore = () => {
    setDisplayCount(prev => prev + 10);
  };

  const handleInterpret = () => {
    const allTestResults = { ...testResults, ...customTests };
    const allUnits = { ...units, ...customUnits };
    const encryptedResults = encryptData(JSON.stringify(allTestResults));
    const encryptedUnits = encryptData(JSON.stringify(allUnits));
    navigate('/results', { state: { encryptedResults, encryptedUnits } });
    logAccess('TestInput', 'Navigated to results interpretation');
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h2" gutterBottom sx={{ color: '#1a237e', fontWeight: 'bold', mt: 4 }}>
        {translations[language].inputResults}
      </Typography>
  
      {error && (
        <Alert severity="error" onClose={() => setError('')} sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" onClose={() => setSuccess('')} sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
  
      <StyledPaper>
        <form onSubmit={handleSubmit}>
          <TextField
            label={translations[language].testDate}
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
  
          <TextField
            label={translations[language].searchTests}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
          />
  
          <StyledTableContainer component={Paper}>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>{translations[language].testName}</TableCell>
                  <TableCell>{translations[language].value}</TableCell>
                  <TableCell>{translations[language].unit}</TableCell>
                  <TableCell>{translations[language].actions}</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {renderAllTestInputs()}
              </TableBody>
            </Table>
          </StyledTableContainer>
  
          {filteredTests.length > displayCount && (
            <StyledButton onClick={handleLoadMore} sx={{ mt: 2 }}>
              {translations[language].loadMore}
            </StyledButton>
          )}
  
          <StyledButton 
            onClick={() => {
              setEditingCustomTest(null);
              setOpenCustomDialog(true);
            }} 
            sx={{ mt: 2, backgroundColor: '#4caf50', color: 'white', '&:hover': { backgroundColor: '#45a049' } }}
          >
            {translations[language].addCustomTest}
          </StyledButton>
  
          {(userTier === 'Tier 1' || userTier === 'Tier 2') && (
            <ImageUpload setImage={setImage} setError={setError} />
          )}
  
          {image && (
            <StyledButton onClick={handleOCR} sx={{ mt: 2 }}>
              {translations[language].performOCR}
            </StyledButton>
          )}
  
          {ocrProgress && <Typography sx={{ mt: 2 }}>{ocrProgress}</Typography>}  
  
          <FormControlLabel
            control={
              <Checkbox
                checked={userConsent}
                onChange={(e) => setUserConsent(e.target.checked)}
                name="userConsent"
              />
            }
            label={translations[language].consent}
            sx={{ mt: 2, display: 'block' }}
          />

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <StyledButton 
              type="submit" 
              variant="contained" 
              sx={{ 
                backgroundColor: '#1a237e',
                color: 'white', 
                '&:hover': { backgroundColor: '#303f9f' },
                '&:disabled': { backgroundColor: '#9fa8da' }
              }} 
              disabled={!userConsent}
            >
              {editingTestId ? translations[language].updateBloodTest : translations[language].saveBloodTest}
            </StyledButton>

            <StyledButton 
              onClick={handleInterpret} 
              variant="contained" 
              sx={{ 
                backgroundColor: '#ff9800',
                color: 'white', 
                '&:hover': { backgroundColor: '#f57c00' } 
              }}
            >
              {translations[language].interpretResults}
            </StyledButton>
          </Box>
        </form>
      </StyledPaper>
  
      {/* Dialog for adding/editing custom tests */}
      <Dialog open={openCustomDialog} onClose={() => setOpenCustomDialog(false)}>
        <DialogTitle>
          {editingCustomTest ? translations[language].editCustomTest : translations[language].addCustomTest}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={translations[language].testName}
            fullWidth
            value={editingCustomTest ? editingCustomTest.name : newCustomTest.name}
            onChange={(e) => editingCustomTest 
              ? setEditingCustomTest(prev => ({ ...prev, name: e.target.value }))
              : setNewCustomTest(prev => ({ ...prev, name: e.target.value }))
            }
          />
          <TextField
            margin="dense"
            label={translations[language].value}
            type="number"
            fullWidth
            value={editingCustomTest ? editingCustomTest.value : newCustomTest.value}
            onChange={(e) => editingCustomTest
              ? setEditingCustomTest(prev => ({ ...prev, value: e.target.value }))
              : setNewCustomTest(prev => ({ ...prev, value: e.target.value }))
            }
          />
          <TextField
            margin="dense"
            label={translations[language].unit}
            fullWidth
            value={editingCustomTest ? editingCustomTest.unit : newCustomTest.unit}
            onChange={(e) => editingCustomTest
              ? setEditingCustomTest(prev => ({ ...prev, unit: e.target.value }))
              : setNewCustomTest(prev => ({ ...prev, unit: e.target.value }))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCustomDialog(false)}>{translations[language].cancel}</Button>
          <Button onClick={editingCustomTest ? handleUpdateCustomTest : handleAddCustomTest}>
            {editingCustomTest ? translations[language].update : translations[language].add}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>{translations[language].confirmDeleteCustomTest}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>{translations[language].cancel}</Button>
          <Button onClick={confirmDeleteCustomTest} color="error">
            {translations[language].delete}
          </Button>
        </DialogActions>
      </Dialog>

  
      <DisclaimerAlert/>
    </Container>
  );
};


export default TestInput;