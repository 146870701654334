import React from 'react';
import { Typography, Box } from '@mui/material';
import { useLanguage } from './LanguageContext';

const HealthcareDisclaimer = () => {
  const { language, translations } = useLanguage();

  return (
    <Box mt={4}>
      <Typography variant="body2" color="textSecondary">
        {translations[language].disclaimer}
      </Typography>
    </Box>
  );
};

export default HealthcareDisclaimer;
