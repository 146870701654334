// SessionManager.js
import { signOut } from 'firebase/auth';
import { initializeFirebase } from '../firebase';

const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
let timeout;

const { auth } = initializeFirebase();

const logout = async () => {
  try {
    await signOut(auth);
    // Clear any user data from local storage if you're storing it
    localStorage.removeItem('encryptedUserData');
    // Redirect to login page
    window.location.href = '/login';
  } catch (error) {
    console.error('Error signing out:', error);
    // You might want to handle this error more gracefully in a production app
  }
};

const startSessionTimer = () => {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => {
    logout();
    alert('Your session has expired due to inactivity.');
  }, SESSION_TIMEOUT);
};

const resetSessionTimer = () => {
  startSessionTimer();
};

// Function to initialize session tracking
export const initializeSessionManagement = () => {
  document.addEventListener('mousemove', resetSessionTimer);
  document.addEventListener('keypress', resetSessionTimer);
  document.addEventListener('click', resetSessionTimer);
  startSessionTimer(); // Start the session immediately upon initialization
};

// Cleanup function to remove event listeners
export const cleanupSessionManagement = () => {
  clearTimeout(timeout);
  document.removeEventListener('mousemove', resetSessionTimer);
  document.removeEventListener('keypress', resetSessionTimer);
  document.removeEventListener('click', resetSessionTimer);
};