export const convertToStandardUnits = (value, unit, test) => {
    if (value === undefined || value === null || isNaN(value)) return 0;
    
    switch (test) {
      case 'hemoglobin':
        if (unit === 'g/L') return value / 10;
        if (unit === 'mmol/L') return value * 1.61;
        return value; // g/dL
      case 'glucose':
        return unit === 'mmol/L' ? value * 18 : value; // mg/dL
      case 'creatinine':
        return unit === 'µmol/L' ? value / 88.4 : value; // mg/dL
      // Add more conversion logic for other tests as needed
      default:
        return value;
    }
  };