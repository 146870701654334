import React from 'react';
import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import { useLanguage } from '../components/LanguageContext';

const DisclaimerAlert = () => {
  const { language, translations } = useLanguage();

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      mt: 2, 
      mb: 2,
      width: '100%'
    }}>
      <Alert 
        severity="info" 
        variant="outlined"
        sx={{
          maxWidth: '600px',
          width: '100%',
          backgroundColor: '#f0f4f8', // Light blue background
          borderColor: '#1a237e', // Dark blue border
          '& .MuiAlert-message': {
            width: '100%'
          }
        }}
      >
        <AlertTitle sx={{ 
          fontWeight: 'bold', 
          textAlign: 'center', 
          color: '#f44336', // Red color for the title
          fontSize: '1.2rem'
        }}>
          {translations[language].disclaimerTitle}
        </AlertTitle>
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ mt: 1, color: '#1a237e' }}> {/* Dark blue text */}
            {translations[language].disclaimerContent}
          </Typography>
        </Box>
      </Alert>
    </Box>
  );
};

export default DisclaimerAlert;