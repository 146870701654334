import React, { useState } from 'react';
import { 
  Container, Typography, Box, Grid, TextField, Button, Paper, 
  Card, CardContent, Snackbar, Alert, useTheme, useMediaQuery 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Email as EmailIcon, Phone as PhoneIcon, LocationOn as LocationIcon } from '@mui/icons-material';
import { useLanguage } from '../components/LanguageContext';

// Replace this with your actual email
const CONTACT_EMAIL = "info@medwebreports.io";

const AnimatedCard = styled(motion(Card))(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

const ContactPage = () => {
  const { language, translations } = useLanguage();
  const t = translations[language];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const contactInfo = [
    { icon: <EmailIcon />, text: "info@medwebreports.io", label: t.email }
  ];

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh', padding: '2rem 0' }}>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom>
          {t.contactUs}
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          {t.contactUsSubtitle}
        </Typography>
        
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
              {/* Static message or contact info */}
              <Typography variant="body1" gutterBottom>
                {t.staticContactMessage}
              </Typography>
              <Box mt={2}>
                <Button href={`mailto:${contactInfo[0].text}`} variant="contained" color="primary" sx={{ margin: '0 10px' }}>
                  {t.sendEmail}
                </Button>
                
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              {contactInfo.map((info, index) => (
                <Grid item xs={12} key={index}>
                  <AnimatedCard
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3 }}
                  >
                    <CardContent sx={{ textAlign: 'center' }}>
                      {info.icon}
                      <Typography variant="h6" gutterBottom>
                        {info.label}
                      </Typography>
                      <Typography variant="body2">
                        {info.label === t.email ? <a href={`mailto:${info.text}`}>{info.text}</a> : info.text}
                      </Typography>
                    </CardContent>
                  </AnimatedCard>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};


export default ContactPage;