import { createWorker } from 'tesseract.js';
import { bloodTestParameters } from './data/bloodTestParameters';


const API_URL = process.env.REACT_APP_API_URL + '/api/analyze-blood-test-ocr';

export const performOCR = async (image, setProgress) => {
  try {
    const worker = await createWorker({
      logger: m => setProgress(m.status),
    });

    await worker.loadLanguage('eng+ita');
    await worker.initialize('eng+ita');
    const { data: { text } } = await worker.recognize(image, {
      tessedit_ocr_engine_mode: 2,
      tessedit_pageseg_mode: 6,
    });
    console.log('Extracted Text:', text);
    await worker.terminate();

    if (!text) {
      throw new Error('OCR failed to extract text from the image.');
    }

    const analysis = await processBloodTestOCR({ text });

    console.log('AI Analyzed Blood Test Results:', JSON.stringify(analysis, null, 2));
    return { text, analysis };
  } catch (error) {
    console.error('OCR Error:', error);
    throw error;
  }
};

export const processBloodTestOCR = async (ocrData) => {
  try {
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text: ocrData.text,
        language: 'en'
      }),
    });
    
    if (!response.ok) {
      throw new Error('Failed to process OCR data');
    }
    
    const data = await response.json();
    
    if (data.error) {
      throw new Error(data.error);
    }

    // Log the AI response to check its structure
    console.log('AI Response:', data);

    return data.analysis;
  } catch (error) {
    console.error('Error processing blood test OCR:', error);
    throw error;
  }
};

export const analyzeTestInputOCR = (aiAnalysis) => {
  // Parse the JSON if it's a string
  if (typeof aiAnalysis === 'string') {
    try {
      aiAnalysis = JSON.parse(aiAnalysis.trim().replace(/^```json/, '').replace(/```$/, ''));
    } catch (error) {
      console.error('Failed to parse AI analysis JSON:', error);
      throw new TypeError('aiAnalysis must be a valid JSON array');
    }
  }

  if (!Array.isArray(aiAnalysis)) {
    console.error('Expected an array for aiAnalysis, but received:', aiAnalysis);
    throw new TypeError('aiAnalysis must be an array');
  }

  const updatedTestResults = {};

  aiAnalysis.forEach(item => {
    if (item.test && item.value !== undefined) {
      const normalizedTest = item.test.toLowerCase().replace(/\s+/g, '');
      
      // Handle body measurements
      if (normalizedTest.includes('weight')) {
        updatedTestResults.weight = parseFloat(item.value);
      } else if (normalizedTest.includes('height')) {
        updatedTestResults.height = parseFloat(item.value);
      } else if (normalizedTest.includes('bmi')) {
        updatedTestResults.bmi = parseFloat(item.value);
      } else if (normalizedTest.includes('bloodpressure')) {
        updatedTestResults.bloodPressure = item.value;
      } else if (normalizedTest.includes('pulse')) {
        updatedTestResults.pulse = parseFloat(item.value);
      }
      // Handle patient history data
      else if (normalizedTest.includes('date')) {
        updatedTestResults.date = item.value;
      } else if (normalizedTest.includes('allerg')) {
        updatedTestResults.newAllergies = item.value;
      } else if (normalizedTest.includes('intolerance')) {
        updatedTestResults.newIntolerances = item.value;
      } else if (normalizedTest.includes('illness') || normalizedTest.includes('presentillness')) {
        updatedTestResults.historyOfPresentIllness = item.value;
      } else if (normalizedTest.includes('outcome') || normalizedTest.includes('visitoutcome')) {
        updatedTestResults.visitOutcomeNote = item.value;
      } else if (normalizedTest.includes('assessment') || normalizedTest.includes('plan')) {
        updatedTestResults.assessmentAndPlan = item.value;
      } else if (normalizedTest.includes('followup')) {
        updatedTestResults.followUp = item.value;
      } else if (normalizedTest.includes('therapy')) {
        updatedTestResults.therapy = item.value;
      }
    }
  });

  console.log('Mapped results from OCR:', updatedTestResults);
  
  return { updatedTestResults };
};

export const analyzeResults = (aiAnalysis) => {
  // Check if aiAnalysis is a string and needs parsing
  if (typeof aiAnalysis === 'string') {
    try {
      // Clean the string by removing any non-JSON content like backticks and extra newlines
      aiAnalysis = aiAnalysis.trim().replace(/^```json/, '').replace(/```$/, '');

      // Parse the cleaned JSON string
      aiAnalysis = JSON.parse(aiAnalysis);
    } catch (error) {
      console.error('Failed to parse AI analysis JSON:', error);
      throw new TypeError('aiAnalysis must be a valid JSON array');
    }
  }

  // Ensure aiAnalysis is an array after parsing
  if (!Array.isArray(aiAnalysis)) {
    console.error('Expected an array for aiAnalysis, but received:', aiAnalysis);
    throw new TypeError('aiAnalysis must be an array');
  }

  const updatedTestResults = {};
  const updatedUnits = {};

  const normalizeTestName = (name) => {
    return name.toLowerCase().replace(/[^a-z0-9]/g, '');
  };

  aiAnalysis.forEach(item => {
    const normalizedTestName = normalizeTestName(item.test);

    const matchingKey = Object.keys(bloodTestParameters).find(key => {
      const enLabel = normalizeTestName(bloodTestParameters[key].label.en);
      const itLabel = normalizeTestName(bloodTestParameters[key].label.it);
      return normalizedTestName === enLabel || normalizedTestName === itLabel ||
             enLabel.includes(normalizedTestName) || itLabel.includes(normalizedTestName);
    });

    if (matchingKey) {
      updatedTestResults[matchingKey] = formatValue(item.value);
      updatedUnits[matchingKey] = item.unit || bloodTestParameters[matchingKey].units[0];
    } else {
      console.warn(`No matching parameter found for test: ${item.test}`);
    }
  });

  console.log('Mapped results from OCR:', updatedTestResults);
  console.log('Mapped units from OCR:', updatedUnits);
  
  return { updatedTestResults, updatedUnits };
};

function formatValue(value) {
  if (typeof value === 'string' && value.startsWith('<')) {
    return value;
  }
  const numericValue = parseFloat(value);
  return isNaN(numericValue) ? '' : numericValue.toFixed(3).replace(/\.?0+$/, '');
}
