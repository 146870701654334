import React, { useState, useEffect, useCallback } from 'react';
import {
  Container, Typography, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, CircularProgress, IconButton, Dialog, DialogActions,
  DialogContent, DialogTitle, Button, TextField, Snackbar, Alert, FormControlLabel,
  Checkbox, Collapse, Box, Tabs, Tab, DialogContentText , ButtonGroup
} from '@mui/material';
import {
  Edit as EditIcon, Delete as DeleteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  GetApp as DownloadIcon
} from '@mui/icons-material';
import { initializeFirebase } from '../firebase';
import {
  collection, query, where, orderBy, getDocs, setDoc, doc, deleteDoc, addDoc
} from 'firebase/firestore';
import { decryptData, encryptData } from '../utils/encryption';
import { logError, logAccess } from '../utils/qms';
import { useLanguage } from '../components/LanguageContext';
import { useCreditOperations } from "../hooks/useCreditOperations";
import { performOCR, analyzeTestInputOCR } from '../utils/ocrUtils';
import { styled } from '@mui/material/styles';
import { useCredits } from '../components/CreditContext';
import ImageUpload from '../components/ImageUpload';
import { generatePatientHistoryReport, generatePatientHistoryReportPreview } from '../utils/reportGenerator';

const colors = {
  primary: '#1a237e',
  secondary: '#4caf50',
  accent: '#ff9800',
  background: '#f0f4f8',
  text: '#FFFFFF',
  textRows: '#000000'
};

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: '15px',
  overflow: 'auto',
  maxHeight: '600px',
  backgroundColor: colors.background,
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: `${colors.primary} !important`,
  '& th': {
    color: colors.text,
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    fontFamily: 'Arial, sans-serif',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme, component }) => ({
  fontFamily: 'Arial, sans-serif',
  ...(component === 'th' ? {
    color: colors.text,
    backgroundColor: colors.primary,
  } : {
    color: colors.textRows,
  }),
  '&.MuiTableCell-head': {
    backgroundColor: colors.primary,
    color: colors.text,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  bgcolor: colors.primary,
  color: 'white',
  '&:hover': { bgcolor: theme.palette.primary.dark },
}));

const PatientHistoryTable = () => {
  const [patientData, setPatientData] = useState({ measurements: [], history: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingEntry, setEditingEntry] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [entryToDelete, setEntryToDelete] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editValues, setEditValues] = useState({
    date: '',
    weight: '',
    height: '',
    bmi: '',
    bloodPressure: '',
    pulse: '',
    newAllergies: '',
    newIntolerances: '',
    historyOfPresentIllness: '',
    visitOutcomeNote: '',
    assessmentAndPlan: '',
    followUp: '',
    therapy: ''
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [userConsent, setUserConsent] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [editConsent, setEditConsent] = useState(false);

  const { auth, db } = initializeFirebase();
  const { language, translations } = useLanguage();

  const [image, setImage] = useState(null);
  const [ocrProgress, setOcrProgress] = useState('');
  const [ocrError, setOcrError] = useState('');
  const { handleCreditOperation } = useCreditOperations();
  const { userTier } = useCredits();
  const [reportDetails, setReportDetails] = useState(null);
  const [editReportOpen, setEditReportOpen] = useState(false);
  const [reportPreview, setReportPreview] = useState(null);

  const fetchPatientData = useCallback(async () => {
    if (!auth.currentUser) {
      setLoading(false);
      setError('Please log in to access patient data.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const historyQuery = query(
        collection(db, 'patientHistory'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('date', 'desc')
      );
      const measurementsQuery = query(
        collection(db, 'bodyMeasurements'),
        where('userId', '==', auth.currentUser.uid),
        orderBy('date', 'desc')
      );
      const [historySnapshot, measurementsSnapshot] = await Promise.all([
        getDocs(historyQuery),
        getDocs(measurementsQuery)
      ]);
      const historyData = historySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          date: data.date,
          newAllergies: decryptData(data.encryptedNewAllergies),
          newIntolerances: decryptData(data.encryptedNewIntolerances),
          historyOfPresentIllness: decryptData(data.encryptedHistoryOfPresentIllness),
          visitOutcomeNote: decryptData(data.encryptedVisitOutcomeNote),
          assessmentAndPlan: decryptData(data.encryptedAssessmentAndPlan),
          followUp: decryptData(data.encryptedFollowUp),
          therapy: decryptData(data.encryptedTherapy)
        };
      });
      const measurementsData = measurementsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          date: data.date,
          weight: decryptData(data.encryptedWeight),
          height: decryptData(data.encryptedHeight),
          bmi: decryptData(data.encryptedBMI),
          bloodPressure: decryptData(data.encryptedBloodPressure),
          pulse: decryptData(data.encryptedPulse)
        };
      });
      setPatientData({ history: historyData, measurements: measurementsData });
      logAccess('PatientHistoryTable', 'Fetched patient data');
    } catch (error) {
      console.error('Error fetching patient data:', error);
      logError('Error fetching patient data', error);
      setError('Failed to fetch patient data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [auth, db]);

  useEffect(() => {
    fetchPatientData();
  }, [fetchPatientData]);

  const handlePatientHistoryOCR = useCallback(async () => {
    if (!image) return;

    try {
      const creditCost = 5;
      const { success, error, result } = await handleCreditOperation(creditCost, async () => {
        setOcrProgress('Processing...');
        setOcrError('');
        return await performOCR(image, setOcrProgress);
      });

      if (!success) {
        throw new Error(error || 'OCR operation failed');
      }

      const { text, analysis } = result;
      const { updatedTestResults } = analyzeTestInputOCR(analysis);
      
      setEditValues(prevValues => ({
        ...prevValues,
        ...updatedTestResults,
      }));

      setOcrProgress('');
      setSnackbar({ open: true, message: translations[language].ocrCompleted, severity: 'success' });
    } catch (error) {
      console.error('OCR Error:', error);
      setOcrError(translations[language].errorProcessingImage);
      setOcrProgress('');
    }
  }, [image, handleCreditOperation, translations, language]);

  const handleEditReport = () => {
    setEditReportOpen(true);
  };

  const handleSaveReportDetails = (details) => {
    setReportDetails(details);
    setEditReportOpen(false);
    updateReportPreview(details);
  };

  const updateReportPreview = useCallback((details) => {
    const previewData = {
      patientDetails: details,
      bodyMeasurements: patientData.measurements,
      patientHistory: patientData.history,
      language
    };
    const preview = generatePatientHistoryReportPreview(previewData);
    setReportPreview(preview);
  }, [patientData, language]);

  const handleDownloadReport = useCallback(async () => {
    if (!reportDetails) {
      setSnackbar({ open: true, message: translations[language].pleaseEditReportFirst, severity: 'error' });
      return;
    }
    if (!userConsent) {
      setSnackbar({ open: true, message: translations[language].pleaseProvideConsent, severity: 'error' });
      return;
    }
    
    try {
      const { success, error } = await handleCreditOperation(5, async () => {
        const reportData = {
          patientDetails: reportDetails,
          bodyMeasurements: patientData.measurements,
          patientHistory: patientData.history,
          language
        };
        await generatePatientHistoryReport(reportData);
        logAccess('PatientHistoryTable', 'Patient History Report downloaded');
      });

      if (success) {
        setSnackbar({ open: true, message: translations[language].reportDownloaded, severity: 'success' });
      } else {
        throw new Error(error);
      }
    } catch (error) {
      console.error('Error downloading report:', error);
      logError('Error downloading report', error);
      setSnackbar({ open: true, message: `${translations[language].errorDownloading}: ${error.message}`, severity: 'error' });
    }
  }, [reportDetails, userConsent, handleCreditOperation, patientData, language, translations]);

  const handleAddNew = () => {
    setEditingEntry(null);
    setEditValues({
      date: new Date().toISOString().split('T')[0],
      weight: '', height: '', bmi: '', bloodPressure: '', pulse: '',
      newAllergies: '', newIntolerances: '',
      historyOfPresentIllness: '', visitOutcomeNote: '', assessmentAndPlan: '', followUp: '', therapy: ''
    });
    setEditDialogOpen(true);
    setUserConsent(false);
    setTabValue(0);
    logAccess('PatientHistoryTable', 'Creating new entry');
  };

  const handleEdit = (entry, type) => {
    setEditingEntry({ ...entry, type });
    setEditValues({ ...entry });
    setEditDialogOpen(true);
    setUserConsent(false);
    setTabValue(type === 'measurements' ? 1 : 0);
    logAccess('PatientHistoryTable', `Editing ${type} entry from ${entry.date}`);
  };

  const handleEditSave = async () => {
    if (!editConsent) {
      setSnackbar({ open: true, message: translations[language].provideConsentToUpdate, severity: 'error' });
      return;
    }
    
    const creditCost = 4; // Adjust this value as needed
    const { success, error } = await handleCreditOperation(creditCost, async () => {
      setLoading(true);
      setError(null);
      try {
        const isNewEntry = !editingEntry;
        const collectionName = tabValue === 1 ? 'bodyMeasurements' : 'patientHistory';
        const encryptedEntry = {
          date: editValues.date,
          userId: auth.currentUser.uid,
          ...(tabValue === 1 ? {
            encryptedWeight: encryptData(editValues.weight),
            encryptedHeight: encryptData(editValues.height),
            encryptedBMI: encryptData(editValues.bmi),
            encryptedBloodPressure: encryptData(editValues.bloodPressure),
            encryptedPulse: encryptData(editValues.pulse),
          } : {
            encryptedNewAllergies: encryptData(editValues.newAllergies),
            encryptedNewIntolerances: encryptData(editValues.newIntolerances),
            encryptedHistoryOfPresentIllness: encryptData(editValues.historyOfPresentIllness),
            encryptedVisitOutcomeNote: encryptData(editValues.visitOutcomeNote),
            encryptedAssessmentAndPlan: encryptData(editValues.assessmentAndPlan),
            encryptedFollowUp: encryptData(editValues.followUp),
            encryptedTherapy: encryptData(editValues.therapy)
          })
        };
  
        if (isNewEntry) {
          await addDoc(collection(db, collectionName), {
            ...encryptedEntry,
            createdAt: new Date()
          });
        } else {
          await setDoc(doc(db, collectionName, editingEntry.id), encryptedEntry, { merge: true });
        }
  
        await fetchPatientData();
        setEditDialogOpen(false);
        setEditingEntry(null);
        setSnackbar({ open: true, message: 'Entry saved successfully', severity: 'success' });
        logAccess('PatientHistoryTable', `${isNewEntry ? 'Added new' : 'Updated'} ${collectionName} entry from ${editValues.date}`);
      } catch (error) {
        console.error('Error saving patient data entry:', error);
        logError('Error saving patient data entry', error);
        throw error; // Rethrow the error to be caught by handleCreditOperation
      } finally {
        setLoading(false);
      }
    });
  
    if (!success) {
      setSnackbar({ open: true, message: error || 'Failed to save entry', severity: 'error' });
    }
  };

  const handleDelete = async () => {
    if (!userConsent) {
      setSnackbar({ open: true, message: 'Please provide consent to delete the entry.', severity: 'error' });
      return;
    }
    if (!entryToDelete) return;
    
    const creditCost = 4; // Adjust this value as needed
    const { success, error } = await handleCreditOperation(creditCost, async () => {
      setLoading(true);
      setError(null);
      try {
        const collectionName = entryToDelete.type === 'measurements' ? 'bodyMeasurements' : 'patientHistory';
        await deleteDoc(doc(db, collectionName, entryToDelete.id));
        await fetchPatientData();
        setDeleteConfirmOpen(false);
        setEntryToDelete(null);
        setSnackbar({ open: true, message: 'Entry deleted successfully', severity: 'success' });
        logAccess('PatientHistoryTable', `Deleted ${collectionName} entry from ${entryToDelete.date}`);
      } catch (error) {
        console.error('Error deleting patient data entry:', error);
        logError('Error deleting patient data entry', error);
        throw error; // Rethrow the error to be caught by handleCreditOperation
      } finally {
        setLoading(false);
      }
    });
  
    if (!success) {
      setSnackbar({ open: true, message: error || 'Failed to delete entry', severity: 'error' });
    }
  };

  const toggleExpandRow = (id) => {
    setExpandedRows(prev => ({ ...prev, [id]: !prev[id] }));
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  

  

  const isReportAvailable = patientData.history.length > 0 || patientData.measurements.length > 0;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom sx={{ color: colors.primary, fontWeight: 'bold', mt: 4 }}>
        {translations[language].patientHistory}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <ButtonGroup variant="contained" disabled={patientData.history.length === 0 && patientData.measurements.length === 0}>
          <Button
            onClick={handleAddNew}
            sx={{
              bgcolor: colors.primary,
              color: colors.text,
              '&:hover': { bgcolor: colors.accent },
            }}
          >
            {translations[language].addNewEntry}
          </Button>
          <Button
            onClick={handleEditReport}
            startIcon={<EditIcon />}
            sx={{
              bgcolor: colors.secondary,
              color: colors.text,
              '&:hover': { bgcolor: colors.accent },
            }}
          >
            {translations[language].editReport}
          </Button>
          <Button
            onClick={handleDownloadReport}
            startIcon={<DownloadIcon />}
            disabled={!reportDetails || !userConsent}
            sx={{
              bgcolor: colors.accent,
              color: colors.text,
              '&:hover': { bgcolor: colors.secondary },
            }}
          >
            {translations[language].downloadReport}
          </Button>
        </ButtonGroup>
      </Box>

      {reportPreview && (
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            {translations[language].reportPreview}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: reportPreview }} />
        </Paper>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={userConsent}
            onChange={(e) => setUserConsent(e.target.checked)}
            name="userConsent"
          />
        }
        label={translations[language].consentToDownload}
      />
      
      {/* Body Measurements Table */}
      <Typography variant="h5" gutterBottom style={{ color: colors.primary, marginTop: '2rem' }}>
        {translations[language].bodyMeasurements}
      </Typography>
      <StyledTableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>{translations[language].date}</StyledTableCell>
              <StyledTableCell>{translations[language].weight}</StyledTableCell>
              <StyledTableCell>{translations[language].height}</StyledTableCell>
              <StyledTableCell>{translations[language].bmi}</StyledTableCell>
              <StyledTableCell>{translations[language].bloodPressure}</StyledTableCell>
              <StyledTableCell>{translations[language].pulse}</StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {patientData.measurements.map((entry) => (
              <TableRow key={entry.id}>
                <TableCell>{entry.date}</TableCell>
                <TableCell>{entry.weight}</TableCell>
                <TableCell>{entry.height}</TableCell>
                <TableCell>{entry.bmi}</TableCell>
                <TableCell>{entry.bloodPressure}</TableCell>
                <TableCell>{entry.pulse}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(entry, 'measurements')} size="small">
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => {
                    setEntryToDelete({ ...entry, type: 'measurements' });
                    setDeleteConfirmOpen(true);
                    setUserConsent(false);
                  }} size="small">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
  
      {/* Patient History Table */}
      <Typography variant="h5" gutterBottom style={{ color: colors.primary, marginTop: '2rem' }}>
        {translations[language].patientHistory}
      </Typography>
      <StyledTableContainer component={Paper}>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>{translations[language].date}</StyledTableCell>
              <StyledTableCell>{translations[language].newAllergies}</StyledTableCell>
              <StyledTableCell>{translations[language].newIntolerances}</StyledTableCell>
              <StyledTableCell>{translations[language].historyOfPresentIllness}</StyledTableCell>
              <StyledTableCell>{translations[language].visitOutcomeNote}</StyledTableCell>
              <StyledTableCell>{translations[language].assessmentAndPlan}</StyledTableCell>
              <StyledTableCell>{translations[language].followUp}</StyledTableCell>
              <StyledTableCell>{translations[language].therapy}</StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {patientData.history.map((entry) => (
              <React.Fragment key={entry.id}>
                <TableRow>
                  <TableCell>{entry.date}</TableCell>
                  <TableCell>{entry.newAllergies}</TableCell>
                  <TableCell>{entry.newIntolerances}</TableCell>
                  <TableCell>{entry.historyOfPresentIllness}</TableCell>
                  <TableCell>{entry.visitOutcomeNote}</TableCell>
                  <TableCell>{entry.assessmentAndPlan}</TableCell>
                  <TableCell>{entry.followUp}</TableCell>
                  <TableCell>{entry.therapy}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(entry, 'history')} color="primary">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                      setEntryToDelete({ ...entry, type: 'history' });
                      setDeleteConfirmOpen(true);
                      setUserConsent(false);
                    }} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={() => toggleExpandRow(entry.id)}>
                      {expandedRows[entry.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={expandedRows[entry.id]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          {translations[language].fullInformation}
                        </Typography>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>{translations[language].historyOfPresentIllness}</TableCell>
                              <TableCell>{entry.historyOfPresentIllness}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{translations[language].assessmentAndPlan}</TableCell>
                              <TableCell>{entry.assessmentAndPlan}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{translations[language].followUp}</TableCell>
                              <TableCell>{entry.followUp}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{translations[language].therapy}</TableCell>
                              <TableCell>{entry.therapy}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      {/* Add/Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {editingEntry ? translations[language].editEntry : translations[language].addNewEntry}
        </DialogTitle>
        <DialogContent>
          <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)}>
            <Tab label={translations[language].patientHistory} />
            <Tab label={translations[language].bodyMeasurement} />
          </Tabs>
          <Box sx={{ border: '2px solid #2196f3', borderRadius: '4px', padding: '1rem', marginTop: '1rem' }}>
            <TextField
              label={translations[language].date}
              type="date"
              value={editValues.date}
              onChange={(e) => setEditValues({ ...editValues, date: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            {tabValue === 0 && (
              <>
                <Typography variant="h6" gutterBottom>
                  {translations[language].medicalHistoryDetails}
                </Typography>
                <TextField
                  label={translations[language].newAllergies}
                  value={editValues.newAllergies}
                  onChange={(e) => setEditValues({ ...editValues, newAllergies: e.target.value })}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label={translations[language].newIntolerances}
                  value={editValues.newIntolerances}
                  onChange={(e) => setEditValues({ ...editValues, newIntolerances: e.target.value })}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label={translations[language].historyOfPresentIllness}
                  value={editValues.historyOfPresentIllness}
                  onChange={(e) => setEditValues({ ...editValues, historyOfPresentIllness: e.target.value })}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />
                <TextField
                  label={translations[language].visitOutcomeNote}
                  value={editValues.visitOutcomeNote}
                  onChange={(e) => setEditValues({ ...editValues, visitOutcomeNote: e.target.value })}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />
                <TextField
                  label={translations[language].assessmentAndPlan}
                  value={editValues.assessmentAndPlan}
                  onChange={(e) => setEditValues({ ...editValues, assessmentAndPlan: e.target.value })}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />
                <TextField
                  label={translations[language].followUp}
                  value={editValues.followUp}
                  onChange={(e) => setEditValues({ ...editValues, followUp: e.target.value })}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={2}
                />
                <TextField
                  label={translations[language].therapy}
                  value={editValues.therapy}
                  onChange={(e) => setEditValues({ ...editValues, therapy: e.target.value })}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                />
              </>
            )}
            {tabValue === 1 && (
              <>
                <Typography variant="h6" gutterBottom>
                  {translations[language].bodyMeasurementDetails}
                </Typography>
                <TextField
                  label={translations[language].weight}
                  value={editValues.weight}
                  onChange={(e) => setEditValues({ ...editValues, weight: e.target.value })}
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <TextField
                  label={translations[language].height}
                  value={editValues.height}
                  onChange={(e) => setEditValues({ ...editValues, height: e.target.value })}
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <TextField
                  label={translations[language].bmi}
                  value={editValues.bmi}
                  onChange={(e) => setEditValues({ ...editValues, bmi: e.target.value })}
                  fullWidth
                  margin="normal"
                  type="number"
                />
                <TextField
                  label={translations[language].bloodPressure}
                  value={editValues.bloodPressure}
                  onChange={(e) => setEditValues({ ...editValues, bloodPressure: e.target.value })}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label={translations[language].pulse}
                  value={editValues.pulse}
                  onChange={(e) => setEditValues({ ...editValues, pulse: e.target.value })}
                  fullWidth
                  margin="normal"
                  type="number"
                />
              </>
            )}
            <FormControlLabel
                control={
                    <Checkbox
                    checked={editConsent}
                    onChange={(e) => setEditConsent(e.target.checked)}
                    name="editConsent"
                    />
                }
                label={translations[language].updateConsentLabel}
/>
          </Box>
          {/* OCR Upload and Process Section */}
          <Box sx={{ mt: 2 }}>
          {(userTier === 'Tier 1' || userTier === 'Tier 2') && (
            <ImageUpload setImage={setImage} setError={setError} />
          )}
            
            {image && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePatientHistoryOCR}
                sx={{ mt: 2 }}
                disabled={ocrProgress !== ''}
              >
                {ocrProgress ? <CircularProgress size={24} /> : translations[language].performOCR}
              </Button>
            )}
            {ocrProgress && <Typography variant="body2">{ocrProgress}</Typography>}
            {ocrError && <Alert severity="error" sx={{ mt: 2 }}>{ocrError}</Alert>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>{translations[language].cancel}</Button>
          <Button onClick={handleEditSave} color="primary" disabled={!editConsent}>
            {translations[language].save}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translations[language].confirmDeletion}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translations[language].deleteConfirmation}
          </DialogContentText>
          <FormControlLabel
            control={<Checkbox checked={userConsent} onChange={(e) => setUserConsent(e.target.checked)} name="deleteConsent" />}
            label={translations[language].deleteConsentLabel}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>{translations[language].cancel}</Button>
          <Button onClick={handleDelete} color="secondary">{translations[language].delete}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editReportOpen} onClose={() => setEditReportOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{translations[language].editReportDetails}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label={translations[language].fullName}
            value={reportDetails?.fullName || ''}
            onChange={(e) => setReportDetails(prev => ({ ...prev, fullName: e.target.value }))}
          />
          <TextField
            fullWidth
            margin="normal"
            label={translations[language].dateOfBirth}
            type="date"
            value={reportDetails?.dateOfBirth || ''}
            onChange={(e) => setReportDetails(prev => ({ ...prev, dateOfBirth: e.target.value }))}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            fullWidth
            margin="normal"
            label={translations[language].gender}
            value={reportDetails?.gender || ''}
            onChange={(e) => setReportDetails(prev => ({ ...prev, gender: e.target.value }))}
          />
          <TextField
            fullWidth
            margin="normal"
            label={translations[language].hospitalName}
            value={reportDetails?.hospitalName || ''}
            onChange={(e) => setReportDetails(prev => ({ ...prev, hospitalName: e.target.value }))}
          />
          <TextField
            fullWidth
            margin="normal"
            label={translations[language].department}
            value={reportDetails?.department || ''}
            onChange={(e) => setReportDetails(prev => ({ ...prev, department: e.target.value }))}
          />
          <TextField
            fullWidth
            margin="normal"
            label={translations[language].examType}
            value={reportDetails?.examType || ''}
            onChange={(e) => setReportDetails(prev => ({ ...prev, examType: e.target.value }))}
          />
          <TextField
            fullWidth
            margin="normal"
            label={translations[language].examDate}
            type="date"
            value={reportDetails?.examDate || ''}
            onChange={(e) => setReportDetails(prev => ({ ...prev, examDate: e.target.value }))}
            InputLabelProps={{ shrink: true }}
          />

          <FormControlLabel
                control={
                  <Checkbox
                    checked={userConsent}
                    onChange={(e) => setUserConsent(e.target.checked)}
                    name="editConsent"
                  />
                }
                label={translations[language].updateBloodTestConsent}
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditReportOpen(false)}>{translations[language].cancel}</Button>
          <Button onClick={() => handleSaveReportDetails(reportDetails)} color="primary">
            {translations[language].save}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PatientHistoryTable;
