import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLanguage } from '../components/LanguageContext';

const FAQ = () => {
  const { language, translations } = useLanguage();

  const faqData = [
    {
      question: translations[language].faqBloodTestQuestion,
      answer: (
        <ol>
          <li>{translations[language].faqBloodTestStep1}</li>
          <li>{translations[language].faqBloodTestStep2}</li>
          <li>{translations[language].faqBloodTestStep3}</li>
          <li>{translations[language].faqBloodTestStep4}</li>
        </ol>
      )
    },
    {
      question: translations[language].faqPatientHistoryQuestion,
      answer: (
        <ol>
          <li>{translations[language].faqPatientHistoryStep1}</li>
          <li>{translations[language].faqPatientHistoryStep2}</li>
          <li>{translations[language].faqPatientHistoryStep3}</li>
          <li>{translations[language].faqPatientHistoryStep4}</li>
          <li>{translations[language].faqPatientHistoryStep5}</li>
        </ol>
      )
    },
    {
      question: translations[language].faqAIAnalysisQuestion,
      answer: (
        <ol>
          <li>{translations[language].faqAIAnalysisStep1}</li>
          <li>{translations[language].faqAIAnalysisStep2}</li>
          <li>{translations[language].faqAIAnalysisStep3}</li>
          <li>{translations[language].faqAIAnalysisStep4}</li>
        </ol>
      )
    },
    {
      question: translations[language].faqLanguageQuestion,
      answer: translations[language].faqLanguageAnswer
    },
    {
      question: translations[language].faqBuyCreditsQuestion,
      answer: (
        <ol>
          <li>{translations[language].faqBuyCreditsStep1}</li>
          <li>{translations[language].faqBuyCreditsStep2}</li>
          <li>{translations[language].faqBuyCreditsStep3}</li>
        </ol>
      )
    }
  ];

  return (
    <Box sx={{ my: 4 }}>
      
      {faqData.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;