import React, { createContext, useState, useEffect } from 'react';
import { initializeFirebase } from '../firebase';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { auth } = initializeFirebase();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        // Set user data
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
        });
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};