import React from 'react';
import { Box, Container, Typography, Link as MuiLink } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useLanguage } from './LanguageContext';
import YBugFeedback from './YBugFeedback';

const Footer = () => {
  const { language, toggleLanguage, translations } = useLanguage();
  const t = translations[language];

  const colors = {
    primary: '#1a237e',
    secondary: '#4caf50',
    accent: '#ff9800',
    text: '#FFFFFF',
  };

  const linkStyle = {
    color: colors.text,
    textDecoration: 'none',
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: '14px',
    mx: 2,
    '&:hover': {
      color: colors.accent,
    },
  };

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: colors.primary,
        color: colors.text,
        py: 3,
        mt: 'auto',
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Typography variant="body2" sx={{ mb: 1 }}>
              © {new Date().getFullYear()} Med Web Reports
            </Typography>
            <Box>
              <MuiLink component={RouterLink} to="/privacy-policy" sx={linkStyle}>
                {t.privacyPolicy}
              </MuiLink>
              <MuiLink component={RouterLink} to="/terms-and-conditions" sx={linkStyle}>
                {t.termsOfService}
              </MuiLink>
              <MuiLink component={RouterLink} to="/contact" sx={linkStyle}>
                {t.contact}
              </MuiLink>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <YBugFeedback />
            <MuiLink
              component="button"
              onClick={toggleLanguage}
              sx={{
                ...linkStyle,
                bgcolor: 'transparent',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              {language === 'en' ? 'IT' : 'EN'}
            </MuiLink>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;