import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Alert,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { initializeFirebase } from '../firebase';
import { encryptData } from '../utils/encryption';
import { logAccess, logError } from '../utils/qms';
import { useLanguage } from './LanguageContext';

const Register = () => {
  const [firstName, setFirstName] = useState(''); // Optional first name
  const [lastName, setLastName] = useState('');   // Optional last name
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [gdprConsent, setGdprConsent] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const { auth, db } = initializeFirebase();
  const { language, translations } = useLanguage();

  // Lockout logic (if you have it in your code)
  const [registrationAttempts, setRegistrationAttempts] = useState(() => {
    return parseInt(localStorage.getItem('registrationAttempts') || '0');
  });
  const [lockoutUntil, setLockoutUntil] = useState(() => {
    const storedLockoutUntil = localStorage.getItem('lockoutUntil');
    return parseInt(storedLockoutUntil || '0');
  });
  const [isLockedOut, setIsLockedOut] = useState(false);

  const MAX_ATTEMPTS = 5;
  const LOCKOUT_TIME = 15 * 60 * 1000; // 15 minutes

  useEffect(() => {
    localStorage.setItem('registrationAttempts', registrationAttempts.toString());
    localStorage.setItem('lockoutUntil', lockoutUntil.toString());
  }, [registrationAttempts, lockoutUntil]);

  useEffect(() => {
    const checkLockout = () => {
      const now = Date.now();
      const storedLockoutUntil = parseInt(localStorage.getItem('lockoutUntil') || '0');
      const isLocked = now < storedLockoutUntil;
      setIsLockedOut(isLocked);
      setLockoutUntil(storedLockoutUntil);

      if (!isLocked && storedLockoutUntil !== 0) {
        localStorage.setItem('lockoutUntil', '0');
        localStorage.setItem('registrationAttempts', '0');
        setRegistrationAttempts(0);
      }
    };

    checkLockout();
  }, []);

  const sanitizeInput = (input) => {
    // Remove potentially dangerous characters
    return input.replace(/[&<>"']/g, function (m) {
      return {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
      }[m];
    });
  };

  const isPasswordStrong = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasNonalphas = /\W/.test(password);
    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasNonalphas
    );
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Increment attempts for each registration try
    const newAttempts = registrationAttempts + 1;
    setRegistrationAttempts(newAttempts);
    localStorage.setItem('registrationAttempts', newAttempts.toString());

    if (isLockedOut || Date.now() < lockoutUntil) {
      setError(translations[language].tooManyAttempts);
      return;
    }

    if (newAttempts >= MAX_ATTEMPTS) {
      const newLockoutTime = Date.now() + LOCKOUT_TIME;
      setLockoutUntil(newLockoutTime);
      localStorage.setItem('lockoutUntil', newLockoutTime.toString());
      setIsLockedOut(true);
      setError(translations[language].tooManyAttempts);
      return;
    }

    // Sanitize inputs
    const sanitizedFirstName = sanitizeInput(firstName.trim());
    const sanitizedLastName = sanitizeInput(lastName.trim());

    // Validate email
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setError(translations[language].invalidEmail);
      return;
    }

    // Validate password
    if (!isPasswordStrong(password)) {
      setError(translations[language].weakPassword);
      return;
    }

    // Check password confirmation
    if (password !== confirmPassword) {
      setError(translations[language].passwordsMismatch);
      return;
    }

    // Check GDPR consent
    if (!gdprConsent) {
      setError(translations[language].pleaseConsent);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        sanitizeInput(email),
        password
      );
      const user = userCredential.user;

      // Construct displayName
      const displayName = `${sanitizedFirstName} ${sanitizedLastName}`.trim();

      // Create userData object, including email and optional names
      const userData = {
        email: user.email,
        uid: user.uid,
        registrationDate: new Date().toISOString(),
        tier: 'free',
        credits: 20,
        isAdmin: false,
        ...(sanitizedFirstName && { firstName: sanitizedFirstName }),
        ...(sanitizedLastName && { lastName: sanitizedLastName }),
        ...(displayName && { displayName }),
      };

      // Store user data in Firestore
      await setDoc(doc(db, 'users', user.uid), userData);

      // Encrypt and store user data locally
      const encryptedUserData = encryptData(JSON.stringify(userData));
      localStorage.setItem('encryptedUserData', encryptedUserData);

      // Reset registration attempts on successful registration
      setRegistrationAttempts(0);
      setLockoutUntil(0);

      logAccess('Registration', 'User registered successfully');
      setSuccess(translations[language].registrationSuccessful);
      setTimeout(
        () =>
          navigate('/login', {
            state: { message: translations[language].registrationSuccessful },
          }),
        2000
      );
    } catch (error) {
      logError('Registration', 'Failed registration attempt', error);
      setError(`${translations[language].registrationFailed} ${error.message}`);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Extract first name and last name from displayName, if available
      const displayName = user.displayName || '';
      const nameParts = displayName.split(' ');
      const firstName = nameParts[0] || '';
      const lastName = nameParts.slice(1).join(' ') || '';

      const sanitizedFirstName = sanitizeInput(firstName.trim());
      const sanitizedLastName = sanitizeInput(lastName.trim());

      // Create userData object, including email and optional names
      const userData = {
        email: user.email,
        uid: user.uid,
        registrationDate: new Date().toISOString(),
        tier: 'free',
        credits: 20,
        googleSignIn: true,
        isAdmin: false,
        ...(sanitizedFirstName && { firstName: sanitizedFirstName }),
        ...(sanitizedLastName && { lastName: sanitizedLastName }),
        ...(displayName && { displayName: sanitizeInput(displayName) }),
      };

      // Store user data in Firestore
      await setDoc(doc(db, 'users', user.uid), userData);

      // Encrypt and store user data locally
      const encryptedUserData = encryptData(JSON.stringify(userData));
      localStorage.setItem('encryptedUserData', encryptedUserData);

      logAccess('Registration', 'User registered with Google successfully');
      setSuccess(translations[language].registrationSuccessful);
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      logError('Registration', 'Google Sign-In failed', error);
      setError(`${translations[language].registrationFailed} ${error.message}`);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '2rem', marginTop: '2rem' }}>
        <Typography component="h1" variant="h5">
          {translations[language].register}
        </Typography>
        {(isLockedOut || error) && (
          <Alert severity="error" style={{ marginTop: '1rem' }}>
            {isLockedOut ? translations[language].tooManyAttempts : error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" style={{ marginTop: '1rem' }}>
            {success}
          </Alert>
        )}
        <form onSubmit={handleRegister} style={{ marginTop: '1rem' }}>
          {/* First Name Field (Optional) */}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="firstName"
            label={translations[language].firstName}
            name="firstName"
            autoComplete="given-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          {/* Last Name Field (Optional) */}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="lastName"
            label={translations[language].lastName}
            name="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          {/* Email Field */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={translations[language].email}
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {/* Password Field */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={translations[language].password}
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* Confirm Password Field */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label={translations[language].confirmPassword}
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Typography variant="caption" display="block" gutterBottom>
            {translations[language].passwordRequirements}
          </Typography>
          {/* GDPR Consent Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                value="gdprConsent"
                color="primary"
                checked={gdprConsent}
                onChange={(e) => setGdprConsent(e.target.checked)}
              />
            }
            label={translations[language].gdprConsent}
          />
          {/* Submit Button */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: '1rem' }}
            disabled={!gdprConsent}
          >
            {translations[language].register}
          </Button>
        </form>
        {/* Google Sign-In Button */}
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          style={{ marginTop: '1rem' }}
          onClick={handleGoogleSignIn}
        >
          {translations[language].registerWithGoogle}
        </Button>
      </Paper>
    </Container>
  );
};

export default Register;
