import React from 'react';
import { Typography, Box, Paper } from '@mui/material';
import { useLanguage } from '../components/LanguageContext';

const ResultsSummary = ({ summary }) => {
  const { language, translations } = useLanguage();

  return (
    <Paper elevation={3} style={{ padding: '1rem', marginTop: '1rem' }}>
      <Typography variant="h5" gutterBottom>
        {translations[language].summary}
      </Typography>
      <Typography paragraph>{summary}</Typography>
      <Box mt={2}>
        <Typography variant="caption">
          {translations[language].summaryNote}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ResultsSummary;