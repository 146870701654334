import CryptoJS from 'crypto-js';
import { logError, logAccess } from './qms';

const SECRET_KEY = process.env.REACT_APP_ENCRYPTION_KEY || 'defaultSecretKey123!@#';

export const encryptData = (data) => {
  if (!data) return null;
  try {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
    logAccess('Encryption', 'Data encrypted successfully');
    return encryptedData;
  } catch (error) {
    logError('Encryption error', error);
    return null;
  }
};

export const decryptData = (encryptedData) => {
  if (!encryptedData) return null;
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    logAccess('Decryption', 'Data decrypted successfully');
    return decryptedData;
  } catch (error) {
    logError('Decryption error', error);
    return null;
  }
};

