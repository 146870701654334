import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLanguage } from '../LanguageContext';

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: '20px',
  padding: '10px 20px',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 7px 2px rgba(0, 0, 0, .2)',
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '15px',
  boxShadow: '0 5px 15px rgba(0, 0, 0, .1)',
  backgroundColor: '#f0f4f8',
}));

const EditReportDetails = ({ onSave, initialData }) => {
  const { language, translations } = useLanguage();
  const [reportDetails, setReportDetails] = useState(initialData || {
    fullName: '',
    dateOfBirth: '',
    gender: '',
    hospitalName: '',
    department: '',
    examType: '',
    examDate: ''
  });

  const handleChange = (e) => {
    setReportDetails({ ...reportDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(reportDetails);
  };

  return (
    <StyledBox component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom sx={{ color: '#1a237e', fontWeight: 'bold' }}>
        {translations[language].editReportDetails}
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        name="fullName"
        label={translations[language].fullName}
        value={reportDetails.fullName}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        name="dateOfBirth"
        label={translations[language].dateOfBirth}
        type="date"
        value={reportDetails.dateOfBirth}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        fullWidth
        margin="normal"
        name="gender"
        label={translations[language].gender}
        value={reportDetails.gender}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        name="hospitalName"
        label={translations[language].hospitalName}
        value={reportDetails.hospitalName}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        name="department"
        label={translations[language].department}
        value={reportDetails.department}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        name="examType"
        label={translations[language].examType}
        value={reportDetails.examType}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        margin="normal"
        name="examDate"
        label={translations[language].examDate}
        type="date"
        value={reportDetails.examDate}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
      <StyledButton 
        type="submit" 
        variant="contained" 
        sx={{ 
          mt: 2,
          bgcolor: '#1a237e',
          color: 'white',
          '&:hover': { bgcolor: '#303f9f' }
        }}
      >
        {translations[language].saveDetails}
      </StyledButton>
    </StyledBox>
  );
};

export default EditReportDetails;