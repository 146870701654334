import React, { createContext, useState, useEffect, useContext } from 'react';
import { initializeFirebase } from '../firebase';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';  // Import updateDoc to update Firestore
import { onAuthStateChanged } from 'firebase/auth';
import { logError } from '../utils/qms';

export const CreditContext = createContext();

export const CreditProvider = ({ children }) => {
  const [credits, setCredits] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userTier, setUserTier] = useState('free');
  const [loading, setLoading] = useState(true);
  const { auth, db } = initializeFirebase();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const unsubscribeSnapshot = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            const userData = doc.data();
            setIsAdmin(userData.isAdmin || false);
            setUserTier(userData.tier || 'free');
            setCredits(userData.isAdmin ? 'Unlimited' : (userData.credits || 0));
          } else {
            setIsAdmin(false);
            setUserTier('free');
            setCredits(0);
          }
          setLoading(false);
        }, (error) => {
          logError('CreditContext', 'Error fetching user data', error);
          setLoading(false);
        });

        return () => unsubscribeSnapshot();
      } else {
        setIsAdmin(false);
        setUserTier('free');
        setCredits(0);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, db]);

  const checkCredits = (amount) => {
    return isAdmin || credits === 'Unlimited' || (typeof credits === 'number' && credits >= amount);
  };

  const updateCredits = async (amount) => {
    if (typeof credits === 'number') {
      const newCredits = credits + amount;

      // Update credits in Firestore
      try {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(userDocRef, { credits: newCredits });

        // Update the credits in the local state
        setCredits(newCredits);
      } catch (error) {
        logError('CreditContext', 'Error updating credits', error);
      }
    }
  };

  return (
    <CreditContext.Provider value={{ credits, setCredits, checkCredits, updateCredits, userTier, setUserTier, isAdmin, loading }}>
      {children}
    </CreditContext.Provider>
  );
};

export const useCredits = () => useContext(CreditContext);
