import React from 'react';
import { 
  Typography, Container, Grid, Card, CardContent, 
  List, ListItem, ListItemIcon, ListItemText, Box
} from '@mui/material';
import { CheckCircleOutline as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { useLanguage } from '../components/LanguageContext';

const PremiumFeaturesComparison = () => {
  const { language, translations } = useLanguage();
  const t = translations[language];

  const colors = {
    primary: '#1a237e',
    secondary: '#4caf50',
    accent: '#ff9800',
    background: '#f0f4f8',
    text: '#333333',
  };

  const commonStyles = {
    fontFamily: 'Arial, sans-serif',
  };

  const features = [
    { name: t.ocrFeatureComp, description: t.ocrDescriptionComp, free: false, premium: true },
    { name: t.creditsComp, description: t.creditsDescriptionComp, free: '20 ' + t.creditsComp, premium: t.moreCreditsTiersComp },
    { name: t.addModifyDeleteComp, description: t.addModifyDeleteDescriptionComp, free: true, premium: true },
    { name: t.aiInsightsComp, description: t.aiInsightsDescriptionComp, free: true, premium: true },
  ];

  return (
    <Box sx={{ bgcolor: colors.background, py: 8 }}>
      <Container maxWidth="lg">
        <Typography variant="h3" gutterBottom sx={{ ...commonStyles, color: colors.primary, textAlign: 'center', mb: 6 }}>
          {t.compareFeaturesComp} 

        </Typography>
        <Typography variant="h5" gutterBottom sx={{ ...commonStyles, color: colors.primary, textAlign: 'center', mb: 6 }}>
          {t.premiumExplanation} 
        </Typography>
        
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', bgcolor: colors.primary, color: 'white' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                  {t.freeVersionComp}
                </Typography>
                <List>
                  {features.map((feature, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        {feature.free ? (
                          <CheckIcon sx={{ color: colors.secondary }} />
                        ) : (
                          <CloseIcon sx={{ color: colors.accent }} />
                        )}
                      </ListItemIcon>
                      <ListItemText 
                        primary={feature.name} 
                        secondary={
                          <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                            {typeof feature.free === 'string' ? feature.free : feature.description}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', bgcolor: colors.accent, color: colors.text }}>
              <CardContent>
                <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                  {t.premiumVersionComp}
                </Typography>
                <List>
                  {features.map((feature, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CheckIcon sx={{ color: colors.primary }} />
                      </ListItemIcon>
                      <ListItemText 
                        primary={feature.name} 
                        secondary={
                          <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.7)' }}>
                            {typeof feature.premium === 'string' ? feature.premium : feature.description}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PremiumFeaturesComparison;