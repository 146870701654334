import React from 'react';
import { 
  Typography, Container, Button, Box, Grid, Paper, Card, CardContent, 
  List, ListItem, ListItemIcon, ListItemText 
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useLanguage } from '../components/LanguageContext';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  Lock as LockIcon,
  LocalHospital as LocalHospitalIcon,
  CreditScore as CreditScoreIcon,
  CreditCard as CreditCardIcon, // Add this line
} from '@mui/icons-material';
import logo from '../assets/logo.jpeg';
import DisclaimerAlert from '../components/DisclaimerAlert'
import PremiumFeaturesComparison from '../components/PremiumFeaturesComparison';
import FAQ from '../components/Faq';

const Home = () => {
  const { language, translations } = useLanguage();

  const colors = {
    primary: '#1a237e',
    secondary: '#4caf50',
    accent: '#ff9800',
    background: '#f0f4f8',
    text: '#333333',
  };

  const commonStyles = {
    fontFamily: 'Arial, sans-serif',
  };

  const buttonStyle = {
    ...commonStyles,
    fontWeight: 'bold',
    fontSize: '16px',
    textTransform: 'none',
    px: 4,
    py: 2,
  };

  const sectionStyle = {
    py: 8,
  };

  return (
    <Box sx={{ bgcolor: colors.background, minHeight: 'calc(100vh - 100px)' }}>
      {/* Hero Section */}
      <Box sx={{ bgcolor: colors.primary, color: 'white', py: 12 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" gutterBottom sx={{ ...commonStyles, fontWeight: 'bold' }}>
                {translations[language].welcome}
              </Typography>
              <Typography variant="h5" paragraph sx={{ ...commonStyles, mb: 4 }}>
                {translations[language].improvedHomeDescription}
              </Typography>
              <Button
                component={Link}
                to="/test-input"
                variant="contained"
                size="large"
                sx={{
                  ...buttonStyle,
                  bgcolor: colors.accent,
                  color: colors.text,
                  '&:hover': { bgcolor: colors.secondary },
                }}
              >
                {translations[language].startInterpreting}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box 
                component="img" 
                src={logo}
                alt="Blood Test Interpreter Logo" 
                sx={{ width: '100%', maxWidth: 400, height: 'auto', display: 'block', mx: 'auto' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

     {/* Features Section */}
     <Container maxWidth="lg" sx={sectionStyle}>
        <Typography variant="h3" gutterBottom sx={{ ...commonStyles, color: colors.primary, textAlign: 'center', mb: 6 }}>
          {translations[language].keyFeatures}
        </Typography>
        <Grid container spacing={4}>
          {[
            { icon: <CheckCircleOutlineIcon />, title: translations[language].accurateInterpretation, description: translations[language].accurateInterpretationDesc },
            { icon: <PeopleIcon />, title: translations[language].userFriendly, description: translations[language].userFriendlyDesc },
            { icon: <TrendingUpIcon />, title: translations[language].trendAnalysis, description: translations[language].trendAnalysisDesc },
            { icon: <LockIcon />, title: translations[language].dataSecurity, description: translations[language].dataSecurityDesc },
            { icon: <LocalHospitalIcon />, title: translations[language].medicalGuidance, description: translations[language].medicalGuidanceDesc },
            { icon: <CreditScoreIcon />, title: translations[language].freeCredits, description: translations[language].freeCreditsDesc },
            { icon: <CreditCardIcon />, title: translations[language].creditSystem, description: translations[language].creditSystemDesc },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                <Box sx={{ m: 2, color: colors.primary }}>{feature.icon}</Box>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center' }}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* How It Works Section */}
      <Box sx={{ bgcolor: 'white', ...sectionStyle }}>
        <Container maxWidth="lg">
          <Typography variant="h3" gutterBottom sx={{ ...commonStyles, color: colors.primary, textAlign: 'center', mb: 6 }}>
            {translations[language].howItWorks}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <List>
                {[
                  translations[language].improvedStep1,
                  translations[language].improvedStep2,
                  translations[language].improvedStep3,
                  translations[language].improvedStep4,
                  translations[language].improvedStep5,
                ].map((step, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon sx={{ color: colors.secondary }} />
                    </ListItemIcon>
                    <ListItemText primary={step} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2, bgcolor: colors.background }}>
              <DisclaimerAlert />
              </Paper>
            </Grid>
          </Grid>
          
        </Container>
      </Box>

      {/* FAQ Section*/}
      <Box sx={{ bgcolor: 'white', ...sectionStyle }}>
        <Container maxWidth="lg">
          <Typography variant="h3" gutterBottom sx={{ ...commonStyles, color: colors.primary, textAlign: 'center', mb: 6 }}>
            {translations[language].faqTitle}
          </Typography>
          <FAQ />
        </Container>
      </Box>

      {/* Credit System Section */}
      <Container maxWidth="lg" sx={sectionStyle}>
        <Typography variant="h3" gutterBottom sx={{ ...commonStyles, color: colors.primary, textAlign: 'center', mb: 6 }}>
          {translations[language].creditSystem}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ color: colors.secondary }}>
              {translations[language].howCreditsWork}
            </Typography>
            <Typography paragraph>
              {translations[language].improvedCreditsExplanation}
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon sx={{ color: colors.secondary }} />
                </ListItemIcon>
                <ListItemText primary={translations[language].creditUsage1} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon sx={{ color: colors.secondary }} />
                </ListItemIcon>
                <ListItemText primary={translations[language].creditUsage2} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon sx={{ color: colors.secondary }} />
                </ListItemIcon>
                <ListItemText primary={translations[language].creditUsage3} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutlineIcon sx={{ color: colors.secondary }} />
                </ListItemIcon>
                <ListItemText primary={translations[language].creditUsage4} />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom sx={{ color: colors.secondary }}>
              {translations[language].creditPackages}
            </Typography>
            <Grid container spacing={2}>
              {[
                { credits: 300, price: 14.99, tier: 'Tier 1' },
                { credits: 600, price: 24.99, tier: 'Tier 2' },
                { credits: 'Custom', price: 'Contact Us', tier: 'Tier 3' },
              ].map((plan, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {plan.credits === 'Custom' ? translations[language].customCredits : `${plan.credits} ${translations[language].credits}`}
                      </Typography>
                      <Typography variant="subtitle1" color="text.secondary">
                        {plan.tier}
                      </Typography>
                      <Typography variant="h4" color="text.secondary">
                        {plan.price === 'Contact Us' ? translations[language].contactUs : `€${plan.price}`}
                      </Typography>
                    </CardContent>
                    {plan.price === 'Contact Us' ? (
                      <Button
                        component="a"
                        href="mailto:info@medwebreports.io"
                        variant="contained"
                        sx={{
                          ...buttonStyle,
                          bgcolor: colors.accent,
                          color: colors.text,
                          '&:hover': { bgcolor: colors.secondary },
                          m: 2,
                        }}
                      >
                        {translations[language].contactUs}
                      </Button>
                    ) : (
                      <Button
                        component={Link}
                        to="/purchase-credits"
                        variant="contained"
                        sx={{
                          ...buttonStyle,
                          bgcolor: colors.accent,
                          color: colors.text,
                          '&:hover': { bgcolor: colors.secondary },
                          m: 2,
                        }}
                      >
                        {translations[language].buyNow}
                      </Button>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        
      </Container>

      <PremiumFeaturesComparison />

     
    </Box>
  );
};

export default Home;