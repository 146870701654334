export const resultInterpretations = {
  hgb: [
    { range: [13.8, 17.2], interpretation: { en: "normal (males)", it: "normale (maschi)" } },
    { range: [12.1, 15.1], interpretation: { en: "normal (females)", it: "normale (femmine)" } },
    { range: [0, 13.8], interpretation: { en: "low (males)", it: "basso (maschi)" }, advice: {
      en: "Low hemoglobin may indicate anemia. Please consult a doctor.",
      it: "Emoglobina bassa può indicare anemia. Si prega di consultare un medico."
    }},
    { range: [0, 12.1], interpretation: { en: "low (females)", it: "basso (femmine)" }, advice: {
      en: "Low hemoglobin may indicate anemia. Please consult a doctor.",
      it: "Emoglobina bassa può indicare anemia. Si prega di consultare un medico."
    }},
    { range: [17.2, Infinity], interpretation: { en: "high (males)", it: "alto (maschi)" }, advice: {
      en: "High hemoglobin may indicate polycythemia or dehydration. Please consult a doctor.",
      it: "Emoglobina alta può indicare policitemia o disidratazione. Si prega di consultare un medico."
    }},
    { range: [15.1, Infinity], interpretation: { en: "high (females)", it: "alto (femmine)" }, advice: {
      en: "High hemoglobin may indicate polycythemia or dehydration. Please consult a doctor.",
      it: "Emoglobina alta può indicare policitemia o disidratazione. Si prega di consultare un medico."
    }}
  ],
  a1c: [
    { range: [4, 5.6], interpretation: { en: "normal", it: "normale" } },
    { range: [5.7, 6.4], interpretation: { en: "prediabetes", it: "prediabete" }, advice: {
      en: "Elevated A1C indicates a risk of diabetes. Lifestyle changes are recommended. Please consult a doctor.",
      it: "L'A1C elevata indica un rischio di diabete. Sono raccomandati cambiamenti nello stile di vita. Si prega di consultare un medico."
    }},
    { range: [6.5, Infinity], interpretation: { en: "diabetes", it: "diabete" }, advice: {
      en: "High A1C indicates diabetes. Management and treatment are necessary. Please consult a doctor.",
      it: "L'A1C alta indica il diabete. Sono necessari la gestione e il trattamento. Si prega di consultare un medico."
    }}
  ],
  mpv: [
    { range: [7.5, 11.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 7.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low MPV may indicate bone marrow issues or thrombocytopenia. Please consult a doctor.",
      it: "MPV basso può indicare problemi al midollo osseo o trombocitopenia. Si prega di consultare un medico."
    }},
    { range: [11.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High MPV may suggest increased platelet production or activation. Please consult a doctor.",
      it: "MPV alto può suggerire un aumento della produzione o dell'attivazione delle piastrine. Si prega di consultare un medico."
    }}
  ],
  wbc: [
    { range: [4, 10], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 4], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low WBC count may indicate a risk of infection or bone marrow issues. Please consult a doctor.",
      it: "Un basso conteggio dei globuli bianchi può indicare un rischio di infezione o problemi al midollo osseo. Si prega di consultare un medico."
    }},
    { range: [10, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High WBC count may indicate infection, inflammation, or hematological conditions. Please consult a doctor.",
      it: "Un alto conteggio dei globuli bianchi può indicare infezione, infiammazione o condizioni ematologiche. Si prega di consultare un medico."
    }}
  ],
  t3: [
    { range: [3.1, 6.8], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 3.1], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low FT3 may indicate hypothyroidism or other thyroid issues. Please consult a doctor.",
      it: "FT3 basso può indicare ipotiroidismo o altri problemi tiroidei. Si prega di consultare un medico."
    }},
    { range: [6.8, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High FT3 may indicate hyperthyroidism. Please consult a doctor.",
      it: "FT3 alto può indicare ipertiroidismo. Si prega di consultare un medico."
    }}
  ],
  t4: [
    { range: [0.8, 2.7], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.8], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low FT4 may indicate hypothyroidism. Please consult a doctor.",
      it: "FT4 basso può indicare ipotiroidismo. Si prega di consultare un medico."
    }},
    { range: [2.7, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High FT4 may indicate hyperthyroidism. Please consult a doctor.",
      it: "FT4 alto può indicare ipertiroidismo. Si prega di consultare un medico."
    }}
  ],
  ftl: [
    { range: [12, 300], interpretation: { en: "normal (males)", it: "normale (maschi)" } },
    { range: [12, 150], interpretation: { en: "normal (females)", it: "normale (femmine)" } },
    { range: [0, 12], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low ferritin may indicate iron deficiency or anemia. Please consult a doctor.",
      it: "Ferritina bassa può indicare carenza di ferro o anemia. Si prega di consultare un medico."
    }},
    { range: [300, Infinity], interpretation: { en: "high (males)", it: "alto (maschi)" }, advice: {
      en: "High ferritin may indicate inflammation, liver disease, or iron overload. Please consult a doctor.",
      it: "Ferritina alta può indicare infiammazione, malattie del fegato o sovraccarico di ferro. Si prega di consultare un medico."
    }},
    { range: [150, Infinity], interpretation: { en: "high (females)", it: "alto (femmine)" }, advice: {
      en: "High ferritin may indicate inflammation, liver disease, or iron overload. Please consult a doctor.",
      it: "Ferritina alta può indicare infiammazione, malattie del fegato o sovraccarico di ferro. Si prega di consultare un medico."
    }}
  ],
  rdwCv: [
    { range: [11.5, 14.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 11.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low RDW-CV may indicate uniformity in red blood cell size, which is generally not a concern.",
      it: "RDW-CV basso può indicare uniformità nelle dimensioni dei globuli rossi, generalmente non preoccupante."
    }},
    { range: [14.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High RDW-CV may indicate anisocytosis, often associated with anemia or other conditions. Please consult a doctor.",
      it: "RDW-CV alto può indicare anisocitosi, spesso associata ad anemia o altre condizioni. Si prega di consultare un medico."
    }}
  ],
  po4: [
    { range: [2.5, 4.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 2.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low phosphorus levels may indicate malnutrition, hyperparathyroidism, or kidney issues. Please consult a doctor.",
      it: "Bassi livelli di fosforo possono indicare malnutrizione, iperparatiroidismo o problemi renali. Si prega di consultare un medico."
    }},
    { range: [4.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High phosphorus levels may indicate kidney disease or hypoparathyroidism. Please consult a doctor.",
      it: "Alti livelli di fosforo possono indicare malattia renale o ipoparatiroidismo. Si prega di consultare un medico."
    }}
  ],
  ldh: [
    { range: [140, 280], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 140], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low LDH is rare and usually not clinically significant, but may require further evaluation. Please consult a doctor.",
      it: "LDH basso è raro e di solito non è clinicamente significativo, ma può richiedere ulteriori valutazioni. Si prega di consultare un medico."
    }},
    { range: [280, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High LDH levels may indicate tissue damage, hemolysis, or certain cancers. Please consult a doctor.",
      it: "Alti livelli di LDH possono indicare danno tissutale, emolisi o alcuni tipi di cancro. Si prega di consultare un medico."
    }}
  ],
  cpk: [
    { range: [20, 200], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 20], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low CPK levels are generally not a concern but may indicate low muscle mass. Please consult a doctor.",
      it: "Bassi livelli di CPK generalmente non sono preoccupanti, ma possono indicare bassa massa muscolare. Si prega di consultare un medico."
    }},
    { range: [200, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High CPK levels may indicate muscle damage, myocardial infarction, or muscle disease. Please consult a doctor.",
      it: "Alti livelli di CPK possono indicare danni muscolari, infarto miocardico o malattie muscolari. Si prega di consultare un medico."
    }}
  ],
  inr: [
    { range: [0.8, 1.2], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.8], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low INR may indicate a higher risk of clotting. Please consult a doctor.",
      it: "INR basso può indicare un rischio maggiore di coagulazione. Si prega di consultare un medico."
    }},
    { range: [1.2, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High INR may indicate a higher risk of bleeding. Please consult a doctor.",
      it: "INR alto può indicare un rischio maggiore di sanguinamento. Si prega di consultare un medico."
    }}
  ],
  aptt: [
    { range: [30, 40], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 30], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low aPTT may indicate a hypercoagulable state. Please consult a doctor.",
      it: "APTT basso può indicare uno stato di ipercoagulabilità. Si prega di consultare un medico."
    }},
    { range: [40, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High aPTT may indicate a bleeding disorder or anticoagulant use. Please consult a doctor.",
      it: "APTT alto può indicare un disturbo della coagulazione o l'uso di anticoagulanti. Si prega di consultare un medico."
    }}
  ],
  apttRatio: [
    { range: [0.8, 1.2], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.8], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low aPTT ratio may suggest a hypercoagulable state. Please consult a doctor.",
      it: "Un basso rapporto aPTT può suggerire uno stato di ipercoagulabilità. Si prega di consultare un medico."
    }},
    { range: [1.2, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High aPTT ratio may indicate a bleeding disorder or anticoagulant use. Please consult a doctor.",
      it: "Un alto rapporto aPTT può indicare un disturbo della coagulazione o l'uso di anticoagulanti. Si prega di consultare un medico."
    }}
  ],
  tg: [
    { range: [0, 55], interpretation: { en: "normal", it: "normale" } },
    { range: [55, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High thyroglobulin levels may indicate thyroid cancer or other thyroid disorders. Please consult a doctor.",
      it: "Alti livelli di tireoglobulina possono indicare cancro alla tiroide o altri disturbi tiroidei. Si prega di consultare un medico."
    }}
  ],
  hbsag: [
    { range: [0, 1], interpretation: { en: "negative", it: "negativo" } },
    { range: [1, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "A positive HBsAg test indicates an active Hepatitis B infection. Please consult a doctor.",
      it: "Un test HBsAg positivo indica un'infezione attiva da epatite B. Si prega di consultare un medico."
    }}
  ],
  antiHbsAg: [
    { range: [0, 10], interpretation: { en: "negative", it: "negativo" } },
    { range: [10, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "A positive anti-HBs antibody test indicates immunity to Hepatitis B. Please consult a doctor.",
      it: "Un test anticorpale anti-HBs positivo indica immunità all'epatite B. Si prega di consultare un medico."
    }}
  ],
  antiHcv: [
    { range: [0, 1], interpretation: { en: "negative", it: "negativo" } },
    { range: [1, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "A positive anti-HCV test indicates exposure to the Hepatitis C virus. Further testing is required. Please consult a doctor.",
      it: "Un test anti-HCV positivo indica l'esposizione al virus dell'epatite C. Sono necessari ulteriori test. Si prega di consultare un medico."
    }}
  ],
  pt: [
    { range: [11, 13.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 11], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low PT may indicate a higher risk of clotting. Please consult a doctor.",
      it: "PT basso può indicare un rischio maggiore di coagulazione. Si prega di consultare un medico."
    }},
    { range: [13.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High PT may indicate a bleeding disorder or anticoagulant use. Please consult a doctor.",
      it: "PT alto può indicare un disturbo della coagulazione o l'uso di anticoagulanti. Si prega di consultare un medico."
    }}
  ],
  mch: [
    { range: [27, 31], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 27], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low MCH may indicate microcytic anemia. Please consult a doctor.",
      it: "MCH basso può indicare anemia microcitica. Si prega di consultare un medico."
    }},
    { range: [31, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High MCH may indicate macrocytic anemia. Please consult a doctor.",
      it: "MCH alto può indicare anemia macrocitica. Si prega di consultare un medico."
    }}
  ],
  mchc: [
    { range: [32, 36], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 32], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low MCHC may indicate hypochromic anemia. Please consult a doctor.",
      it: "MCHC basso può indicare anemia ipocromica. Si prega di consultare un medico."
    }},
    { range: [36, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High MCHC may indicate hereditary spherocytosis or other conditions. Please consult a doctor.",
      it: "MCHC alto può indicare sferocitosi ereditaria o altre condizioni. Si prega di consultare un medico."
    }}
  ],
  plt: [
    { range: [150, 450], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 150], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low platelet count (thrombocytopenia) may increase the risk of bleeding. Please consult a doctor.",
      it: "Un basso numero di piastrine (trombocitopenia) può aumentare il rischio di sanguinamento. Si prega di consultare un medico."
    }},
    { range: [450, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High platelet count (thrombocytosis) may increase the risk of clotting. Please consult a doctor.",
      it: "Un alto numero di piastrine (trombocitosi) può aumentare il rischio di coagulazione. Si prega di consultare un medico."
    }}
  ],
  mpv: [
    { range: [7.5, 11.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 7.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low MPV may indicate bone marrow issues or thrombocytopenia. Please consult a doctor.",
      it: "MPV basso può indicare problemi al midollo osseo o trombocitopenia. Si prega di consultare un medico."
    }},
    { range: [11.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High MPV may suggest increased platelet production or activation. Please consult a doctor.",
      it: "MPV alto può suggerire un aumento della produzione o dell'attivazione delle piastrine. Si prega di consultare un medico."
    }}
  ],
  ast: [
    { range: [10, 40], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 10], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low AST is generally not a concern but may require further evaluation if persistently low. Please consult a doctor.",
      it: "AST basso generalmente non è preoccupante, ma può richiedere ulteriori valutazioni se persistentemente basso. Si prega di consultare un medico."
    }},
    { range: [40, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High AST levels may indicate liver damage, muscle injury, or other conditions. Please consult a doctor.",
      it: "Livelli elevati di AST possono indicare danno epatico, lesione muscolare o altre condizioni. Si prega di consultare un medico."
    }}
  ],
  lps: [
    { range: [13, 60], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 13], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low lipase levels are generally not a concern but may require further evaluation. Please consult a doctor.",
      it: "Livelli bassi di lipasi generalmente non sono preoccupanti, ma possono richiedere ulteriori valutazioni. Si prega di consultare un medico."
    }},
    { range: [60, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High lipase levels may indicate pancreatitis or other pancreatic issues. Please consult a doctor.",
      it: "Livelli elevati di lipasi possono indicare pancreatite o altri problemi pancreatici. Si prega di consultare un medico."
    }}
  ],
  alt: [
    { range: [7, 56], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 7], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low ALT is generally not a concern but may require further evaluation if persistently low. Please consult a doctor.",
      it: "ALT basso generalmente non è preoccupante, ma può richiedere ulteriori valutazioni se persistentemente basso. Si prega di consultare un medico."
    }},
    { range: [56, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High ALT levels may indicate liver damage or disease. Please consult a doctor.",
      it: "Livelli elevati di ALT possono indicare danno epatico o malattia epatica. Si prega di consultare un medico."
    }}
  ],
  alp: [
    { range: [44, 147], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 44], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low ALP may indicate malnutrition, hypothyroidism, or other conditions. Please consult a doctor.",
      it: "Basso ALP può indicare malnutrizione, ipotiroidismo o altre condizioni. Si prega di consultare un medico."
    }},
    { range: [147, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High ALP may indicate liver or bone disease. Please consult a doctor.",
      it: "ALP elevato può indicare malattia epatica o ossea. Si prega di consultare un medico."
    }}
  ],
  pth: [
    { range: [10, 65], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 10], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low PTH levels may indicate hypoparathyroidism. Please consult a doctor.",
      it: "Bassi livelli di PTH possono indicare ipoparatiroidismo. Si prega di consultare un medico."
    }},
    { range: [65, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High PTH levels may indicate hyperparathyroidism or kidney disease. Please consult a doctor.",
      it: "Alti livelli di PTH possono indicare iperparatiroidismo o malattia renale. Si prega di consultare un medico."
    }}
  ],
  insulin: [
    { range: [2.6, 24.9], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 2.6], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low insulin levels may indicate diabetes or pancreatic issues. Please consult a doctor.",
      it: "Bassi livelli di insulina possono indicare diabete o problemi pancreatici. Si prega di consultare un medico."
    }},
    { range: [24.9, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High insulin levels may indicate insulin resistance or hyperinsulinemia. Please consult a doctor.",
      it: "Alti livelli di insulina possono indicare resistenza all'insulina o iperinsulinemia. Si prega di consultare un medico."
    }}
  ],
  dbil: [
    { range: [0, 0.3], interpretation: { en: "normal", it: "normale" } },
    { range: [0.3, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High direct bilirubin may indicate liver dysfunction or bile duct obstruction. Please consult a doctor.",
      it: "Un'elevata bilirubina diretta può indicare disfunzione epatica o ostruzione del dotto biliare. Si prega di consultare un medico."
    }}
  ],
  ibil: [
    { range: [0.1, 1.0], interpretation: { en: "normal", it: "normale" } },
    { range: [1.0, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High indirect bilirubin may indicate hemolysis or liver dysfunction. Please consult a doctor.",
      it: "Elevata bilirubina indiretta può indicare emolisi o disfunzione epatica. Si prega di consultare un medico."
    }}
  ],
  tp: [
    { range: [6.0, 8.3], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 6.0], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low total protein may indicate malnutrition, liver disease, or kidney issues. Please consult a doctor.",
      it: "Proteine totali basse possono indicare malnutrizione, malattie epatiche o problemi renali. Si prega di consultare un medico."
    }},
    { range: [8.3, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High total protein may indicate chronic inflammation or infection. Please consult a doctor.",
      it: "Proteine totali alte possono indicare infiammazione cronica o infezione. Si prega di consultare un medico."
    }}
  ],
  fe: [
    { range: [60, 170], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 60], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low serum iron may indicate iron deficiency anemia or chronic disease. Please consult a doctor.",
      it: "Bassa sideremia può indicare anemia da carenza di ferro o malattia cronica. Si prega di consultare un medico."
    }},
    { range: [170, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High serum iron may indicate hemochromatosis or iron overload. Please consult a doctor.",
      it: "Alta sideremia può indicare emocromatosi o sovraccarico di ferro. Si prega di consultare un medico."
    }}
  ],
  ggt: [
    { range: [0, 51], interpretation: { en: "normal", it: "normale" } },
    { range: [51, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Gamma GT levels may indicate liver disease or bile duct obstruction. Please consult a doctor.",
      it: "Alti livelli di Gamma GT possono indicare malattie epatiche o ostruzione del dotto biliare. Si prega di consultare un medico."
    }}
  ],
  ams: [
    { range: [30, 110], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 30], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low amylase levels may indicate pancreatic insufficiency. Please consult a doctor.",
      it: "Bassi livelli di amilasi possono indicare insufficienza pancreatica. Si prega di consultare un medico."
    }},
    { range: [110, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High amylase levels may indicate pancreatitis or other pancreatic disorders. Please consult a doctor.",
      it: "Alti livelli di amilasi possono indicare pancreatite o altri disturbi pancreatici. Si prega di consultare un medico."
    }}
  ],
  bche: [
    { range: [4000, 12000], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 4000], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low cholinesterase levels may indicate liver disease or organophosphate poisoning. Please consult a doctor.",
      it: "Bassi livelli di colinesterasi possono indicare malattie epatiche o avvelenamento da organofosfati. Si prega di consultare un medico."
    }},
    { range: [12000, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High cholinesterase levels may indicate nephrotic syndrome or genetic conditions. Please consult a doctor.",
      it: "Alti livelli di colinesterasi possono indicare sindrome nefrosica o condizioni genetiche. Si prega di consultare un medico."
    }}
  ],
  hct: [
    { range: [40.7, 50.3], interpretation: { en: "normal (males)", it: "normale (maschi)" } },
    { range: [36.1, 44.3], interpretation: { en: "normal (females)", it: "normale (femmine)" } },
    { range: [0, 40.7], interpretation: { en: "low (males)", it: "basso (maschi)" }, advice: {
      en: "Low hematocrit may indicate anemia or blood loss. Please consult a doctor.",
      it: "Un basso ematocrito può indicare anemia o perdita di sangue. Si prega di consultare un medico."
    }},
    { range: [0, 36.1], interpretation: { en: "low (females)", it: "basso (femmine)" }, advice: {
      en: "Low hematocrit may indicate anemia or blood loss. Please consult a doctor.",
      it: "Un basso ematocrito può indicare anemia o perdita di sangue. Si prega di consultare un medico."
    }},
    { range: [50.3, Infinity], interpretation: { en: "high (males)", it: "alto (maschi)" }, advice: {
      en: "High hematocrit may indicate dehydration or polycythemia. Please consult a doctor.",
      it: "Un ematocrito alto può indicare disidratazione o policitemia. Si prega di consultare un medico."
    }},
    { range: [44.3, Infinity], interpretation: { en: "high (females)", it: "alto (femmine)" }, advice: {
      en: "High hematocrit may indicate dehydration or polycythemia. Please consult a doctor.",
      it: "Un ematocrito alto può indicare disidratazione o policitemia. Si prega di consultare un medico."
    }}
  ],
  rbc: [
    { range: [4.7, 6.1], interpretation: { en: "normal (males)", it: "normale (maschi)" } },
    { range: [4.2, 5.4], interpretation: { en: "normal (females)", it: "normale (femmine)" } },
    { range: [0, 4.7], interpretation: { en: "low (males)", it: "basso (maschi)" }, advice: {
      en: "Low RBC count may indicate anemia or bone marrow disorders. Please consult a doctor.",
      it: "Un basso numero di globuli rossi può indicare anemia o disturbi del midollo osseo. Si prega di consultare un medico."
    }},
    { range: [0, 4.2], interpretation: { en: "low (females)", it: "basso (femmine)" }, advice: {
      en: "Low RBC count may indicate anemia or bone marrow disorders. Please consult a doctor.",
      it: "Un basso numero di globuli rossi può indicare anemia o disturbi del midollo osseo. Si prega di consultare un medico."
    }},
    { range: [6.1, Infinity], interpretation: { en: "high (males)", it: "alto (maschi)" }, advice: {
      en: "High RBC count may indicate dehydration, polycythemia, or other conditions. Please consult a doctor.",
      it: "Un alto numero di globuli rossi può indicare disidratazione, policitemia o altre condizioni. Si prega di consultare un medico."
    }},
    { range: [5.4, Infinity], interpretation: { en: "high (females)", it: "alto (femmine)" }, advice: {
      en: "High RBC count may indicate dehydration, polycythemia, or other conditions. Please consult a doctor.",
      it: "Un alto numero di globuli rossi può indicare disidratazione, policitemia o altre condizioni. Si prega di consultare un medico."
    }}
  ],
  mcv: [
    { range: [80, 100], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 80], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low MCV may indicate microcytic anemia. Please consult a doctor.",
      it: "Un MCV basso può indicare anemia microcitica. Si prega di consultare un medico."
    }},
    { range: [100, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High MCV may indicate macrocytic anemia. Please consult a doctor.",
      it: "Un MCV alto può indicare anemia macrocitica. Si prega di consultare un medico."
    }}
  ],
  mch: [
    { range: [27, 31], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 27], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low MCH may indicate microcytic anemia. Please consult a doctor.",
      it: "MCH basso può indicare anemia microcitica. Si prega di consultare un medico."
    }},
    { range: [31, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High MCH may indicate macrocytic anemia. Please consult a doctor.",
      it: "MCH alto può indicare anemia macrocitica. Si prega di consultare un medico."
    }}
  ],
  mchc: [
    { range: [32, 36], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 32], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low MCHC may indicate hypochromic anemia. Please consult a doctor.",
      it: "MCHC basso può indicare anemia ipocromica. Si prega di consultare un medico."
    }},
    { range: [36, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High MCHC may indicate hereditary spherocytosis or other conditions. Please consult a doctor.",
      it: "MCHC alto può indicare sferocitosi ereditaria o altre condizioni. Si prega di consultare un medico."
    }}
  ],
  neut: [
    { range: [40, 60], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 40], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low neutrophil count (neutropenia) may indicate a risk of infection. Please consult a doctor.",
      it: "Un basso numero di neutrofili (neutropenia) può indicare un rischio di infezione. Si prega di consultare un medico."
    }},
    { range: [60, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High neutrophil count may indicate an ongoing infection or inflammation. Please consult a doctor.",
      it: "Un alto numero di neutrofili può indicare un'infezione in corso o un'infiammazione. Si prega di consultare un medico."
    }}
  ],
  lym: [
    { range: [20, 40], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 20], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low lymphocyte count (lymphopenia) may indicate a compromised immune system. Please consult a doctor.",
      it: "Un basso numero di linfociti (linfopenia) può indicare un sistema immunitario compromesso. Si prega di consultare un medico."
    }},
    { range: [40, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High lymphocyte count may indicate an ongoing viral infection or chronic lymphocytic leukemia. Please consult a doctor.",
      it: "Un alto numero di linfociti può indicare un'infezione virale in corso o leucemia linfatica cronica. Si prega di consultare un medico."
    }}
  ],
  mono: [
    { range: [2, 8], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 2], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low monocyte count may be seen in certain infections or bone marrow suppression. Please consult a doctor.",
      it: "Un basso numero di monociti può essere osservato in alcune infezioni o soppressione del midollo osseo. Si prega di consultare un medico."
    }},
    { range: [8, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High monocyte count may indicate chronic inflammation or infection. Please consult a doctor.",
      it: "Un alto numero di monociti può indicare infiammazione cronica o infezione. Si prega di consultare un medico."
    }}
  ],
  eosi: [
    { range: [1, 4], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 1], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low eosinophil count is typically not a concern. Please consult a doctor if symptoms persist.",
      it: "Un basso numero di eosinofili generalmente non è preoccupante. Si prega di consultare un medico se i sintomi persistono."
    }},
    { range: [4, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High eosinophil count may indicate allergies, asthma, or parasitic infections. Please consult a doctor.",
      it: "Un alto numero di eosinofili può indicare allergie, asma o infezioni parassitarie. Si prega di consultare un medico."
    }}
  ],
  baso: [
    { range: [0.5, 1], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low basophil count is typically not a concern. Please consult a doctor if symptoms persist.",
      it: "Un basso numero di basofili generalmente non è preoccupante. Si prega di consultare un medico se i sintomi persistono."
    }},
    { range: [1, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High basophil count may indicate chronic inflammation or allergic reactions. Please consult a doctor.",
      it: "Un alto numero di basofili può indicare infiammazione cronica o reazioni allergiche. Si prega di consultare un medico."
    }}
  ],
  glu: [
    { range: [70, 99], interpretation: { en: "normal", it: "normale" } },
    { range: [100, 125], interpretation: { en: "prediabetes", it: "prediabete" }, advice: {
      en: "Elevated glucose levels indicate a risk of developing diabetes. Lifestyle changes are recommended. Please consult a doctor.",
      it: "Livelli elevati di glucosio indicano un rischio di sviluppare il diabete. Sono raccomandati cambiamenti nello stile di vita. Si prega di consultare un medico."
    }},
    { range: [126, Infinity], interpretation: { en: "diabetes", it: "diabete" }, advice: {
      en: "High glucose levels indicate diabetes. Management and treatment are necessary. Please consult a doctor.",
      it: "Livelli elevati di glucosio indicano diabete. Sono necessari la gestione e il trattamento. Si prega di consultare un medico."
    }}
  ],
  TPOAb: [
    { range: [0, 35], interpretation: { en: "normal", it: "normale" } },
    { range: [35, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "Elevated levels may indicate autoimmune thyroid disease. Please consult a doctor.",
      it: "Livelli elevati possono indicare una malattia autoimmune della tiroide. Si prega di consultare un medico."
    }}
  ],
  PRL: [
    { range: [4.8, 23.3], interpretation: { en: "normal (females)", it: "normale (femmine)" } },
    { range: [4.0, 15.2], interpretation: { en: "normal (males)", it: "normale (maschi)" } },
    { range: [0, 4.8], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low prolactin levels can occur due to pituitary issues. Please consult a doctor.",
      it: "Livelli bassi di prolattina possono verificarsi a causa di problemi ipofisari. Si prega di consultare un medico."
    }},
    { range: [23.3, Infinity], interpretation: { en: "high (females)", it: "alto (femmine)" }, advice: {
      en: "High prolactin levels may indicate prolactinoma or other conditions. Please consult a doctor.",
      it: "Livelli alti di prolattina possono indicare prolattinoma o altre condizioni. Si prega di consultare un medico."
    }},
    { range: [15.2, Infinity], interpretation: { en: "high (males)", it: "alto (maschi)" }, advice: {
      en: "High prolactin levels may indicate prolactinoma or other conditions. Please consult a doctor.",
      it: "Livelli alti di prolattina possono indicare prolattinoma o altre condizioni. Si prega di consultare un medico."
    }}
  ],
  rdwsd: [
    { range: [39, 46], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 39], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low RDW-SD may indicate anemia. Please consult a doctor.",
      it: "RDW-SD basso può indicare anemia. Si prega di consultare un medico."
    }},
    { range: [46, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High RDW-SD may indicate mixed anemia or vitamin deficiency. Please consult a doctor.",
      it: "RDW-SD alto può indicare anemia mista o carenza di vitamine. Si prega di consultare un medico."
    }}
  ],
  PLCR: [
    { range: [15, 35], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 15], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low PLCR may indicate thrombocytopenia or other platelet disorders. Please consult a doctor.",
      it: "Un basso PLCR può indicare trombocitopenia o altri disturbi delle piastrine. Si prega di consultare un medico."
    }},
    { range: [35, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High PLCR may indicate an increased risk of thrombosis. Please consult a doctor.",
      it: "Un alto PLCR può indicare un aumento del rischio di trombosi. Si prega di consultare un medico."
    }}
  ],
  PDW: [
    { range: [9, 14], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 9], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low PDW may suggest uniformity in platelet size, which is generally not a concern.",
      it: "Un basso PDW può suggerire uniformità nelle dimensioni delle piastrine, generalmente non preoccupante."
    }},
    { range: [14, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High PDW may indicate variation in platelet size, often seen in platelet disorders. Please consult a doctor.",
      it: "Un alto PDW può indicare una variazione nelle dimensioni delle piastrine, spesso vista nei disturbi delle piastrine. Si prega di consultare un medico."
    }}
  ],
  PCT: [
    { range: [0.22, 0.24], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.22], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low PCT may suggest thrombocytopenia. Please consult a doctor.",
      it: "Un basso PCT può suggerire trombocitopenia. Si prega di consultare un medico."
    }},
    { range: [0.24, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High PCT may indicate a risk of thrombosis. Please consult a doctor.",
      it: "Un alto PCT può indicare un rischio di trombosi. Si prega di consultare un medico."
    }}
  ],
  HBA1: [
    { range: [95, 98], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 95], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low Hemoglobin A1 may indicate a hemoglobinopathy. Please consult a doctor.",
      it: "Basso Emoglobina A1 può indicare una emoglobinopatia. Si prega di consultare un medico."
    }},
    { range: [98, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Hemoglobin A1 may indicate hemolytic anemia. Please consult a doctor.",
      it: "Alto Emoglobina A1 può indicare anemia emolitica. Si prega di consultare un medico."
    }}
  ],
  HBF: [
    { range: [0, 2], interpretation: { en: "normal", it: "normale" } },
    { range: [2, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Hemoglobin F may indicate beta-thalassemia or other genetic conditions. Please consult a doctor.",
      it: "Alto Emoglobina F può indicare beta-talassemia o altre condizioni genetiche. Si prega di consultare un medico."
    }}
  ],
  HBA2: [
    { range: [2, 3.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 2], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low Hemoglobin A2 may indicate a hemoglobinopathy. Please consult a doctor.",
      it: "Basso Emoglobina A2 può indicare una emoglobinopatia. Si prega di consultare un medico."
    }},
    { range: [3.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Hemoglobin A2 may indicate beta-thalassemia trait. Please consult a doctor.",
      it: "Alto Emoglobina A2 può indicare tratto beta-talassemico. Si prega di consultare un medico."
    }}
  ],
  TgAb: [
    { range: [0, 40], interpretation: { en: "normal", it: "normale" } },
    { range: [40, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High TgAb may indicate autoimmune thyroid disease. Please consult a doctor.",
      it: "Alto TgAb può indicare una malattia autoimmune della tiroide. Si prega di consultare un medico."
    }}
  ],
  AbnHE: [
    { range: [0, 0], interpretation: { en: "normal", it: "normale" } },
    { range: [0, Infinity], interpretation: { en: "abnormal", it: "anomalo" }, advice: {
      en: "Presence of abnormal hemoglobin suggests a hemoglobinopathy. Please consult a doctor.",
      it: "La presenza di emoglobina anomala suggerisce una emoglobinopatia. Si prega di consultare un medico."
    }}
  ],
  HF: [
    { range: [0, 2], interpretation: { en: "normal", it: "normale" } },
    { range: [2, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Fetal Hemoglobin may indicate hereditary conditions like beta-thalassemia. Please consult a doctor.",
      it: "Alto Emoglobina fetale può indicare condizioni ereditarie come la beta-talassemia. Si prega di consultare un medico."
    }}
  ],
  BUN: [
    { range: [7, 20], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 7], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low BUN may indicate liver disease or malnutrition. Please consult a doctor.",
      it: "BUN basso può indicare malattie epatiche o malnutrizione. Si prega di consultare un medico."
    }},
    { range: [20, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High BUN may indicate kidney dysfunction or dehydration. Please consult a doctor.",
      it: "BUN alto può indicare disfunzione renale o disidratazione. Si prega di consultare un medico."
    }}
  ],
  ToxoIgG: [
    { range: [0, 4], interpretation: { en: "negative", it: "negativo" } },
    { range: [4, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "Positive Toxoplasma IgG indicates past or current infection with Toxoplasma gondii. Please consult a doctor.",
      it: "Toxoplasma IgG positivo indica un'infezione passata o in corso con Toxoplasma gondii. Si prega di consultare un medico."
    }}
  ],
  ToxoIgM: [
    { range: [0, 1], interpretation: { en: "negative", it: "negativo" } },
    { range: [1, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "Positive Toxoplasma IgM suggests a recent or acute infection with Toxoplasma gondii. Please consult a doctor.",
      it: "Toxoplasma IgM positivo suggerisce un'infezione recente o acuta con Toxoplasma gondii. Si prega di consultare un medico."
    }}
  ],
  HSV12IgG: [
    { range: [0, 0.9], interpretation: { en: "negative", it: "negativo" } },
    { range: [0.9, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "Positive HSV 1-2 IgG indicates past or current infection with Herpes Simplex Virus. Please consult a doctor.",
      it: "HSV 1-2 IgG positivo indica un'infezione passata o in corso con il virus Herpes Simplex. Si prega di consultare un medico."
    }}
  ],
  HSV12IgM: [
    { range: [0, 1], interpretation: { en: "negative", it: "negativo" } },
    { range: [1, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "Positive HSV 1-2 IgM suggests a recent or acute infection with Herpes Simplex Virus. Please consult a doctor.",
      it: "HSV 1-2 IgM positivo suggerisce un'infezione recente o acuta con il virus Herpes Simplex. Si prega di consultare un medico."
    }}
  ],
  VDRL: [
    { range: [0, 1], interpretation: { en: "non-reactive", it: "non reattivo" } },
    { range: [1, Infinity], interpretation: { en: "reactive", it: "reattivo" }, advice: {
      en: "A reactive VDRL test suggests a possible syphilis infection. Further testing is required. Please consult a doctor.",
      it: "Un test VDRL reattivo suggerisce una possibile infezione da sifilide. Sono necessari ulteriori test. Si prega di consultare un medico."
    }}
  ],
  RPHA: [
    { range: [0, 1], interpretation: { en: "negative", it: "negativo" } },
    { range: [1, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "A positive RPHA test indicates the presence of specific antigens. Please consult a doctor for further evaluation.",
      it: "Un test RPHA positivo indica la presenza di antigeni specifici. Si prega di consultare un medico per ulteriori valutazioni."
    }}
  ],
  HIV12Ab: [
    { range: [0, 1], interpretation: { en: "negative", it: "negativo" } },
    { range: [1, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "A positive HIV 1-2 antibody test suggests HIV infection. Confirmatory testing is required. Please consult a doctor.",
      it: "Un test positivo per gli anticorpi HIV 1-2 suggerisce un'infezione da HIV. Sono necessari test di conferma. Si prega di consultare un medico."
    }}
  ],
  HIV1Ag: [
    { range: [0, 1], interpretation: { en: "negative", it: "negativo" } },
    { range: [1, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "A positive HIV 1 antigen test indicates early HIV infection. Please consult a doctor.",
      it: "Un test positivo per l'antigene HIV 1 indica un'infezione precoce da HIV. Si prega di consultare un medico."
    }}
  ],
  βhCG: [
    { range: [0, 5], interpretation: { en: "normal (non-pregnant)", it: "normale (non incinta)" } },
    { range: [5, Infinity], interpretation: { en: "positive (pregnant)", it: "positivo (incinta)" }, advice: {
      en: "A positive β-hCG test confirms pregnancy. Please consult a doctor for prenatal care.",
      it: "Un test positivo per β-hCG conferma la gravidanza. Si prega di consultare un medico per le cure prenatali."
    }},
    { range: [5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "Elevated β-hCG in non-pregnant individuals may indicate a tumor. Please consult a doctor.",
      it: "Livelli elevati di β-hCG in individui non incinti possono indicare un tumore. Si prega di consultare un medico."
    }}
  ],
  TRAb: [
    { range: [0, 1.75], interpretation: { en: "normal", it: "normale" } },
    { range: [1.75, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "Elevated TRAb levels may indicate Graves' disease. Please consult a doctor.",
      it: "Livelli elevati di TRAb possono indicare la malattia di Graves. Si prega di consultare un medico."
    }}
  ],
  urea: [
    { range: [7, 20], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 7], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low blood urea nitrogen may indicate liver disease or malnutrition. Please consult a doctor.",
      it: "Bassi livelli di azoto ureico possono indicare malattie epatiche o malnutrizione. Si prega di consultare un medico."
    }},
    { range: [20, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High blood urea nitrogen may indicate kidney dysfunction or dehydration. Please consult a doctor.",
      it: "Alti livelli di azoto ureico possono indicare disfunzione renale o disidratazione. Si prega di consultare un medico."
    }}
  ],
  crea: [
    { range: [0.7, 1.2], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.7], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low creatinine levels may indicate muscle loss or liver disease. Please consult a doctor.",
      it: "Bassi livelli di creatinina possono indicare perdita muscolare o malattie epatiche. Si prega di consultare un medico."
    }},
    { range: [1.2, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High creatinine levels may indicate kidney dysfunction. Please consult a doctor.",
      it: "Alti livelli di creatinina possono indicare disfunzione renale. Si prega di consultare un medico."
    }}
  ],
  na: [
    { range: [135, 145], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 135], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low sodium levels (hyponatremia) may indicate overhydration or kidney issues. Please consult a doctor.",
      it: "Bassi livelli di sodio (iponatremia) possono indicare iperidratazione o problemi renali. Si prega di consultare un medico."
    }},
    { range: [145, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High sodium levels (hypernatremia) may indicate dehydration or adrenal gland issues. Please consult a doctor.",
      it: "Alti livelli di sodio (ipernatremia) possono indicare disidratazione o problemi delle ghiandole surrenali. Si prega di consultare un medico."
    }}
  ],
  k: [
    { range: [3.5, 5.1], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 3.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low potassium levels (hypokalemia) may indicate malnutrition or excessive loss of potassium. Please consult a doctor.",
      it: "Bassi livelli di potassio (ipokaliemia) possono indicare malnutrizione o perdita eccessiva di potassio. Si prega di consultare un medico."
    }},
    { range: [5.1, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High potassium levels (hyperkalemia) may indicate kidney dysfunction or adrenal gland issues. Please consult a doctor.",
      it: "Alti livelli di potassio (iperkaliemia) possono indicare disfunzione renale o problemi delle ghiandole surrenali. Si prega di consultare un medico."
    }}
  ],
  cl: [
    { range: [98, 106], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 98], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low chloride levels may indicate chronic lung disease, metabolic alkalosis, or prolonged vomiting. Please consult a doctor.",
      it: "Bassi livelli di cloruro possono indicare malattie polmonari croniche, alcalosi metabolica o vomito prolungato. Si prega di consultare un medico."
    }},
    { range: [106, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High chloride levels may indicate dehydration, kidney disease, or acidosis. Please consult a doctor.",
      it: "Alti livelli di cloruro possono indicare disidratazione, malattie renali o acidosi. Si prega di consultare un medico."
    }}
  ],
  ca: [
    { range: [8.5, 10.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 8.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low calcium levels may indicate hypoparathyroidism or vitamin D deficiency. Please consult a doctor.",
      it: "Bassi livelli di calcio possono indicare ipoparatiroidismo o carenza di vitamina D. Si prega di consultare un medico."
    }},
    { range: [10.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High calcium levels may indicate hyperparathyroidism or bone disease. Please consult a doctor.",
      it: "Alti livelli di calcio possono indicare iperparatiroidismo o malattie ossee. Si prega di consultare un medico."
    }}
  ],
  co2: [
    { range: [23, 29], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 23], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low carbon dioxide levels may indicate metabolic acidosis or chronic lung disease. Please consult a doctor.",
      it: "Bassi livelli di anidride carbonica possono indicare acidosi metabolica o malattie polmonari croniche. Si prega di consultare un medico."
    }},
    { range: [29, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High carbon dioxide levels may indicate metabolic alkalosis or lung disease. Please consult a doctor.",
      it: "Alti livelli di anidride carbonica possono indicare alcalosi metabolica o malattie polmonari. Si prega di consultare un medico."
    }}
  ],
  tc: [
    { range: [0, 200], interpretation: { en: "desirable", it: "desiderabile" } },
    { range: [200, 239], interpretation: { en: "borderline high", it: "al limite del normale" }, advice: {
      en: "Borderline high total cholesterol may increase the risk of heart disease. Lifestyle changes are recommended. Please consult a doctor.",
      it: "Colesterolo totale al limite del normale può aumentare il rischio di malattie cardiache. Sono raccomandati cambiamenti nello stile di vita. Si prega di consultare un medico."
    }},
    { range: [240, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High total cholesterol is a major risk factor for heart disease. Management and treatment are necessary. Please consult a doctor.",
      it: "Colesterolo totale alto è un fattore di rischio importante per le malattie cardiache. Sono necessari la gestione e il trattamento. Si prega di consultare un medico."
    }}
  ],
  hdl: [
    { range: [60, Infinity], interpretation: { en: "high (desirable)", it: "alto (desiderabile)" } },
    { range: [40, 59], interpretation: { en: "acceptable", it: "accettabile" } },
    { range: [0, 40], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low HDL cholesterol is a risk factor for heart disease. Lifestyle changes are recommended. Please consult a doctor.",
      it: "Colesterolo HDL basso è un fattore di rischio per le malattie cardiache. Sono raccomandati cambiamenti nello stile di vita. Si prega di consultare un medico."
    }}
  ],
  ldl: [
    { range: [0, 100], interpretation: { en: "optimal", it: "ottimale" } },
    { range: [100, 129], interpretation: { en: "near optimal", it: "quasi ottimale" } },
    { range: [130, 159], interpretation: { en: "borderline high", it: "al limite del normale" }, advice: {
      en: "Borderline high LDL cholesterol may increase the risk of heart disease. Lifestyle changes are recommended. Please consult a doctor.",
      it: "Colesterolo LDL al limite del normale può aumentare il rischio di malattie cardiache. Sono raccomandati cambiamenti nello stile di vita. Si prega di consultare un medico."
    }},
    { range: [160, 189], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High LDL cholesterol is a significant risk factor for heart disease. Management and treatment are necessary. Please consult a doctor.",
      it: "Colesterolo LDL alto è un fattore di rischio significativo per le malattie cardiache. Sono necessari la gestione e il trattamento. Si prega di consultare un medico."
    }},
    { range: [190, Infinity], interpretation: { en: "very high", it: "molto alto" }, advice: {
      en: "Very high LDL cholesterol is a severe risk factor for heart disease. Immediate management and treatment are required. Please consult a doctor.",
      it: "Colesterolo LDL molto alto è un fattore di rischio grave per le malattie cardiache. Sono necessari gestione e trattamento immediati. Si prega di consultare un medico."
    }}
  ],
  tag: [
    { range: [0, 150], interpretation: { en: "normal", it: "normale" } },
    { range: [150, 199], interpretation: { en: "borderline high", it: "al limite del normale" }, advice: {
      en: "Borderline high triglycerides may increase the risk of heart disease. Lifestyle changes are recommended. Please consult a doctor.",
      it: "Trigliceridi al limite del normale possono aumentare il rischio di malattie cardiache. Sono raccomandati cambiamenti nello stile di vita. Si prega di consultare un medico."
    }},
    { range: [200, 499], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High triglycerides are a significant risk factor for heart disease. Management and treatment are necessary. Please consult a doctor.",
      it: "Trigliceridi alti sono un fattore di rischio significativo per le malattie cardiache. Sono necessari la gestione e il trattamento. Si prega di consultare un medico."
    }},
    { range: [500, Infinity], interpretation: { en: "very high", it: "molto alto" }, advice: {
      en: "Very high triglycerides are a severe risk factor for heart disease and pancreatitis. Immediate management and treatment are required. Please consult a doctor.",
      it: "Trigliceridi molto alti sono un fattore di rischio grave per le malattie cardiache e la pancreatite. Sono necessari gestione e trattamento immediati. Si prega di consultare un medico."
    }}
  ],
  tbil: [
    { range: [0.1, 1.2], interpretation: { en: "normal", it: "normale" } },
    { range: [1.2, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High total bilirubin may indicate liver disease, bile duct obstruction, or hemolysis. Please consult a doctor.",
      it: "Alta bilirubina totale può indicare malattie epatiche, ostruzione del dotto biliare o emolisi. Si prega di consultare un medico."
    }}
  ],
  alb: [
    { range: [3.5, 5.0], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 3.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low albumin levels may indicate liver disease, kidney disease, or malnutrition. Please consult a doctor.",
      it: "Bassi livelli di albumina possono indicare malattie epatiche, malattie renali o malnutrizione. Si prega di consultare un medico."
    }},
    { range: [5.0, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High albumin levels are rare but may indicate dehydration. Please consult a doctor.",
      it: "Alti livelli di albumina sono rari ma possono indicare disidratazione. Si prega di consultare un medico."
    }}
  ],
  tsh: [
    { range: [0.4, 4.0], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.4], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low TSH levels may indicate hyperthyroidism. Please consult a doctor.",
      it: "Bassi livelli di TSH possono indicare ipertiroidismo. Si prega di consultare un medico."
    }},
    { range: [4.0, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High TSH levels may indicate hypothyroidism. Please consult a doctor.",
      it: "Alti livelli di TSH possono indicare ipotiroidismo. Si prega di consultare un medico."
    }}
  ],
  tibc: [
    { range: [240, 450], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 240], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low TIBC may indicate malnutrition or liver disease. Please consult a doctor.",
      it: "Basso TIBC può indicare malnutrizione o malattie epatiche. Si prega di consultare un medico."
    }},
    { range: [450, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High TIBC may indicate iron deficiency anemia. Please consult a doctor.",
      it: "Alto TIBC può indicare anemia da carenza di ferro. Si prega di consultare un medico."
    }}
  ],
  b12: [
    { range: [200, 900], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 200], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low Vitamin B12 levels may indicate a deficiency that could lead to anemia or neurological issues. Please consult a doctor.",
      it: "Bassi livelli di Vitamina B12 possono indicare una carenza che potrebbe portare ad anemia o problemi neurologici. Si prega di consultare un medico."
    }},
    { range: [900, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Vitamin B12 levels are uncommon but may indicate liver disease or excessive supplementation. Please consult a doctor.",
      it: "Alti livelli di Vitamina B12 sono rari ma possono indicare malattie epatiche o un'integrazione eccessiva. Si prega di consultare un medico."
    }}
  ],
  fa: [
    { range: [2.7, 17], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 2.7], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low Folic Acid levels may indicate a deficiency that could lead to anemia. Please consult a doctor.",
      it: "Bassi livelli di acido folico possono indicare una carenza che potrebbe portare ad anemia. Si prega di consultare un medico."
    }},
    { range: [17, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Folic Acid levels are uncommon and typically due to excessive supplementation. Please consult a doctor.",
      it: "Alti livelli di acido folico sono rari e tipicamente dovuti a un'integrazione eccessiva. Si prega di consultare un medico."
    }}
  ],
  d25: [
    { range: [30, 100], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 30], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low Vitamin D levels may indicate a deficiency that could affect bone health. Please consult a doctor.",
      it: "Bassi livelli di Vitamina D possono indicare una carenza che potrebbe influire sulla salute delle ossa. Si prega di consultare un medico."
    }},
    { range: [100, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Vitamin D levels may indicate excessive supplementation. Please consult a doctor.",
      it: "Alti livelli di Vitamina D possono indicare un'integrazione eccessiva. Si prega di consultare un medico."
    }}
  ],
  d3: [
    { range: [0, 10000], interpretation: { en: "normal", it: "normale" } },
    { range: [10000, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Cholecalciferol (Vitamin D3) levels may indicate excessive supplementation. Please consult a doctor.",
      it: "Alti livelli di colecalciferolo (Vitamina D3) possono indicare un'integrazione eccessiva. Si prega di consultare un medico."
    }}
  ],
  crp: [
    { range: [0, 3], interpretation: { en: "normal", it: "normale" } },
    { range: [3, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High CRP levels may indicate inflammation or infection. Please consult a doctor.",
      it: "Alti livelli di PCR possono indicare infiammazione o infezione. Si prega di consultare un medico."
    }}
  ],
  esr: [
    { range: [0, 20], interpretation: { en: "normal", it: "normale" } },
    { range: [20, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High ESR may indicate inflammation or chronic disease. Please consult a doctor.",
      it: "Alta VES può indicare infiammazione o malattia cronica. Si prega di consultare un medico."
    }}
  ],
  hba1c: [
    { range: [4, 5.6], interpretation: { en: "normal", it: "normale" } },
    { range: [5.7, 6.4], interpretation: { en: "prediabetes", it: "prediabete" }, advice: {
      en: "Elevated Hemoglobin A1c indicates a risk of developing diabetes. Lifestyle changes are recommended. Please consult a doctor.",
      it: "Emoglobina A1c elevata indica un rischio di sviluppare il diabete. Sono raccomandati cambiamenti nello stile di vita. Si prega di consultare un medico."
    }},
    { range: [6.5, Infinity], interpretation: { en: "diabetes", it: "diabete" }, advice: {
      en: "High Hemoglobin A1c indicates diabetes. Management and treatment are necessary. Please consult a doctor.",
      it: "Emoglobina A1c elevata indica diabete. Sono necessari la gestione e il trattamento. Si prega di consultare un medico."
    }}
  ],
  egfr: [
    { range: [60, Infinity], interpretation: { en: "normal", it: "normale" } },
    { range: [15, 59], interpretation: { en: "chronic kidney disease", it: "malattia renale cronica" }, advice: {
      en: "Reduced eGFR may indicate chronic kidney disease. Please consult a doctor.",
      it: "Ridotto eGFR può indicare malattia renale cronica. Si prega di consultare un medico."
    }},
    { range: [0, 15], interpretation: { en: "kidney failure", it: "insufficienza renale" }, advice: {
      en: "Severely reduced eGFR indicates kidney failure. Immediate medical attention is required.",
      it: "Ridotto gravemente eGFR indica insufficienza renale. È necessaria un'attenzione medica immediata."
    }}
  ],
  psa: [
    { range: [0, 4], interpretation: { en: "normal", it: "normale" } },
    { range: [4, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High PSA levels may indicate prostate issues, including cancer. Please consult a doctor.",
      it: "Alti livelli di PSA possono indicare problemi alla prostata, incluso il cancro. Si prega di consultare un medico."
    }}
  ],
  afp: [
    { range: [0, 10], interpretation: { en: "normal", it: "normale" } },
    { range: [10, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High AFP levels may indicate liver cancer or other malignancies. Please consult a doctor.",
      it: "Alti livelli di AFP possono indicare cancro al fegato o altre neoplasie. Si prega di consultare un medico."
    }}
  ],
  cea: [
    { range: [0, 3], interpretation: { en: "normal (non-smoker)", it: "normale (non fumatore)" } },
    { range: [3, 5], interpretation: { en: "normal (smoker)", it: "normale (fumatore)" } },
    { range: [5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High CEA levels may indicate cancer or other conditions. Please consult a doctor.",
      it: "Alti livelli di CEA possono indicare cancro o altre condizioni. Si prega di consultare un medico."
    }}
  ],
  ca125: [
    { range: [0, 35], interpretation: { en: "normal", it: "normale" } },
    { range: [35, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High CA-125 levels may indicate ovarian cancer or other conditions. Please consult a doctor.",
      it: "Alti livelli di CA-125 possono indicare cancro ovarico o altre condizioni. Si prega di consultare un medico."
    }}
  ],
  ca19_9: [
    { range: [0, 37], interpretation: { en: "normal", it: "normale" } },
    { range: [37, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High CA 19-9 levels may indicate pancreatic or gastrointestinal cancer. Please consult a doctor.",
      it: "Alti livelli di CA 19-9 possono indicare cancro pancreatico o gastrointestinale. Si prega di consultare un medico."
    }}
  ],
  hcy: [
    { range: [5, 15], interpretation: { en: "normal", it: "normale" } },
    { range: [15, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High homocysteine levels may increase the risk of cardiovascular disease. Please consult a doctor.",
      it: "Alti livelli di omocisteina possono aumentare il rischio di malattie cardiovascolari. Si prega di consultare un medico."
    }}
  ],
  lpa: [
    { range: [0, 30], interpretation: { en: "normal", it: "normale" } },
    { range: [30, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Lipoprotein(a) levels may increase the risk of cardiovascular disease. Please consult a doctor.",
      it: "Alti livelli di Lipoproteina(a) possono aumentare il rischio di malattie cardiovascolari. Si prega di consultare un medico."
    }}
  ],
  apob: [
    { range: [40, 125], interpretation: { en: "normal", it: "normale" } },
    { range: [125, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Apolipoprotein B levels may indicate a higher risk of cardiovascular disease. Please consult a doctor.",
      it: "Alti livelli di Apolipoproteina B possono indicare un rischio maggiore di malattie cardiovascolari. Si prega di consultare un medico."
    }}
  ],
  hscrp: [
    { range: [0, 1], interpretation: { en: "low risk", it: "basso rischio" } },
    { range: [1, 3], interpretation: { en: "average risk", it: "rischio medio" } },
    { range: [3, Infinity], interpretation: { en: "high risk", it: "alto rischio" }, advice: {
      en: "High levels of High-Sensitivity C-Reactive Protein (hs-CRP) may indicate an increased risk of cardiovascular disease or inflammation. Please consult a doctor.",
      it: "Alti livelli di Proteina C-Reattiva ad Alta Sensibilità (hs-CRP) possono indicare un aumento del rischio di malattie cardiovascolari o infiammazione. Si prega di consultare un medico."
    }}
  ],
  ntprobnp: [
    { range: [0, 125], interpretation: { en: "normal", it: "normale" } },
    { range: [125, Infinity], interpretation: { en: "elevated", it: "elevato" }, advice: {
      en: "Elevated NT-proBNP levels may indicate heart failure or cardiac stress. Please consult a doctor.",
      it: "Livelli elevati di NT-proBNP possono indicare insufficienza cardiaca o stress cardiaco. Si prega di consultare un medico."
    }}
  ],
  tn: [
    { range: [0, 0.04], interpretation: { en: "normal", it: "normale" } },
    { range: [0.04, Infinity], interpretation: { en: "elevated", it: "elevato" }, advice: {
      en: "Elevated Troponin levels may indicate myocardial injury or heart attack. Immediate medical attention is required.",
      it: "Livelli elevati di Troponina possono indicare lesioni miocardiche o infarto. È necessaria un'attenzione medica immediata."
    }}
  ],
  cort: [
    { range: [6, 23], interpretation: { en: "normal (morning)", it: "normale (mattina)" } },
    { range: [3, 17], interpretation: { en: "normal (afternoon)", it: "normale (pomeriggio)" } },
    { range: [0, 6], interpretation: { en: "low (morning)", it: "basso (mattina)" }, advice: {
      en: "Low cortisol levels may indicate adrenal insufficiency. Please consult a doctor.",
      it: "Livelli bassi di cortisolo possono indicare insufficienza surrenalica. Si prega di consultare un medico."
    }},
    { range: [0, 3], interpretation: { en: "low (afternoon)", it: "basso (pomeriggio)" }, advice: {
      en: "Low cortisol levels may indicate adrenal insufficiency. Please consult a doctor.",
      it: "Livelli bassi di cortisolo possono indicare insufficienza surrenalica. Si prega di consultare un medico."
    }},
    { range: [23, Infinity], interpretation: { en: "high (morning)", it: "alto (mattina)" }, advice: {
      en: "High cortisol levels may indicate Cushing's syndrome or stress. Please consult a doctor.",
      it: "Livelli alti di cortisolo possono indicare la sindrome di Cushing o stress. Si prega di consultare un medico."
    }},
    { range: [17, Infinity], interpretation: { en: "high (afternoon)", it: "alto (pomeriggio)" }, advice: {
      en: "High cortisol levels may indicate Cushing's syndrome or stress. Please consult a doctor.",
      it: "Livelli alti di cortisolo possono indicare la sindrome di Cushing o stress. Si prega di consultare un medico."
    }}
  ],
  testo: [
    { range: [300, 1000], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 300], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low testosterone levels may indicate hypogonadism or other conditions. Please consult a doctor.",
      it: "Bassi livelli di testosterone possono indicare ipogonadismo o altre condizioni. Si prega di consultare un medico."
    }},
    { range: [1000, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High testosterone levels may indicate hormonal imbalance or excessive supplementation. Please consult a doctor.",
      it: "Alti livelli di testosterone possono indicare squilibrio ormonale o un'integrazione eccessiva. Si prega di consultare un medico."
    }}
  ],
  e2: [
    { range: [15, 60], interpretation: { en: "normal (men)", it: "normale (uomini)" } },
    { range: [30, 400], interpretation: { en: "normal (premenopausal women)", it: "normale (donne in premenopausa)" } },
    { range: [0, 15], interpretation: { en: "low (men)", it: "basso (uomini)" }, advice: {
      en: "Low estradiol levels in men may indicate hormonal imbalance. Please consult a doctor.",
      it: "Bassi livelli di estradiolo negli uomini possono indicare squilibrio ormonale. Si prega di consultare un medico."
    }},
    { range: [0, 30], interpretation: { en: "low (premenopausal women)", it: "basso (donne in premenopausa)" }, advice: {
      en: "Low estradiol levels in premenopausal women may indicate hormonal imbalance or ovarian insufficiency. Please consult a doctor.",
      it: "Bassi livelli di estradiolo nelle donne in premenopausa possono indicare squilibrio ormonale o insufficienza ovarica. Si prega di consultare un medico."
    }},
    { range: [400, Infinity], interpretation: { en: "high (premenopausal women)", it: "alto (donne in premenopausa)" }, advice: {
      en: "High estradiol levels in premenopausal women may indicate estrogen dominance or other conditions. Please consult a doctor.",
      it: "Alti livelli di estradiolo nelle donne in premenopausa possono indicare dominanza estrogenica o altre condizioni. Si prega di consultare un medico."
    }},
    { range: [60, Infinity], interpretation: { en: "high (men)", it: "alto (uomini)" }, advice: {
      en: "High estradiol levels in men may indicate hormonal imbalance or liver disease. Please consult a doctor.",
      it: "Alti livelli di estradiolo negli uomini possono indicare squilibrio ormonale o malattie epatiche. Si prega di consultare un medico."
    }}
  ],
  prg: [
    { range: [0.1, 0.3], interpretation: { en: "normal (men)", it: "normale (uomini)" } },
    { range: [1.8, 24], interpretation: { en: "normal (women, luteal phase)", it: "normale (donne, fase luteale)" } },
    { range: [0, 0.1], interpretation: { en: "low (men)", it: "basso (uomini)" }, advice: {
      en: "Low progesterone levels in men are generally not a concern.",
      it: "Bassi livelli di progesterone negli uomini generalmente non sono preoccupanti."
    }},
    { range: [0, 1.8], interpretation: { en: "low (women, luteal phase)", it: "basso (donne, fase luteale)" }, advice: {
      en: "Low progesterone levels in the luteal phase may indicate luteal phase deficiency. Please consult a doctor.",
      it: "Bassi livelli di progesterone nella fase luteale possono indicare insufficienza luteale. Si prega di consultare un medico."
    }},
    { range: [0.3, Infinity], interpretation: { en: "high (men)", it: "alto (uomini)" }, advice: {
      en: "High progesterone levels in men may indicate adrenal or testicular tumors. Please consult a doctor.",
      it: "Alti livelli di progesterone negli uomini possono indicare tumori surrenali o testicolari. Si prega di consultare un medico."
    }},
    { range: [24, Infinity], interpretation: { en: "high (women, luteal phase)", it: "alto (donne, fase luteale)" }, advice: {
      en: "High progesterone levels in the luteal phase are generally not a concern but may require further evaluation if persistently high. Please consult a doctor.",
      it: "Alti livelli di progesterone nella fase luteale generalmente non sono preoccupanti, ma possono richiedere ulteriori valutazioni se persistentemente alti. Si prega di consultare un medico."
    }}
  ],
  dhea_s: [
    { range: [80, 560], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 80], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low DHEA-S levels may indicate adrenal insufficiency or hypopituitarism. Please consult a doctor.",
      it: "Bassi livelli di DHEA-S possono indicare insufficienza surrenalica o ipopituitarismo. Si prega di consultare un medico."
    }},
    { range: [560, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High DHEA-S levels may indicate adrenal hyperplasia or tumors. Please consult a doctor.",
      it: "Alti livelli di DHEA-S possono indicare iperplasia surrenalica o tumori. Si prega di consultare un medico."
    }}
  ],
  DHEA: [
    { range: [200, 600], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 200], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low DHEA levels may indicate adrenal insufficiency. Please consult an endocrinologist.",
      it: "Bassi livelli di DHEA possono indicare insufficienza surrenalica. Si prega di consultare un endocrinologo."
    }},
    { range: [600, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High DHEA levels may indicate adrenal hyperplasia or tumors. Please consult an endocrinologist.",
      it: "Alti livelli di DHEA possono indicare iperplasia surrenalica o tumori. Si prega di consultare un endocrinologo."
    }}
  ],
  oh_prg: [
    { range: [0.2, 3], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.2], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low 17-OH Progesterone levels may indicate adrenal insufficiency. Please consult an endocrinologist.",
      it: "Bassi livelli di 17-OH Progesterone possono indicare insufficienza surrenalica. Si prega di consultare un endocrinologo."
    }},
    { range: [3, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High 17-OH Progesterone levels may indicate congenital adrenal hyperplasia. Please consult an endocrinologist.",
      it: "Alti livelli di 17-OH Progesterone possono indicare iperplasia surrenale congenita. Si prega di consultare un endocrinologo."
    }}
  ],
  deoxycort: [
    { range: [0, 2], interpretation: { en: "normal", it: "normale" } },
    { range: [2, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High 21-Deoxycortisol levels may indicate congenital adrenal hyperplasia. Please consult an endocrinologist.",
      it: "Alti livelli di 21-Deossicortisolo possono indicare iperplasia surrenale congenita. Si prega di consultare un endocrinologo."
    }}
  ],
  dht: [
    { range: [30, 85], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 30], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low DHT levels may indicate 5-alpha reductase deficiency or androgen insensitivity. Please consult an endocrinologist.",
      it: "Bassi livelli di DHT possono indicare carenza di 5-alfa reduttasi o insensibilità agli androgeni. Si prega di consultare un endocrinologo."
    }},
    { range: [85, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High DHT levels may contribute to conditions like benign prostatic hyperplasia. Please consult a urologist or endocrinologist.",
      it: "Alti livelli di DHT possono contribuire a condizioni come l'iperplasia prostatica benigna. Si prega di consultare un urologo o un endocrinologo."
    }}
  ],
  ace: [
    { range: [8, 52], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 8], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low ACE levels are generally not a concern but may indicate certain conditions. Please consult a doctor for interpretation.",
      it: "Bassi livelli di ACE generalmente non sono preoccupanti ma possono indicare alcune condizioni. Si prega di consultare un medico per l'interpretazione."
    }},
    { range: [52, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High ACE levels may indicate sarcoidosis or other granulomatous diseases. Please consult a pulmonologist or rheumatologist.",
      it: "Alti livelli di ACE possono indicare sarcoidosi o altre malattie granulomatose. Si prega di consultare un pneumologo o un reumatologo."
    }}
  ],
  bile_acids: [
    { range: [0, 10], interpretation: { en: "normal", it: "normale" } },
    { range: [10, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High bile acid levels may indicate liver disease or bile duct obstruction. Please consult a gastroenterologist.",
      it: "Alti livelli di acidi biliari possono indicare malattie epatiche o ostruzione delle vie biliari. Si prega di consultare un gastroenterologo."
    }}
  ],
  methylmalonic_acid: [
    { range: [0, 0.4], interpretation: { en: "normal", it: "normale" } },
    { range: [0.4, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High methylmalonic acid levels may indicate vitamin B12 deficiency or certain metabolic disorders. Please consult a hematologist or metabolic specialist.",
      it: "Alti livelli di acido metilmalonico possono indicare carenza di vitamina B12 o alcuni disturbi metabolici. Si prega di consultare un ematologo o uno specialista metabolico."
    }}
  ],
  valproic_acid: [
    { range: [50, 100], interpretation: { en: "normal (therapeutic range)", it: "normale (range terapeutico)" } },
    { range: [0, 50], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low valproic acid levels may indicate insufficient dosage. Please consult your neurologist.",
      it: "Bassi livelli di acido valproico possono indicare un dosaggio insufficiente. Si prega di consultare il proprio neurologo."
    }},
    { range: [100, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High valproic acid levels may lead to toxicity. Please consult your neurologist immediately.",
      it: "Alti livelli di acido valproico possono portare a tossicità. Si prega di consultare immediatamente il proprio neurologo."
    }}
  ],
  acth: [
    { range: [7, 63], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 7], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low ACTH levels may indicate secondary adrenal insufficiency. Please consult an endocrinologist.",
      it: "Bassi livelli di ACTH possono indicare insufficienza surrenalica secondaria. Si prega di consultare un endocrinologo."
    }},
    { range: [63, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High ACTH levels may indicate Cushing's disease or primary adrenal insufficiency. Please consult an endocrinologist.",
      it: "Alti livelli di ACTH possono indicare malattia di Cushing o insufficienza surrenalica primaria. Si prega di consultare un endocrinologo."
    }}
  ],
  adh: [
    { range: [1, 5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 1], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low ADH levels may indicate diabetes insipidus. Please consult an endocrinologist.",
      it: "Bassi livelli di ADH possono indicare diabete insipido. Si prega di consultare un endocrinologo."
    }},
    { range: [5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High ADH levels may indicate syndrome of inappropriate ADH secretion (SIADH). Please consult an endocrinologist.",
      it: "Alti livelli di ADH possono indicare sindrome da inappropriata secrezione di ADH (SIADH). Si prega di consultare un endocrinologo."
    }}
  ],
  ald: [
    { range: [1, 7.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 1], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low aldolase levels are generally not a concern but may indicate certain conditions. Please consult a doctor for interpretation.",
      it: "Bassi livelli di aldolasi generalmente non sono preoccupanti ma possono indicare alcune condizioni. Si prega di consultare un medico per l'interpretazione."
    }},
    { range: [7.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High aldolase levels may indicate muscle damage or certain metabolic disorders. Please consult a neurologist or metabolic specialist.",
      it: "Alti livelli di aldolasi possono indicare danni muscolari o alcuni disturbi metabolici. Si prega di consultare un neurologo o uno specialista metabolico."
    }}
  ],
  ald_orto: [
    { range: [3, 16], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 3], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low aldosterone levels may indicate adrenal insufficiency. Please consult an endocrinologist.",
      it: "Bassi livelli di aldosterone possono indicare insufficienza surrenalica. Si prega di consultare un endocrinologo."
    }},
    { range: [16, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High aldosterone levels may indicate primary aldosteronism. Please consult an endocrinologist.",
      it: "Alti livelli di aldosterone possono indicare aldosteronismo primario. Si prega di consultare un endocrinologo."
    }}
  ],
  alpha1_acid_glycoprotein: [
    { range: [50, 120], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 50], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low alpha-1-acid glycoprotein levels are rare and may indicate certain conditions. Please consult a doctor for interpretation.",
      it: "Bassi livelli di alfa-1-glicoproteina acida sono rari e possono indicare alcune condizioni. Si prega di consultare un medico per l'interpretazione."
    }},
    { range: [120, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High alpha-1-acid glycoprotein levels may indicate inflammation or certain diseases. Please consult a doctor for further evaluation.",
      it: "Alti livelli di alfa-1-glicoproteina acida possono indicare infiammazione o alcune malattie. Si prega di consultare un medico per ulteriori valutazioni."
    }}
  ],
  alpha1_antitrypsin: [
    { range: [90, 200], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 90], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low alpha-1-antitrypsin levels may indicate alpha-1 antitrypsin deficiency. Please consult a pulmonologist or hepatologist.",
      it: "Bassi livelli di alfa-1-antitripsina possono indicare deficit di alfa-1 antitripsina. Si prega di consultare un pneumologo o un epatologo."
    }},
    { range: [200, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High alpha-1-antitrypsin levels may indicate inflammation or certain diseases. Please consult a doctor for further evaluation.",
      it: "Alti livelli di alfa-1-antitripsina possono indicare infiammazione o alcune malattie. Si prega di consultare un medico per ulteriori valutazioni."
    }}
  ],
  alpha_fetoprotein: [
    { range: [0, 10], interpretation: { en: "normal", it: "normale" } },
    { range: [10, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High alpha-fetoprotein levels may indicate liver disease, certain cancers, or pregnancy. Please consult a doctor for further evaluation.",
      it: "Alti livelli di alfa-fetoproteina possono indicare malattie epatiche, alcuni tipi di cancro o gravidanza. Si prega di consultare un medico per ulteriori valutazioni."
    }}
  ],
  amylasemia: [
    { range: [30, 110], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 30], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low amylase levels are generally not a concern but may indicate certain conditions. Please consult a doctor for interpretation.",
      it: "Bassi livelli di amilasi generalmente non sono preoccupanti ma possono indicare alcune condizioni. Si prega di consultare un medico per l'interpretazione."
    }},
    { range: [110, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High amylase levels may indicate pancreatitis or other pancreatic disorders. Please consult a gastroenterologist.",
      it: "Alti livelli di amilasi possono indicare pancreatite o altri disturbi pancreatici. Si prega di consultare un gastroenterologo."
    }}
  ],
  pancreatic_amylase: [
    { range: [13, 53], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 13], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low pancreatic amylase levels are generally not a concern but may indicate certain conditions. Please consult a doctor for interpretation.",
      it: "Bassi livelli di amilasi pancreatica generalmente non sono preoccupanti ma possono indicare alcune condizioni. Si prega di consultare un medico per l'interpretazione."
    }},
    { range: [53, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High pancreatic amylase levels may indicate pancreatitis or other pancreatic disorders. Please consult a gastroenterologist.",
      it: "Alti livelli di amilasi pancreatica possono indicare pancreatite o altri disturbi pancreatici. Si prega di consultare un gastroenterologo."
    }}
  ],
  amiodarone_metabolites: [
    { range: [0.5, 2.5], interpretation: { en: "normal (therapeutic range)", it: "normale (range terapeutico)" } },
    { range: [0, 0.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low amiodarone levels may indicate insufficient dosage. Please consult your cardiologist.",
      it: "Bassi livelli di amiodarone possono indicare un dosaggio insufficiente. Si prega di consultare il proprio cardiologo."
    }},
    { range: [2.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High amiodarone levels may lead to toxicity. Please consult your cardiologist immediately.",
      it: "Alti livelli di amiodarone possono portare a tossicità. Si prega di consultare immediatamente il proprio cardiologo."
    }}
  ],
  ammonemia: [
    { range: [11, 32], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 11], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low ammonia levels are generally not a concern. Please consult a doctor for interpretation if you have specific symptoms.",
      it: "Bassi livelli di ammoniaca generalmente non sono preoccupanti. Si prega di consultare un medico per l'interpretazione se si hanno sintomi specifici."
    }},
    { range: [32, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High ammonia levels may indicate liver disease or certain metabolic disorders. Please consult a hepatologist or metabolic specialist urgently.",
      it: "Alti livelli di ammoniaca possono indicare malattie epatiche o alcuni disturbi metabolici. Si prega di consultare urgentemente un epatologo o uno specialista metabolico."
    }}
  ],
  ANA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive ANA may indicate an autoimmune disorder. Please consult a rheumatologist for further evaluation.",
      it: "ANA positivi possono indicare un disturbo autoimmune. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  TROMBO: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive result indicates a genetic predisposition to thrombophilia. Please consult a hematologist for further evaluation and management.",
      it: "Un risultato positivo indica una predisposizione genetica alla trombofilia. Si prega di consultare un ematologo per ulteriori valutazioni e gestione."
    }}
  ],
  ADG: [
    { range: [0.5, 3.0], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low ADG levels may indicate decreased androgen activity. Please consult an endocrinologist.",
      it: "Bassi livelli di ADG possono indicare una diminuzione dell'attività androgenica. Si prega di consultare un endocrinologo."
    }},
    { range: [3.0, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High ADG levels may indicate increased androgen activity. Please consult an endocrinologist.",
      it: "Alti livelli di ADG possono indicare un aumento dell'attività androgenica. Si prega di consultare un endocrinologo."
    }}
  ],
  ANDRO: [
    { range: [0.5, 2.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 0.5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low androstenedione levels may indicate adrenal or gonadal insufficiency. Please consult an endocrinologist.",
      it: "Bassi livelli di androstenedione possono indicare insufficienza surrenalica o gonadica. Si prega di consultare un endocrinologo."
    }},
    { range: [2.5, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High androstenedione levels may indicate congenital adrenal hyperplasia or androgen-secreting tumors. Please consult an endocrinologist.",
      it: "Alti livelli di androstenedione possono indicare iperplasia surrenale congenita o tumori secernenti androgeni. Si prega di consultare un endocrinologo."
    }}
  ],
  ANISAKIS_IgG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anisakis IgG indicates past exposure to Anisakis parasites. Please consult a gastroenterologist if you have related symptoms.",
      it: "Anisakis IgG positivo indica una passata esposizione ai parassiti Anisakis. Si prega di consultare un gastroenterologo se si manifestano sintomi correlati."
    }}
  ],
  AAIgA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Actin IgA may indicate autoimmune hepatitis. Please consult a hepatologist for further evaluation.",
      it: "Anti-Actina IgA positivo può indicare epatite autoimmune. Si prega di consultare un epatologo per ulteriori valutazioni."
    }}
  ],
  AAIgG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Actin IgG may indicate autoimmune hepatitis. Please consult a hepatologist for further evaluation.",
      it: "Anti-Actina IgG positivo può indicare epatite autoimmune. Si prega di consultare un epatologo per ulteriori valutazioni."
    }}
  ],
  AAN: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Neuronal Antigens may indicate paraneoplastic neurological syndromes. Please consult a neurologist for further evaluation.",
      it: "Anti-Antigeni Neuronali positivi possono indicare sindromi neurologiche paraneoplastiche. Si prega di consultare un neurologo per ulteriori valutazioni."
    }}
  ],
  ABGPIgA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Beta 2 Glycoprotein I IgA may indicate antiphospholipid syndrome. Please consult a rheumatologist for further evaluation.",
      it: "Anti-Beta 2 Glicoproteina I IgA positivo può indicare sindrome da antifosfolipidi. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ABGPIgG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Beta 2 Glycoprotein I IgG may indicate antiphospholipid syndrome. Please consult a rheumatologist for further evaluation.",
      it: "Anti-Beta 2 Glicoproteina I IgG positivo può indicare sindrome da antifosfolipidi. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ABGPIgM: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Beta 2 Glycoprotein I IgM may indicate antiphospholipid syndrome. Please consult a rheumatologist for further evaluation.",
      it: "Anti-Beta 2 Glicoproteina I IgM positivo può indicare sindrome da antifosfolipidi. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ABP180: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-BP180 may indicate bullous pemphigoid. Please consult a dermatologist for further evaluation.",
      it: "Anti-BP180 positivo può indicare pemfigoide bolloso. Si prega di consultare un dermatologo per ulteriori valutazioni."
    }}
  ],
  ABP230: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-BP230 may indicate bullous pemphigoid. Please consult a dermatologist for further evaluation.",
      it: "Anti-BP230 positivo può indicare pemfigoide bolloso. Si prega di consultare un dermatologo per ulteriori valutazioni."
    }}
  ],
  ABRUC: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Brucella IgG/IgM may indicate brucellosis. Please consult an infectious disease specialist for further evaluation and treatment.",
      it: "Anti-Brucelle IgG/IgM positivo può indicare brucellosi. Si prega di consultare uno specialista in malattie infettive per ulteriori valutazioni e trattamento."
    }}
  ],
  AC1Q: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-C1Q may be associated with systemic lupus erythematosus or other autoimmune diseases. Please consult a rheumatologist for further evaluation.",
      it: "Anti-C1Q positivo può essere associato al lupus eritematoso sistemico o altre malattie autoimmuni. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ACPG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Parietal Cell Antibodies may indicate pernicious anemia or autoimmune gastritis. Please consult a gastroenterologist for further evaluation.",
      it: "Anticorpi Anti-Cellule Parietali Gastriche positivi possono indicare anemia perniciosa o gastrite autoimmune. Si prega di consultare un gastroenterologo per ulteriori valutazioni."
    }}
  ],
  ACEN: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Centromere antibodies may indicate limited systemic sclerosis (CREST syndrome) or other autoimmune conditions. Please consult a rheumatologist for further evaluation.",
      it: "Anticorpi Anti-Centromero positivi possono indicare sclerosi sistemica limitata (sindrome CREST) o altre condizioni autoimmuni. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ACLAP_IgA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Chlamydia Pneumoniae IgA may indicate a current or recent infection. Please consult your doctor for interpretation in the context of your symptoms and medical history.",
      it: "Anti-Clamidia Pneumoniae IgA positivo può indicare un'infezione in corso o recente. Si prega di consultare il medico per l'interpretazione nel contesto dei sintomi e della storia clinica."
    }}
  ],
  ACLAP_IgG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Chlamydia Pneumoniae IgG indicates past exposure or infection. This is common and often doesn't require treatment. Consult your doctor if you have ongoing symptoms.",
      it: "Anti-Clamidia Pneumoniae IgG positivo indica un'esposizione o infezione passata. Questo è comune e spesso non richiede trattamento. Consultare il medico in caso di sintomi persistenti."
    }}
  ],
  ACLAP_IgM: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Chlamydia Pneumoniae IgM may indicate a current or recent infection. Please consult your doctor for potential treatment and follow-up.",
      it: "Anti-Clamidia Pneumoniae IgM positivo può indicare un'infezione in corso o recente. Si prega di consultare il medico per un potenziale trattamento e follow-up."
    }}
  ],
  ACLAT_IgA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Chlamydia Trachomatis IgA may indicate a current or chronic infection. Please consult your doctor for further evaluation and potential treatment.",
      it: "Anti-Clamidia Trachomatis IgA positivo può indicare un'infezione in corso o cronica. Si prega di consultare il medico per ulteriori valutazioni e potenziale trattamento."
    }}
  ],
  ACLAT_IgG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Chlamydia Trachomatis IgG indicates past exposure or infection. Consult your doctor for interpretation, especially if you have ongoing symptoms or concerns about fertility.",
      it: "Anti-Clamidia Trachomatis IgG positivo indica un'esposizione o infezione passata. Consultare il medico per l'interpretazione, specialmente in caso di sintomi persistenti o preoccupazioni sulla fertilità."
    }}
  ],
  ACLAT_IgM: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Chlamydia Trachomatis IgM may indicate a current or recent infection. Please consult your doctor immediately for evaluation and treatment.",
      it: "Anti-Clamidia Trachomatis IgM positivo può indicare un'infezione in corso o recente. Si prega di consultare immediatamente il medico per valutazione e trattamento."
    }}
  ],
  ADES1: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Desmoglein 1 antibodies may indicate pemphigus foliaceus. Please consult a dermatologist for further evaluation and management.",
      it: "Anticorpi Anti-Desmogleina 1 positivi possono indicare pemfigo foliaceo. Si prega di consultare un dermatologo per ulteriori valutazioni e gestione."
    }}
  ],
  ADES3: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Desmoglein 3 antibodies may indicate pemphigus vulgaris. Please consult a dermatologist for further evaluation and management.",
      it: "Anticorpi Anti-Desmogleina 3 positivi possono indicare pemfigo volgare. Si prega di consultare un dermatologo per ulteriori valutazioni e gestione."
    }}
  ],
  ADIF: [
    { result: "negative", interpretation: { en: "may indicate insufficient immunity", it: "può indicare immunità insufficiente" }, advice: {
      en: "Negative Anti-Diphtheria Antibody may indicate insufficient immunity. Please consult your doctor about the need for a diphtheria booster vaccination.",
      it: "Anticorpi Anti-Difterite negativi possono indicare un'immunità insufficiente. Si prega di consultare il medico sulla necessità di un richiamo del vaccino antidifterico."
    }},
    { result: "positive", interpretation: { en: "indicates immunity", it: "indica immunità" }, advice: {
      en: "Positive Anti-Diphtheria Antibody indicates immunity to diphtheria. Continue to follow routine vaccination schedules as recommended by your healthcare provider.",
      it: "Anticorpi Anti-Difterite positivi indicano immunità alla difterite. Continuare a seguire i programmi di vaccinazione di routine come raccomandato dal proprio medico."
    }}
  ],
  ADNA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-DNA antibodies are strongly associated with systemic lupus erythematosus (SLE). Please consult a rheumatologist for further evaluation and management.",
      it: "Anticorpi Anti-DNA positivi sono fortemente associati al lupus eritematoso sistemico (LES). Si prega di consultare un reumatologo per ulteriori valutazioni e gestione."
    }}
  ],
  AFI_IgG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Intrinsic Factor IgG is highly specific for pernicious anemia. Please consult a hematologist or gastroenterologist for further evaluation and management.",
      it: "Anti-Fattore Intrinseco IgG positivo è altamente specifico per l'anemia perniciosa. Si prega di consultare un ematologo o gastroenterologo per ulteriori valutazioni e gestione."
    }}
  ],
  AGAD: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-GAD antibodies may indicate type 1 diabetes or other autoimmune conditions. Please consult an endocrinologist for further evaluation.",
      it: "Anticorpi Anti-GAD positivi possono indicare diabete di tipo 1 o altre condizioni autoimmuni. Si prega di consultare un endocrinologo per ulteriori valutazioni."
    }}
  ],
  AGAN: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Ganglioside antibodies may be associated with various neurological disorders, including Guillain-Barré syndrome. Please consult a neurologist for further evaluation.",
      it: "Anticorpi Anti-Gangliosidi positivi possono essere associati a vari disturbi neurologici, inclusa la sindrome di Guillain-Barré. Si prega di consultare un neurologo per ulteriori valutazioni."
    }}
  ],
  AHHV6_IgG: [
    { result: "negative", interpretation: { en: "no past exposure", it: "nessuna esposizione passata" } },
    { result: "positive", interpretation: { en: "past exposure or infection", it: "esposizione o infezione passata" }, advice: {
      en: "Positive Anti-HHV-6 IgG indicates past exposure to Human Herpesvirus 6. This is common and usually doesn't require treatment. Consult your doctor if you have specific concerns.",
      it: "Anti-HHV-6 IgG positivo indica un'esposizione passata all'Herpesvirus Umano 6. Questo è comune e di solito non richiede trattamento. Consultare il medico in caso di preoccupazioni specifiche."
    }}
  ],
  AICA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Islet Cell Antibodies may indicate type 1 diabetes or risk of developing it. Please consult an endocrinologist for further evaluation and management.",
      it: "Anticorpi Anti-Insula Pancreatica positivi possono indicare diabete di tipo 1 o il rischio di svilupparlo. Si prega di consultare un endocrinologo per ulteriori valutazioni e gestione."
    }}
  ],
  AHIS: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Histone antibodies may be associated with drug-induced lupus or other autoimmune conditions. Please consult a rheumatologist for further evaluation.",
      it: "Anticorpi Anti-Istoni positivi possono essere associati a lupus indotto da farmaci o altre condizioni autoimmuni. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ALIS: [
    { result: "negative", interpretation: { en: "no past exposure", it: "nessuna esposizione passata" } },
    { result: "positive", interpretation: { en: "past exposure or infection", it: "esposizione o infezione passata" }, advice: {
      en: "Positive Anti-Listeria IgG indicates past exposure to Listeria. This doesn't necessarily indicate current infection. Consult your doctor if you have specific concerns or symptoms.",
      it: "Anti-Listeria IgG positivo indica un'esposizione passata alla Listeria. Questo non indica necessariamente un'infezione in corso. Consultare il medico in caso di preoccupazioni o sintomi specifici."
    }}
  ],
  AMPO: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-MPO antibodies may indicate ANCA-associated vasculitis. Please consult a rheumatologist or nephrologist for urgent evaluation and management.",
      it: "Anticorpi Anti-MPO positivi possono indicare vasculite ANCA-associata. Si prega di consultare urgentemente un reumatologo o nefrologo per valutazione e gestione."
    }}
  ],
  AMUSK: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-MuSK antibodies are associated with a specific form of myasthenia gravis. Please consult a neurologist for urgent evaluation and management.",
      it: "Anticorpi Anti-MuSK positivi sono associati a una forma specifica di miastenia gravis. Si prega di consultare urgentemente un neurologo per valutazione e gestione."
    }}
  ],
  ANUC: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Nucleosome antibodies may be associated with systemic lupus erythematosus. Please consult a rheumatologist for further evaluation.",
      it: "Anticorpi Anti-Nucleosomi positivi possono essere associati al lupus eritematoso sistemico. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  APR3: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-PR3 antibodies may indicate ANCA-associated vasculitis, particularly granulomatosis with polyangiitis. Please consult a rheumatologist or nephrologist for urgent evaluation.",
      it: "Anticorpi Anti-PR3 positivi possono indicare vasculite ANCA-associata, in particolare granulomatosi con poliangite. Si prega di consultare urgentemente un reumatologo o nefrologo per valutazione."
    }}
  ],
  AR_TSH: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-TSH Receptor antibodies are associated with Graves' disease. Please consult an endocrinologist for further evaluation and management.",
      it: "Anticorpi Anti-Recettori TSH positivi sono associati al morbo di Graves. Si prega di consultare un endocrinologo per ulteriori valutazioni e gestione."
    }}
  ],
  ASLA_LP: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-SLA/LP antibodies are highly specific for autoimmune hepatitis. Please consult a hepatologist for further evaluation and management.",
      it: "Anticorpi Anti-SLA/LP positivi sono altamente specifici per l'epatite autoimmune. Si prega di consultare un epatologo per ulteriori valutazioni e gestione."
    }}
  ],
  ASUR: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Adrenal Antibodies may indicate Addison's disease or other autoimmune adrenal conditions. Please consult an endocrinologist for further evaluation and management.",
      it: "Anticorpi Anti-Surrene positivi possono indicare il morbo di Addison o altre condizioni autoimmuni delle ghiandole surrenali. Si prega di consultare un endocrinologo per ulteriori valutazioni e gestione."
    }}
  ],
  ATIREO: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Thyroglobulin antibodies may indicate autoimmune thyroid disease. Please consult an endocrinologist for further evaluation.",
      it: "Anticorpi Anti-Tireoglobulina positivi possono indicare una malattia autoimmune della tiroide. Si prega di consultare un endocrinologo per ulteriori valutazioni."
    }}
  ],
  ATOX_IgA: [
    { result: "negative", interpretation: { en: "no recent infection", it: "nessuna infezione recente" } },
    { result: "positive", interpretation: { en: "possible recent infection", it: "possibile infezione recente" }, advice: {
      en: "Positive Anti-Toxoplasma IgA may indicate a recent or active Toxoplasma infection. Please consult an infectious disease specialist, especially if pregnant or immunocompromised.",
      it: "Anti-Toxo IgA positivo può indicare un'infezione recente o attiva da Toxoplasma. Si prega di consultare uno specialista in malattie infettive, specialmente se in gravidanza o immunocompromessi."
    }}
  ],
  ATOX_IGG_AVIDITY: [
    { result: "low avidity", interpretation: { en: "recent infection", it: "infezione recente" }, advice: {
      en: "Low avidity Anti-Toxoplasma IgG suggests a recent infection. Please consult an infectious disease specialist, especially if pregnant.",
      it: "Anti-Toxo IgG a bassa avidità suggerisce un'infezione recente. Si prega di consultare uno specialista in malattie infettive, specialmente se in gravidanza."
    }},
    { result: "high avidity", interpretation: { en: "past infection", it: "infezione passata" }, advice: {
      en: "High avidity Anti-Toxoplasma IgG suggests a past infection. Usually no further action is needed, but consult your doctor if you have concerns.",
      it: "Anti-Toxo IgG ad alta avidità suggerisce un'infezione passata. Di solito non sono necessarie ulteriori azioni, ma consultare il medico in caso di preoccupazioni."
    }}
  ],
  ATRE: [
    { result: "negative", interpretation: { en: "no evidence of syphilis", it: "nessuna evidenza di sifilide" } },
    { result: "positive", interpretation: { en: "possible syphilis infection", it: "possibile infezione da sifilide" }, advice: {
      en: "Positive Anti-Treponema Pallidum may indicate a current or past syphilis infection. Please consult an infectious disease specialist or dermatologist for further evaluation and treatment.",
      it: "Anti-Treponema Pallidum positivo può indicare un'infezione da sifilide attuale o passata. Si prega di consultare uno specialista in malattie infettive o un dermatologo per ulteriori valutazioni e trattamento."
    }}
  ],
  AAIAA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Insulin Autoantibodies may indicate type 1 diabetes or insulin autoimmune syndrome. Please consult an endocrinologist for further evaluation.",
      it: "Anticorpi Anti-Insulina positivi possono indicare diabete di tipo 1 o sindrome autoimmune dell'insulina. Si prega di consultare un endocrinologo per ulteriori valutazioni."
    }}
  ],
  AMAG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-MAG antibodies may indicate peripheral neuropathy associated with monoclonal gammopathy. Please consult a neurologist for further evaluation.",
      it: "Anticorpi Anti-MAG positivi possono indicare una neuropatia periferica associata a gammopatia monoclonale. Si prega di consultare un neurologo per ulteriori valutazioni."
    }}
  ],
  ATF: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Tyrosine Phosphatase (IA-2) antibodies may indicate type 1 diabetes or risk of developing it. Please consult an endocrinologist for further evaluation.",
      it: "Anticorpi Anti-Tirosina Fosfatasi (IA2) positivi possono indicare diabete di tipo 1 o il rischio di svilupparlo. Si prega di consultare un endocrinologo per ulteriori valutazioni."
    }}
  ],
  AAQU: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Aquaporin Antibodies may indicate neuromyelitis optica spectrum disorder. Please consult a neurologist urgently for further evaluation and management.",
      it: "Anticorpi Antiacquaporina positivi possono indicare un disturbo dello spettro della neuromielite ottica. Si prega di consultare urgentemente un neurologo per ulteriori valutazioni e gestione."
    }}
  ],
  ANTIBAC_BACILLUS: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The antibiogram shows resistance to some antibiotics. Please consult an infectious disease specialist for appropriate treatment selection.",
      it: "L'antibiogramma mostra resistenza ad alcuni antibiotici. Si prega di consultare uno specialista in malattie infettive per la selezione del trattamento appropriato."
    }}
  ],
  ANTIBAC_ECOLI: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The E. Coli antibiogram shows resistance to some antibiotics. Please consult an infectious disease specialist for appropriate treatment selection.",
      it: "L'antibiogramma di E. Coli mostra resistenza ad alcuni antibiotici. Si prega di consultare uno specialista in malattie infettive per la selezione del trattamento appropriato."
    }}
  ],
  ANTIBAC_ESP_1: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The sputum antibiogram shows resistance to some antibiotics. Please consult a pulmonologist or infectious disease specialist for appropriate treatment selection.",
      it: "L'antibiogramma dell'espettorato mostra resistenza ad alcuni antibiotici. Si prega di consultare un pneumologo o uno specialista in malattie infettive per la selezione del trattamento appropriato."
    }}
  ],
  ANTIBAC_ESP_2: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The sputum antibiogram shows resistance to some antibiotics. Please consult a pulmonologist or infectious disease specialist for appropriate treatment selection.",
      it: "L'antibiogramma dell'espettorato mostra resistenza ad alcuni antibiotici. Si prega di consultare un pneumologo o uno specialista in malattie infettive per la selezione del trattamento appropriato."
    }}
  ],
  ANTIBAC_ENT_FAECALIS: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The Enterococcus faecalis antibiogram shows resistance to some antibiotics. Please consult an infectious disease specialist for appropriate treatment selection.",
      it: "L'antibiogramma di Enterococcus faecalis mostra resistenza ad alcuni antibiotici. Si prega di consultare uno specialista in malattie infettive per la selezione del trattamento appropriato."
    }}
  ],
  ANTIBAC_GRAM_NEG: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The antibiogram shows resistance in Gram-negative bacteria. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma mostra resistenza nei batteri Gram-negativi. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_GRAM_POS: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The antibiogram shows resistance in Gram-positive bacteria. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma mostra resistenza nei batteri Gram-positivi. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_GRAM_PED: [
    { result: "sensitive", interpretation: { en: "treatable with standard pediatric antibiotics", it: "trattabile con antibiotici pediatrici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized pediatric treatment", it: "può richiedere un trattamento pediatrico specializzato" }, advice: {
      en: "The pediatric Gram-negative antibiogram shows resistance. Please consult a pediatric infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma pediatrico Gram-negativo mostra resistenza. Si prega di consultare uno specialista pediatrico in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_HAEMO: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The Haemophilus influenzae antibiogram shows resistance. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma di Haemophilus influenzae mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_MIC: [
    { result: "low MIC", interpretation: { en: "highly sensitive to antibiotics", it: "altamente sensibile agli antibiotici" } },
    { result: "high MIC", interpretation: { en: "resistant to some antibiotics", it: "resistente ad alcuni antibiotici" }, advice: {
      en: "The antibiogram shows high Minimum Inhibitory Concentrations for some antibiotics. Please consult an infectious disease specialist for appropriate treatment.",
      it: "L'antibiogramma mostra alte Concentrazioni Minime Inibenti per alcuni antibiotici. Si prega di consultare uno specialista in malattie infettive per un trattamento appropriato."
    }}
  ],
  ANTIBAC_MYCO_UREA: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The Mycoplasma/Ureaplasma antibiogram shows resistance. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma di Mycoplasma/Ureaplasma mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_NEISS: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The Neisseria gonorrhoeae antibiogram shows resistance. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma di Neisseria gonorrhoeae mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_PSEUD: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The Pseudomonas antibiogram shows resistance. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma di Pseudomonas mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_SEC_LING_2: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The lingual secretion antibiogram shows resistance. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma del secreto linguale mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_STREP_PNEU: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The Streptococcus pneumoniae antibiogram shows resistance. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma di Streptococcus pneumoniae mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_STREP_PYO: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The Streptococcus pyogenes antibiogram shows resistance. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma di Streptococcus pyogenes mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ANTIBAC_TAMP_FAR: [
    { result: "sensitive", interpretation: { en: "treatable with standard antibiotics", it: "trattabile con antibiotici standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The pharyngeal swab antibiogram shows resistance. Please consult an infectious disease specialist for appropriate antibiotic selection.",
      it: "L'antibiogramma del tampone faringeo mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antibiotici."
    }}
  ],
  ACLA_IgA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Cardiolipin IgA may indicate antiphospholipid syndrome. Please consult a rheumatologist for further evaluation.",
      it: "Anticardiolipina IgA positiva può indicare sindrome da antifosfolipidi. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ACLA_IgG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Cardiolipin IgG may indicate antiphospholipid syndrome. Please consult a rheumatologist for further evaluation.",
      it: "Anticardiolipina IgG positiva può indicare sindrome da antifosfolipidi. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ACIT: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Citrulline antibodies are associated with rheumatoid arthritis. Please consult a rheumatologist for further evaluation.",
      it: "Anticorpi Anticitrullina positivi sono associati all'artrite reumatoide. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  ALAC: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Lupus Anticoagulant may indicate antiphospholipid syndrome. Please consult a rheumatologist or hematologist for further evaluation.",
      it: "Anticoagulante Lupus Like positivo può indicare sindrome da antifosfolipidi. Si prega di consultare un reumatologo o un ematologo per ulteriori valutazioni."
    }}
  ],
  AAQU_IgG: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Aquaporin IgG antibodies may indicate neuromyelitis optica spectrum disorder. Please consult a neurologist urgently for further evaluation.",
      it: "Anticorpi Anti Acquaporina IgG positivi possono indicare un disturbo dello spettro della neuromielite ottica. Si prega di consultare urgentemente un neurologo per ulteriori valutazioni."
    }}
  ],
  ANCA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Cytoplasmic Antibodies (ANCA) may indicate ANCA-associated vasculitis. Please consult a rheumatologist or nephrologist for further evaluation.",
      it: "Anticorpi Anti Citoplasma (ANCA) positivi possono indicare vasculite ANCA-associata. Si prega di consultare un reumatologo o un nefrologo per ulteriori valutazioni."
    }}
  ],
  AHTLV: [
    { result: "negative", interpretation: { en: "no evidence of HTLV infection", it: "nessuna evidenza di infezione da HTLV" } },
    { result: "positive", interpretation: { en: "evidence of HTLV infection", it: "evidenza di infezione da HTLV" }, advice: {
      en: "Positive Anti-HTLV I/II antibodies indicate HTLV infection. Please consult an infectious disease specialist for further evaluation and management.",
      it: "Anticorpi Anti HTLV I/II positivi indicano un'infezione da HTLV. Si prega di consultare uno specialista in malattie infettive per ulteriori valutazioni e gestione."
    }}
  ],
  AANA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Nuclear Antibodies (ANA) may indicate an autoimmune disorder. Please consult a rheumatologist for further evaluation.",
      it: "Anticorpi Anti Nucleo (ANA) positivi possono indicare un disturbo autoimmune. Si prega di consultare un reumatologo per ulteriori valutazioni."
    }}
  ],
  APAR_IgM: [
    { result: "negative", interpretation: { en: "no evidence of recent mumps infection", it: "nessuna evidenza di recente infezione da parotite" } },
    { result: "positive", interpretation: { en: "possible recent mumps infection", it: "possibile recente infezione da parotite" }, advice: {
      en: "Positive Anti-Mumps IgM may indicate a recent mumps infection. Please consult a doctor for further evaluation and potential public health measures.",
      it: "Anticorpi Anti Parotite IgM positivi possono indicare una recente infezione da parotite. Si prega di consultare un medico per ulteriori valutazioni e potenziali misure di salute pubblica."
    }}
  ],
  APAR_IgG: [
    { result: "negative", interpretation: { en: "no immunity to mumps", it: "nessuna immunità alla parotite" }, advice: {
      en: "Consider vaccination against mumps if not previously vaccinated.",
      it: "Considerare la vaccinazione contro la parotite se non precedentemente vaccinati."
    }},
    { result: "positive", interpretation: { en: "immunity to mumps", it: "immunità alla parotite" } }
  ],
  APF4: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-PF4 Antibodies may indicate heparin-induced thrombocytopenia. Please consult a hematologist urgently.",
      it: "Anticorpi Anti PF4 positivi possono indicare trombocitopenia indotta da eparina. Si prega di consultare urgentemente un ematologo."
    }}
  ],
  APIAST: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Platelet Antibodies may indicate immune thrombocytopenia. Please consult a hematologist for further evaluation.",
      it: "Anticorpi Anti Piastrine positivi possono indicare trombocitopenia immune. Si prega di consultare un ematologo per ulteriori valutazioni."
    }}
  ],
  AADEN_IgA: [
    { result: "negative", interpretation: { en: "no evidence of recent adenovirus infection", it: "nessuna evidenza di recente infezione da adenovirus" } },
    { result: "positive", interpretation: { en: "possible recent adenovirus infection", it: "possibile recente infezione da adenovirus" }, advice: {
      en: "Positive Anti-Adenovirus IgA may indicate a recent adenovirus infection. Please consult a doctor if you have ongoing symptoms.",
      it: "Anticorpi Antiadenovirus IgA positivi possono indicare una recente infezione da adenovirus. Si prega di consultare un medico se si hanno sintomi persistenti."
    }}
  ],
  AADEN_IgG: [
    { result: "negative", interpretation: { en: "no past exposure to adenovirus", it: "nessuna esposizione passata all'adenovirus" } },
    { result: "positive", interpretation: { en: "past exposure to adenovirus", it: "esposizione passata all'adenovirus" } }
  ],
  AEPA: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Liver Antigen Antibodies may indicate autoimmune liver disease. Please consult a hepatologist for further evaluation.",
      it: "Anticorpi Anti Antigeni Epatici positivi possono indicare una malattia epatica autoimmune. Si prega di consultare un epatologo per ulteriori valutazioni."
    }}
  ],
  AERIT: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Erythrocyte Antibodies may indicate autoimmune hemolytic anemia. Please consult a hematologist for further evaluation.",
      it: "Anticorpi Antieritrocitari positivi possono indicare anemia emolitica autoimmune. Si prega di consultare un ematologo per ulteriori valutazioni."
    }}
  ],
  AAMUS: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Striated Muscle Antibodies may indicate myasthenia gravis or thymoma. Please consult a neurologist for further evaluation.",
      it: "Anticorpi Antimuscolo Striato positivi possono indicare miastenia gravis o timoma. Si prega di consultare un neurologo per ulteriori valutazioni."
    }}
  ],
  AMIC: [
    { result: "sensitive", interpretation: { en: "treatable with standard antifungals", it: "trattabile con antifungini standard" } },
    { result: "resistant", interpretation: { en: "may require specialized treatment", it: "può richiedere un trattamento specializzato" }, advice: {
      en: "The antimycogram shows resistance. Please consult an infectious disease specialist for appropriate antifungal selection.",
      it: "L'antimicogramma mostra resistenza. Si prega di consultare uno specialista in malattie infettive per la selezione appropriata degli antifungini."
    }}
  ],
  ANEU: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Neuronal Nucleus Antibodies may indicate paraneoplastic neurological syndrome. Please consult a neurologist urgently.",
      it: "Anticorpi Antinucleo Neuronale positivi possono indicare una sindrome neurologica paraneoplastica. Si prega di consultare urgentemente un neurologo."
    }}
  ],
  ARACH: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Acetylcholine Receptor Antibodies strongly suggest myasthenia gravis. Please consult a neurologist urgently.",
      it: "Anticorpi Antirecettore ACh positivi suggeriscono fortemente la miastenia gravis. Si prega di consultare urgentemente un neurologo."
    }}
  ],
  ATIII: [
    { result: "normal", interpretation: { en: "normal antithrombin III levels", it: "livelli normali di antitrombina III" } },
    { result: "low", interpretation: { en: "low antithrombin III levels", it: "bassi livelli di antitrombina III" }, advice: {
      en: "Low Antithrombin III levels may indicate increased risk of thrombosis. Please consult a hematologist for further evaluation.",
      it: "Bassi livelli di Antitrombina III possono indicare un aumentato rischio di trombosi. Si prega di consultare un ematologo per ulteriori valutazioni."
    }}
  ],
  ABO: [
    { result: "A", interpretation: { en: "Blood group A", it: "Gruppo sanguigno A" } },
    { result: "B", interpretation: { en: "Blood group B", it: "Gruppo sanguigno B" } },
    { result: "AB", interpretation: { en: "Blood group AB", it: "Gruppo sanguigno AB" } },
    { result: "O", interpretation: { en: "Blood group O", it: "Gruppo sanguigno O" } }
  ],
  RH: [
    { result: "positive", interpretation: { en: "Rh positive", it: "Rh positivo" } },
    { result: "negative", interpretation: { en: "Rh negative", it: "Rh negativo" }, advice: {
      en: "Rh negative individuals may need special consideration during pregnancy or blood transfusions.",
      it: "Gli individui Rh negativi possono richiedere considerazioni speciali durante la gravidanza o le trasfusioni di sangue."
    }}
  ],
  FSH: [
    { range: [3, 20], interpretation: { en: "normal (premenopausal)", it: "normale (premenopausa)" } },
    { range: [0, 3], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low FSH levels may indicate pituitary dysfunction. Please consult an endocrinologist.",
      it: "Bassi livelli di FSH possono indicare una disfunzione ipofisaria. Si prega di consultare un endocrinologo."
    }},
    { range: [20, Infinity], interpretation: { en: "high (may indicate menopause)", it: "alto (può indicare menopausa)" }, advice: {
      en: "High FSH levels may indicate menopause or ovarian failure. Please consult a gynecologist or endocrinologist.",
      it: "Alti livelli di FSH possono indicare menopausa o insufficienza ovarica. Si prega di consultare un ginecologo o un endocrinologo."
    }}
  ],
  LH: [
    { range: [5, 25], interpretation: { en: "normal (premenopausal)", it: "normale (premenopausa)" } },
    { range: [0, 5], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low LH levels may indicate pituitary dysfunction. Please consult an endocrinologist.",
      it: "Bassi livelli di LH possono indicare una disfunzione ipofisaria. Si prega di consultare un endocrinologo."
    }},
    { range: [25, Infinity], interpretation: { en: "high (may indicate menopause)", it: "alto (può indicare menopausa)" }, advice: {
      en: "High LH levels may indicate menopause or ovarian failure. Please consult a gynecologist or endocrinologist.",
      it: "Alti livelli di LH possono indicare menopausa o insufficienza ovarica. Si prega di consultare un ginecologo o un endocrinologo."
    }}
  ],
  PROTS: [
    { range: [60, 140], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 60], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low Protein S levels may indicate increased risk of thrombosis. Please consult a hematologist.",
      it: "Bassi livelli di Proteina S possono indicare un aumentato rischio di trombosi. Si prega di consultare un ematologo."
    }},
    { range: [140, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Protein S levels are generally not a concern but may warrant further investigation. Please consult your doctor.",
      it: "Alti livelli di Proteina S generalmente non sono preoccupanti ma possono giustificare ulteriori indagini. Si prega di consultare il proprio medico."
    }}
  ],
  HBV: [
    { result: "negative", interpretation: { en: "no evidence of hepatitis B infection", it: "nessuna evidenza di infezione da epatite B" } },
    { result: "positive", interpretation: { en: "evidence of hepatitis B infection", it: "evidenza di infezione da epatite B" }, advice: {
      en: "Positive Hepatitis B Markers indicate current or past hepatitis B infection. Please consult a hepatologist for further evaluation and management.",
      it: "Marker Epatite B positivi indicano un'infezione da epatite B attuale o passata. Si prega di consultare un epatologo per ulteriori valutazioni e gestione."
    }}
  ],
  HCV: [
    { result: "negative", interpretation: { en: "no evidence of hepatitis C infection", it: "nessuna evidenza di infezione da epatite C" } },
    { result: "positive", interpretation: { en: "evidence of hepatitis C infection", it: "evidenza di infezione da epatite C" }, advice: {
      en: "Positive Hepatitis C Markers indicate current or past hepatitis C infection. Please consult a hepatologist for further evaluation and management.",
      it: "Marker Epatite C positivi indicano un'infezione da epatite C attuale o passata. Si prega di consultare un epatologo per ulteriori valutazioni e gestione."
    }}
  ],
  HAV: [
    { result: "negative", interpretation: { en: "no evidence of hepatitis A infection or immunity", it: "nessuna evidenza di infezione da epatite A o immunità" } },
    { result: "positive", interpretation: { en: "evidence of hepatitis A infection or immunity", it: "evidenza di infezione da epatite A o immunità" }, advice: {
      en: "Positive Hepatitis A Markers may indicate current infection, past infection, or vaccination. Please consult a doctor for interpretation.",
      it: "Marker Epatite A positivi possono indicare un'infezione in corso, un'infezione passata o una vaccinazione. Si prega di consultare un medico per l'interpretazione."
    }}
  ],
  HIV: [
    { result: "negative", interpretation: { en: "no evidence of HIV infection", it: "nessuna evidenza di infezione da HIV" } },
    { result: "positive", interpretation: { en: "evidence of HIV infection", it: "evidenza di infezione da HIV" }, advice: {
      en: "Positive HIV test requires immediate follow-up. Please consult an infectious disease specialist urgently for confirmation and management.",
      it: "Un test HIV positivo richiede un follow-up immediato. Si prega di consultare urgentemente uno specialista in malattie infettive per conferma e gestione."
    }}
  ],
  TT: [
    { range: [14, 21], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 14], interpretation: { en: "short", it: "breve" }, advice: {
      en: "Short Thrombin Time may indicate increased coagulation activity. Please consult a hematologist.",
      it: "Un Tempo di Trombina breve può indicare un'aumentata attività di coagulazione. Si prega di consultare un ematologo."
    }},
    { range: [21, Infinity], interpretation: { en: "prolonged", it: "prolungato" }, advice: {
      en: "Prolonged Thrombin Time may indicate coagulation disorders or anticoagulant therapy. Please consult a hematologist.",
      it: "Un Tempo di Trombina prolungato può indicare disturbi della coagulazione o terapia anticoagulante. Si prega di consultare un ematologo."
    }}
  ],
  FIB: [
    { range: [200, 400], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 200], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low Fibrinogen levels may indicate liver disease or consumption coagulopathy. Please consult a hematologist.",
      it: "Bassi livelli di Fibrinogeno possono indicare malattie epatiche o coagulopatia da consumo. Si prega di consultare un ematologo."
    }},
    { range: [400, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Fibrinogen levels may indicate inflammation or cardiovascular risk. Please consult your doctor.",
      it: "Alti livelli di Fibrinogeno possono indicare infiammazione o rischio cardiovascolare. Si prega di consultare il proprio medico."
    }}
  ],
  D_DIMER: [
    { range: [0, 0.5], interpretation: { en: "normal", it: "normale" } },
    { range: [0.5, Infinity], interpretation: { en: "elevated", it: "elevato" }, advice: {
      en: "Elevated D-Dimer may indicate thrombosis or other conditions. Please consult a doctor for further evaluation.",
      it: "D-Dimero elevato può indicare trombosi o altre condizioni. Si prega di consultare un medico per ulteriori valutazioni."
    }}
  ],
  ACT: [
    { range: [80, 120], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 80], interpretation: { en: "short", it: "breve" }, advice: {
      en: "Short ACT may indicate increased coagulation activity. Please consult a hematologist.",
      it: "Un ACT breve può indicare un'aumentata attività di coagulazione. Si prega di consultare un ematologo."
    }},
    { range: [120, Infinity], interpretation: { en: "prolonged", it: "prolungato" }, advice: {
      en: "Prolonged ACT may indicate coagulation disorders or anticoagulant therapy. Please consult a hematologist.",
      it: "Un ACT prolungato può indicare disturbi della coagulazione o terapia anticoagulante. Si prega di consultare un ematologo."
    }}
  ],
  CMV: [
    { result: "negative", interpretation: { en: "no evidence of CMV infection", it: "nessuna evidenza di infezione da CMV" } },
    { result: "positive", interpretation: { en: "evidence of CMV infection", it: "evidenza di infezione da CMV" }, advice: {
      en: "Positive CMV test may indicate current or past infection. Please consult a doctor for interpretation, especially if pregnant or immunocompromised.",
      it: "Un test CMV positivo può indicare un'infezione in corso o passata. Si prega di consultare un medico per l'interpretazione, specialmente se in gravidanza o immunocompromessi."
    }}
  ],
  ROSOLIA: [
    { result: "negative", interpretation: { en: "no immunity to rubella", it: "nessuna immunità alla rosolia" }, advice: {
      en: "Consider vaccination against rubella, especially if planning pregnancy.",
      it: "Considerare la vaccinazione contro la rosolia, specialmente se si sta pianificando una gravidanza."
    }},
    { result: "positive", interpretation: { en: "immunity to rubella", it: "immunità alla rosolia" } }
  ],
  HERPES: [
    { result: "negative", interpretation: { en: "no evidence of herpes infection", it: "nessuna evidenza di infezione da herpes" } },
    { result: "positive", interpretation: { en: "evidence of herpes infection", it: "evidenza di infezione da herpes" }, advice: {
      en: "Positive Herpes test indicates current or past infection. Please consult a doctor for management and prevention strategies.",
      it: "Un test Herpes positivo indica un'infezione in corso o passata. Si prega di consultare un medico per strategie di gestione e prevenzione."
    }}
  ],
  AUTOIMMUNITY_TUMOR_MARKERS: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive result may indicate autoimmune condition or potential malignancy. Please consult a specialist for further evaluation.",
      it: "Un risultato positivo può indicare una condizione autoimmune o una potenziale malignità. Si prega di consultare uno specialista per ulteriori valutazioni."
    }}
  ],
  COOMBS: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Coombs test may indicate autoimmune hemolytic anemia or other conditions. Please consult a hematologist.",
      it: "Un test di Coombs positivo può indicare anemia emolitica autoimmune o altre condizioni. Si prega di consultare un ematologo."
    }}
  ],
  IgE_TOTAL: [
    { range: [0, 100], interpretation: { en: "normal", it: "normale" } },
    { range: [100, Infinity], interpretation: { en: "elevated", it: "elevato" }, advice: {
      en: "Elevated Total IgE may indicate allergic conditions or parasitic infections. Please consult an allergist or immunologist.",
      it: "IgE Totali elevate possono indicare condizioni allergiche o infezioni parassitarie. Si prega di consultare un allergologo o un immunologo."
    }}
  ],
  RAST: [
    { range: [0, 0.35], interpretation: { en: "negative", it: "negativo" } },
    { range: [0.35, Infinity], interpretation: { en: "positive", it: "positivo" }, advice: {
      en: "Positive RAST indicates specific allergen sensitivity. Please consult an allergist for management.",
      it: "RAST positivo indica sensibilità a specifici allergeni. Si prega di consultare un allergologo per la gestione."
    }}
  ],
  G6PDH: [
    { range: [7, 20], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 7], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low G6PDH may indicate G6PD deficiency. Please consult a hematologist for further evaluation and management.",
      it: "G6PDH basso può indicare una carenza di G6PD. Si prega di consultare un ematologo per ulteriori valutazioni e gestione."
    }}
  ],
  dROMs: [
    { range: [250, 300], interpretation: { en: "normal", it: "normale" } },
    { range: [300, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High dROMs levels indicate increased oxidative stress. Consider lifestyle changes and consult a doctor.",
      it: "Alti livelli di dROMs indicano un aumento dello stress ossidativo. Considerare cambiamenti dello stile di vita e consultare un medico."
    }}
  ],
  BAP: [
    { range: [2200, Infinity], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 2200], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low BAP levels indicate reduced antioxidant potential. Consider dietary changes and consult a nutritionist.",
      it: "Bassi livelli di BAP indicano un ridotto potenziale antiossidante. Considerare cambiamenti nella dieta e consultare un nutrizionista."
    }}
  ],
  OMEGA_3_6_RATIO: [
    { range: [1, 4], interpretation: { en: "normal", it: "normale" } },
    { range: [0, 1], interpretation: { en: "low", it: "basso" }, advice: {
      en: "Low Omega-3/6 ratio may indicate increased inflammation risk. Consider dietary changes and consult a nutritionist.",
      it: "Un basso rapporto Omega-3/6 può indicare un aumentato rischio di infiammazione. Considerare cambiamenti nella dieta e consultare un nutrizionista."
    }},
    { range: [4, Infinity], interpretation: { en: "high", it: "alto" }, advice: {
      en: "High Omega-3/6 ratio is generally beneficial but consult a doctor if extremely high.",
      it: "Un alto rapporto Omega-3/6 è generalmente benefico, ma consultare un medico se estremamente alto."
    }}
  ],
  ANTI_ENDOMYSIAL_AB: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Endomysial Antibodies strongly suggest celiac disease. Please consult a gastroenterologist.",
      it: "Anticorpi Anti-Endomisio positivi suggeriscono fortemente la malattia celiaca. Si prega di consultare un gastroenterologo."
    }}
  ],
  ANTI_GLIADIN_AB: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Gliadin Antibodies may indicate celiac disease or gluten sensitivity. Please consult a gastroenterologist.",
      it: "Anticorpi Anti-Gliadina positivi possono indicare malattia celiaca o sensibilità al glutine. Si prega di consultare un gastroenterologo."
    }}
  ],
  ANTI_TRANSGLUTAMINASE_AB: [
    { result: "negative", interpretation: { en: "normal", it: "normale" } },
    { result: "positive", interpretation: { en: "abnormal", it: "anormale" }, advice: {
      en: "Positive Anti-Transglutaminase Antibodies strongly suggest celiac disease. Please consult a gastroenterologist.",
      it: "Anticorpi Anti-Transglutaminasi positivi suggeriscono fortemente la malattia celiaca. Si prega di consultare un gastroenterologo."
    }}
  ]


  



}