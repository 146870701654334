import { bloodTestParameters } from './bloodTestParameters';
import { convertToStandardUnits } from './unitConversion';
import { interpretResult } from './interpreteResults';

export const generateSummary = (interpretations, language) => {
  const translations = {
    en: {
      basedOn: "Based on your test results, ",
      allNormal: "all your values appear to be within normal or desirable ranges. Great job maintaining your health!",
      someAttention: "there are some values that require attention. ",
      additionally: "Additionally, ",
      reminder: "Remember, these interpretations are for informational purposes only and do not replace professional medical advice. Always consult with a healthcare provider for proper diagnosis and treatment.",
      your: "Your ",
      is: " is ",
      noInterpretableResults: "No interpretable results found.",
      invalidData: "Unable to generate summary due to missing or invalid data."
    },
    it: {
      basedOn: "Sulla base dei risultati dei tuoi esami, ",
      allNormal: "tutti i tuoi valori sembrano essere entro intervalli normali o desiderabili. Ottimo lavoro nel mantenere la tua salute!",
      someAttention: "ci sono alcuni valori che richiedono attenzione. ",
      additionally: "Inoltre, ",
      reminder: "Ricorda, queste interpretazioni sono solo a scopo informativo e non sostituiscono il parere medico professionale. Consulta sempre un operatore sanitario per una diagnosi e un trattamento adeguati.",
      your: "Il tuo ",
      is: " è ",
      noInterpretableResults: "Nessun risultato interpretabile trovato.",
      invalidData: "Impossibile generare il riepilogo a causa di dati mancanti o non validi."
    }
  };

  // Check for valid input
  if (!interpretations || typeof interpretations !== 'object' || !language || !translations[language]) {
    console.error('Invalid input for generateSummary:', { interpretations, language });
    return translations[language]?.invalidData || translations.en.invalidData;
  }

  let summary = translations[language].basedOn;
  const abnormalResults = Object.entries(interpretations).filter(([, interpretation]) => {
    return interpretation && 
           interpretation.interpretation && 
           interpretation.interpretation !== 'normal' && 
           interpretation.interpretation !== 'desirable';
  });

  if (abnormalResults.length === 0) {
    summary += translations[language].allNormal;
  } else {
    summary += translations[language].someAttention;
    abnormalResults.forEach(([test, interpretation], index) => {
      const testLabel = bloodTestParameters[test]?.label?.[language] || 
                        bloodTestParameters[test]?.label || 
                        test;
      summary += `${translations[language].your}${testLabel}${translations[language].is}${interpretation.interpretation}. `;
      if (interpretation.advice) {
        summary += `${interpretation.advice} `;
      }
      if (index < abnormalResults.length - 1) {
        summary += translations[language].additionally;
      }
    });
  }

  summary += translations[language].reminder;

  return summary.trim();
};

export const getReferenceRanges = () => {
  return {
    hemoglobin: '13.5 - 17.5 g/dL',
    hematocrit: '41 - 50%',
    redBloodCells: '4.5 - 5.5 x 10^6/µL',
    whiteBloodCells: '4.5 - 11.0 x 10^3/µL',
    platelets: '150 - 450 x 10^3/µL',
    mcv: '80 - 100 fL',
    mch: '27 - 33 pg',
    mchc: '32 - 36 g/dL',
    rdw: '11.5 - 14.5%',
    neutrophils: '40 - 60%',
    lymphocytes: '20 - 40%',
    monocytes: '2 - 8%',
    eosinophils: '1 - 6%',
    basophils: '0 - 2%',
    glucose: '70 - 100 mg/dL',
    ureaNitrogen: '7 - 20 mg/dL',
    creatinine: '0.6 - 1.2 mg/dL',
    sodium: '135 - 145 mEq/L',
    potassium: '3.5 - 5.0 mEq/L',
    chloride: '98 - 106 mEq/L',
    calcium: '8.5 - 10.2 mg/dL',
    carbonDioxide: '23 - 29 mEq/L',
    totalCholesterol: '< 200 mg/dL',
    hdlCholesterol: '> 60 mg/dL',
    ldlCholesterol: '< 100 mg/dL',
    triglycerides: '< 150 mg/dL',
    alt: '< 50 U/L',
    ast: '< 40 U/L',
    alp: '30 - 120 U/L',
    totalBilirubin: '0.1 - 1.2 mg/dL',
    albumin: '3.4 - 5.4 g/dL',
    tsh: '0.4 - 4.0 mIU/L',
    freeT4: '0.7 - 1.8 ng/dL',
    freeT3: '2.3 - 4.2 pg/mL',
    iron: '60 - 170 µg/dL',
    tibc: '240 - 450 µg/dL',
    ferritin: '30 - 400 ng/mL',
    vitaminB12: '200 - 900 pg/mL',
    folicAcid: '2.7 - 17.0 ng/mL',
    vitaminD: '30 - 100 ng/mL',
    crp: '< 3 mg/L',
    esr: '< 20 mm/hr',
    hba1c: '< 5.7%',
    egfr: '> 90 mL/min/1.73m²',
    psa: '< 4 ng/mL',
    afp: '< 10 ng/mL',
    cea: '< 3 ng/mL',
    ca125: '< 35 U/mL',
    ca19_9: '< 37 U/mL',
    homocysteine: '< 15 µmol/L',
    lipoproteinA: '< 30 mg/dL',
    apolipoproteinB: '< 100 mg/dL',
    hscrp: '< 3 mg/L',
    nt_probnp: '< 125 pg/mL',
    troponin: '< 0.04 ng/mL',
    cortisol: '6 - 23 µg/dL',
    testosterone: '300 - 1000 ng/dL',
    estradiol: '10 - 50 pg/mL',
    progesterone: '0.2 - 1.4 ng/mL',
    dhea_s: '80 - 560 µg/dL',
  };
};

// Add these exports
export { convertToStandardUnits, interpretResult };