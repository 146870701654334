import { resultInterpretations } from './resultInterpretations';

export const interpretResult = (test, value, language) => {
  const interpretations = resultInterpretations[test];
  
  if (!interpretations) {
    return {
      interpretation: { en: 'unknown', it: 'sconosciuto' }[language],
      advice: { 
        en: 'Please consult a doctor for interpretation. These results are generated by AI and may sometimes be inaccurate. Always consult a healthcare provider.',
        it: 'Si prega di consultare un medico per l\'interpretazione. Questi risultati sono generati dall\'AI e potrebbero essere a volte inaccurati. Consultare sempre un medico.'
      }[language]
    };
  }

  const result = interpretations.find(
    item => value >= item.range[0] && value < item.range[1]
  );

  return result ? {
    interpretation: result.interpretation[language],
    advice: result.advice ? result.advice[language] : {
      en: 'Please consult a doctor for further evaluation. These results are generated by AI and should not replace professional medical advice.',
      it: 'Si prega di consultare un medico per ulteriori valutazioni. Questi risultati sono generati dall\'AI e non devono sostituire il parere medico professionale.'
    }
  } : {
    interpretation: { en: 'unknown', it: 'sconosciuto' }[language],
    advice: { 
      en: 'Please consult a doctor for interpretation. These results are generated by AI and may sometimes be inaccurate. Always consult a healthcare provider.',
      it: 'Si prega di consultare un medico per l\'interpretazione. Questi risultati sono generati dall\'AI e potrebbero essere a volte inaccurati. Consultare sempre un medico.'
    }[language]
  };
};
