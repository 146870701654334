export const validateTestResults = (testResults) => {
  if (Object.keys(testResults).length === 0) {
    return 'Please enter at least one test result.';
  }

  for (const [test, value] of Object.entries(testResults)) {
    if (value === '' || isNaN(parseFloat(value))) {
      return `Invalid value for ${test}. Please enter a valid number.`;
    }
  }

  return null; // No validation errors
};
